import { Box, Button, Collapse, Flex, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TipTap } from "../../../../common/input/TipTap/TipTap";

interface Props {
  description: string;
}

export const Intro = ({ description }: Props) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [contentOverflows, setContentOverflows] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        // Check if content height is greater than the starting height (8rem = 128px)
        const isOverflowing = contentRef.current.scrollHeight > 128;
        setContentOverflows(isOverflowing);
      }
    };

    // Initial check with a slight delay to ensure content is rendered
    const timeoutId = setTimeout(checkOverflow, 100);

    // Set up a ResizeObserver to detect changes in content size
    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
    });

    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    return () => {
      clearTimeout(timeoutId);
      resizeObserver.disconnect();
    };
  }, [description]);

  return (
    <Flex width="full" flex={3} alignItems="start" flexDirection="column">
      <Text fontSize="sm" fontWeight="semibold" pb="2">
        {t("Description")}
      </Text>
      <Box width="full" shadow="inner" rounded="lg" pt="0" px="4" pb="2">
        <Collapse startingHeight="8rem" in={showFullDescription}>
          <div ref={contentRef}>
            <TipTap content={description} editable={false} hasBorder={false} maxHeight="" padding="0" />
          </div>
        </Collapse>
        {!!description && contentOverflows && (
          <Button size="sm" variant="link" mt="5px" onClick={() => setShowFullDescription(!showFullDescription)}>
            {!showFullDescription ? t("Show more") : t("Show less")}
          </Button>
        )}
      </Box>
    </Flex>
  );
};
