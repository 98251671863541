import { Box, Divider, Flex, Icon, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { CounterpartyContactPersonDto } from "../../../../../autogen/bff-api";
import { displayPhoneNumber } from "../../../../../common/formatting/displayPhoneNumber";
import { ProfileImageRounded } from "../../../../sourcing-events/buyer/view-all/ProfileImageRounded";

export const CustomCounterpartyContactPerson = ({ contactPerson }: { contactPerson: CounterpartyContactPersonDto }) => {
  const { t } = useTranslation();

  return (
    <Box padding="15px" paddingBottom={"20px"} width="full">
      <Text fontWeight={"bold"} fontSize="sm" color="#808B9B">
        {t("Counterparty contact person")}
      </Text>
      <Divider marginTop={"5px"} marginBottom={"5px"} />
      {contactPerson.fullName && (
        <Flex mt="13px">
          <Flex>
            <ProfileImageRounded
              name={contactPerson.fullName}
              width="30px"
              backgroundColor="blue.500"
              fontSize={"xs"}
            />
          </Flex>
          <Flex justifyContent={"center"} mt="-1px" ml="7px">
            <Text fontSize={"xl"} fontWeight="semibold">
              {contactPerson.fullName}
            </Text>
          </Flex>
        </Flex>
      )}
      {contactPerson.email && (
        <Flex mt="15px">
          <Icon as={FaEnvelope} ml="7px" w="15px" h="15px" color={"#717171"} />
          <Text fontSize={"sm"} color="#717171" ml="17px" mt="-3px">
            {contactPerson.email}
          </Text>
        </Flex>
      )}
      {contactPerson.phoneNumber && (
        <Flex mt="15px">
          <Icon as={FaPhone} ml="7px" w="15px" h="15px" color={"#717171"} />
          <Text fontSize={"sm"} color="#717171" ml="17px" mt="-3px">
            {displayPhoneNumber(contactPerson.phoneNumber)}
          </Text>
        </Flex>
      )}
    </Box>
  );
};
