import { Button, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BseBidDto, useUnDeliverBseBidMutation } from "../../../../../autogen/bff-api";
import { useApiError } from "../../../../../common/errors/useApiError";
import { useConfirmationModal } from "../../../../../common/useConfirmationModal";
import { urls } from "../../../../../urls";

interface Props {
  bid: BseBidDto;
  isDisabled?: boolean;
}

export const EditDeliveredBidButton = ({ bid, isDisabled = false }: Props) => {
  const navigate = useNavigate();
  const [unDeliverBid] = useUnDeliverBseBidMutation();
  const confirmationModal = useConfirmationModal();
  const { t } = useTranslation();
  const displayer = useApiError();

  const editDeliveredBid = async (bid: BseBidDto) => {
    const result = await unDeliverBid({
      eventId: bid.forEvent.id,
      bidId: bid.id,
    });
    if ("data" in result) {
      navigate(urls.bids.edit.go(bid.id).details);
    } else {
      displayer.trigger(result.error);
    }
    navigate(urls.bids.edit.go(bid.id).details);
  };

  return (
    <>
      {confirmationModal.modal}
      <Button
        size="sm"
        mr="5px"
        leftIcon={<Icon as={FaEdit} w="15px" h="15px" />}
        variant={"solid"}
        colorScheme="gray"
        isDisabled={isDisabled}
        onClick={() => {
          confirmationModal.show({
            title: t("Important!"),
            description: `${t(
              "Your delivery will be canceled, and you have to deliver the bid again after you are done editing"
            )}. ${t("Do you still want to edit the bid?")}`,
            confirmButtonText: t("Yes, cancel delivery and edit bid") ?? "",
            onConfirmed: async () => {
              await editDeliveredBid(bid);
            },
          });
        }}
      >
        {t("Edit")}
      </Button>
    </>
  );
};
