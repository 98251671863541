import { Box, Button, Card, CardBody, CardHeader, Flex, Heading, Icon, Text, useToast } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiCalendar, FiFileText, FiInfo, FiTag, FiUsers } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {
  EmailInviteDto,
  OrganizationInviteDto,
  TextDocumentDto,
  useListTextDocumentsForSourcingEventQuery,
  usePublishBasicSourcingEventMutation,
} from "../../../../../autogen/bff-api";
import { TextDocumentModal } from "../../../../../common/documents/TextDocumentModal";
import { ReviewRow } from "../../../../../common/editing/ReviewRow/ReviewRow";
import { useApiError } from "../../../../../common/errors/useApiError";
import { displayDateWithTime } from "../../../../../common/formatting/displayDateWithTime";
import { TipTap } from "../../../../../common/input/TipTap/TipTap";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { setSourcingEvent } from "../../../../../common/redux/reducers/basicSourcingEventReducer";
import { useSub } from "../../../../../common/subscription/useSub";
import { urls } from "../../../../../urls";
import { useBasicSourcingEventState } from "../../../useBasicSourcingEventState";
interface Props {
  previousStep: () => void;
}

export const Review = ({ previousStep }: Props) => {
  const eventState = useBasicSourcingEventState();
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [publishEvent, { isLoading }] = usePublishBasicSourcingEventMutation();
  const { data } = useListTextDocumentsForSourcingEventQuery({ eventId: eventState.id });
  const [textDocumentToView, setTextDocumentToView] = useState<TextDocumentDto>();
  const displayer = useApiError();
  const dispatch = useAppDispatch();
  const sub = useSub();



  const publish = async () => {
    if (isValid()) {
      await publishEvent({
        eventId: eventState.id,
      })
        .unwrap()
        .then((result) => {
          toast({
            title: t("Event published!"),
            description: t("Your event is now live, and the invitations have been sent"),
            status: "success",
          });
          dispatch(setSourcingEvent(result));
          navigate(urls.events.viewOngoing);
        })
        .catch((error) => {
          displayer.trigger(error);
        });
    } else {
      toast({
        title: t("One or more values are invalid!"),
        description: t("Please fix missing or invalid values"),
        status: "warning",
      });
    }
  };

  const groupedOrgEntryInvites = useMemo(() => {
    const invites: { [entryId: string]: EmailInviteDto[] } = {};
    eventState.emailInvites.value.forEach((invite) => {
      const invitesForOrgEntry = invites[invite.organizationEntry?.id ?? ""] ?? [];
      invites[invite.organizationEntry?.id ?? ""] = [...invitesForOrgEntry, invite];
    });
    return invites;
  }, [eventState.emailInvites.value]);

  const isValid = (): boolean => {
    return descriptionErrorMessage === null && deadlineErrorMessage === null && invitesErrorMessage === null;
  };

  const getDescriptionErrorMessage = (value: string | null): string | null => {
    if (value === null) {
      return t("Please provide a description");
    } else if (value.length < 2) {
      return t("Please provide a longer description");
    }
    return null;
  };

  const getDeadlineErrorMessage = (value?: string | null): string | null => {
    if (value === null) {
      return t("Please provide a deadline");
    }
    return null;
  };

  const getInvitesErrorMessage = (
    organizationInvites: OrganizationInviteDto[],
    emailInvites: EmailInviteDto[]
  ): string | null => {
    if (organizationInvites.length + emailInvites.length === 0) {
      return t("Please add at least 1 invited organization or email");
    }

    return null;
  };

  const descriptionErrorMessage = getDescriptionErrorMessage(eventState.description.value);
  const deadlineErrorMessage = getDeadlineErrorMessage(eventState.deadline.value);
  const invitesErrorMessage = getInvitesErrorMessage(
    eventState.organizationInvites.value,
    eventState.emailInvites.value
  );

  return (
    <>
      {textDocumentToView && (
        <TextDocumentModal
          textDocument={textDocumentToView}
          onClose={() => setTextDocumentToView(undefined)}
          isUpdating={false}
          editable={false}
        />
      )}
      <Flex width="full" direction="row" grow="1" pt="10">
        <Flex flex="1" flexDirection="column">
          <Card mb="6" variant="outline" borderColor="smBlueShadow" bg="smSecondarySoft">
            <CardBody p="4">
              <Flex alignItems="center">
                <Icon as={FiInfo} color="smSecondary" boxSize="5" mr="3" />
                <Text fontWeight="medium">
                  {eventState.event.stateName === "Draft"
                    ? t("Review your event before publishing")
                    : t("Event details")}
                </Text>
              </Flex>
            </CardBody>
          </Card>
          <Card mb="4" variant="outline" boxShadow="sm">
            <CardHeader pb="0">
              <Heading size="md">{t("Event Details")}</Heading>
            </CardHeader>
            <CardBody>
              <ReviewRow title={t("Title")} errorMessage={null}>
                <Text fontWeight="medium">{eventState.title.value}</Text>
              </ReviewRow>
              <ReviewRow title={t("Description")} errorMessage={descriptionErrorMessage}>
                <Box maxHeight="200px" overflow="hidden" position="relative" width="full">
                  {eventState.description.value && (
                    <TipTap
                      content={eventState.description.value ?? ""}
                      editable={false}
                      hasBorder={false}
                      padding="0"
                    />
                  )}
                  {/* Gradient fade effect at the bottom */}
                  <Box
                    position="absolute"
                    bottom="0"
                    left="0"
                    right="0"
                    height="40px"
                    bgGradient="linear(to-t, smBackground, transparent)"
                  />
                </Box>
              </ReviewRow>
              <ReviewRow title={t("Deadline")} errorMessage={deadlineErrorMessage}>
                <Flex alignItems="center">
                  <Icon as={FiCalendar} color="smMuted" mr="2" />
                  <Text>{eventState.deadline.value && displayDateWithTime(eventState.deadline.value, t("at"))}</Text>
                </Flex>
              </ReviewRow>
            </CardBody>
          </Card>
          <Card mb="4" variant="outline" boxShadow="sm">
            <CardHeader pb="0">
              <Heading size="md">{t("Documents")}</Heading>
            </CardHeader>
            <CardBody>
              <Flex alignItems="center">
                <Icon as={FiFileText} color="smMuted" mr="2" boxSize="5" />
                {eventState.documents.value.length + (data?.documents?.length ?? 0) > 0 ? (
                  <Text>
                    {t("{{count}} documents attached", {
                      count: eventState.documents.value.length + (data?.documents?.length ?? 0),
                    })}
                  </Text>
                ) : (
                  <Text color="smMuted">{t("No documents attached")}</Text>
                )}
              </Flex>
            </CardBody>
          </Card>
          <Card mb="4" variant="outline" boxShadow="sm">
            <CardHeader pb="0">
              <Heading size="md">{t("Invited Companies")}</Heading>
            </CardHeader>
            <CardBody>
              <Flex alignItems="flex-start">
                <Icon as={FiUsers} color="smMuted" mr="2" mt="1" boxSize="5" />
                <Flex flexDirection="column" width="full">
                  {eventState.organizationInvites.value.length + Object.keys(groupedOrgEntryInvites).length > 0 ? (
                    <>
                      {eventState.organizationInvites.value.map((e) => (
                        <Flex key={e.id} py="1" borderBottom="1px" borderColor="smBorder">
                          <Text>{e.organizationName}</Text>
                        </Flex>
                      ))}
                      {Object.values(groupedOrgEntryInvites).map((invites) => (
                        <Flex key={invites[0].organizationEntry?.id} py="1" borderBottom="1px" borderColor="smBorder">
                          <Text>{invites[0].organizationEntry?.name}</Text>
                        </Flex>
                      ))}
                    </>
                  ) : (
                    <Text color="smMuted" fontStyle="italic">
                      {t("None")}
                    </Text>
                  )}
                  {invitesErrorMessage && (
                    <Text color="smDanger" mt="2">
                      {invitesErrorMessage}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </CardBody>
          </Card>
          {sub.hasProjectAccess && (
            <Card mb="4" variant="outline" boxShadow="sm">
              <CardHeader pb="0">
                <Heading size="md">{t("Projects")}</Heading>
              </CardHeader>
              <CardBody>
                <Flex alignItems="flex-start">
                  <Icon as={FiTag} color="smMuted" mr="2" mt="1" boxSize="5" />
                  <Flex flexDirection="column" width="full">
                    {eventState.projects.values?.length ? (
                      eventState.projects.values.map((p) => (
                        <Flex key={p.id} py="1" borderBottom="1px" borderColor="smBorder">
                          <Text>{`${p.externalId ? `${p.externalId} - ` : ""}${p.name}`}</Text>
                        </Flex>
                      ))
                    ) : (
                      <Text color="smMuted" fontStyle="italic">
                        {t("None")}
                      </Text>
                    )}
                    {eventState.projects.errorMessage && (
                      <Text color="smDanger" mt="2">
                        {eventState.projects.errorMessage}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          )}
          <Flex pb="10" flexDirection={"column"}>
            <Flex>
              <Button
                variant="outline"
                mr="10px"
                w="100%"
                colorScheme="teal"
                onClick={previousStep}
                isDisabled={isLoading}
              >
                {t("Previous")}
              </Button>
              {eventState.event.stateName === "Draft" && (
                <Button variant="solid" w="100%" colorScheme="blue" isLoading={isLoading} onClick={publish}>
                  {t("Go live!")}
                </Button>
              )}
              {eventState.event.stateName !== "Draft" && (
                <Button
                  variant="solid"
                  w="100%"
                  colorScheme="blue"
                  isLoading={isLoading}
                  onClick={() => navigate(urls.events.view.go(eventState.id))}
                >
                  {t("Back to event")}
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
