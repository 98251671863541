import { useEffect } from "react";
import { useGetProductGroupsForSourcingEventQuery } from "../../../../autogen/bff-api";
import { useAppDispatch, useAppSelector } from "../../../../common/redux/hooks";
import { mapDtoToProductGroup, setProductGroups } from "../../../../common/redux/reducers/productReducer";
import { ProductGroup } from "../../../../common/types";

/**
 * Custom hook to fetch and manage product groups for a sourcing event
 * @param sourcingEventId The ID of the sourcing event
 * @returns An object containing product groups and loading state
 */
export const useProductGroups = (sourcingEventId: string) => {
  const dispatch = useAppDispatch();
  const { data, isLoading, error } = useGetProductGroupsForSourcingEventQuery({ sourcingEventId });

  const productGroups: ProductGroup[] = useAppSelector((state) => state.product.data.productGroups);

  useEffect(() => {
    if (data?.productGroups) {
      dispatch(setProductGroups(data.productGroups.map((group) => mapDtoToProductGroup(group))));
    }
  }, [data, dispatch]);

  return {
    productGroups,
    isLoading,
    error,
  };
};
