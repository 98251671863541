import { Button, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BidDto, BseBidDto } from "../../../autogen/bff-api";
import { urls } from "../../../urls";

interface Props {
  bid: BseBidDto | BidDto;
  colorScheme?: string;
  size: "sm" | "md";
  isDisabled?: boolean;
}

export const EditBidDraftButton = ({ bid, colorScheme, size, isDisabled = false }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const editBid = () => {
    navigate(urls.bids.edit.go(bid.id).details);
  };

  return (
    <Button
      leftIcon={<Icon as={FaEdit} w="15px" h="15px" />}
      variant={"outline"}
      colorScheme={colorScheme ?? "teal"}
      size={size}
      isDisabled={isDisabled}
      onClick={() => {
        editBid();
      }}
    >
      {t("Edit")}
    </Button>
  );
};
