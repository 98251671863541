import { Button, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  BseBidDto,
  BseSupplierViewDto,
  useAcceptInvitationToBidMutation,
  useCreateDraftBidMutation,
} from "../../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../../common/errors/useApiError";
import { urls } from "../../../../../urls";
import { useNegotiation } from "../NegotiationContext";

interface Props {
  event: BseSupplierViewDto;
  shouldAcceptInvite: boolean;
  isDisabled?: boolean;
}

export const CreateDraftButton = ({ event, shouldAcceptInvite, isDisabled = false }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const toast = useToast();
  const [acceptInvitationToBid] = useAcceptInvitationToBidMutation();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const apiErrorDisplayer = useApiError();
  const navigate = useNavigate();
  const { currentRound } = useNegotiation();

  const navigateToEditBid = (bidId: string) => {
    navigate(urls.bids.edit.go(bidId).details);
  };

  const [createBidDraft] = useCreateDraftBidMutation();

  const acceptInvite = async (): Promise<boolean> => {
    const result = await acceptInvitationToBid({
      eventId: event.id,
    });
    if ("data" in result) {
      return true;
    } else {
      apiErrorDisplayer.trigger(result.error);
      return false;
    }
  };

  const createBid = async (): Promise<BseBidDto> => {
    const result = await createBidDraft({
      createDraftBidRequest: {
        sourcingEventId: event.id,
        organizationId: authState.selectedOrg.id,
        negotiationRoundId: currentRound?.id,
      },
    });

    if ("data" in result) {
      return result.data;
    } else {
      apiErrorDisplayer.trigger(result.error);
      throw Error("Could not create bid.");
    }
  };

  return (
    <Button
      size="sm"
      variant={"outline"}
      colorScheme="teal"
      onClick={async () => {
        setIsLoading(true);
        const inviteAccepted = shouldAcceptInvite ? await acceptInvite() : true;
        if (!inviteAccepted) {
          toast({ title: `${t("Could not accept invitation to create bid")}. ${t("Please contact support")}.` });
          return;
        }
        const bid = await createBid();
        if (bid) {
          toast({
            title: t("Bid draft created!"),
            status: "success",
          });
        }
        navigateToEditBid(bid.id);
        setIsLoading(false);
      }}
      isLoading={isLoading}
      isDisabled={isDisabled}
    >
      {t("Yes, create a draft")}
    </Button>
  );
};
