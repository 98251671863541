import {
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { FaCheckCircle, FaChevronLeft, FaChevronRight, FaClock, FaCopy, FaEdit, FaRedoAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  BseDto,
  useCreateNegotiationRoundMutation,
  useCreateSourcingEventCopyMutation,
} from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { displayDate } from "../../../../common/formatting/displayDate";
import { displayDateWithTime } from "../../../../common/formatting/displayDateWithTime";
import { Explanation } from "../../../../common/support/Explanation/Explanation";
import { requireBooleanEnvVar } from "../../../../config";
import { urls } from "../../../../urls";
import { isCompletedBse, isPublishedBse } from "../../typeguards";
import { useNegotiation } from "./NegotiationContext";

interface Props {
  event: BseDto;
}

export const EventMeta = ({ event }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const displayer = useApiError();

  const [createEventCopy, { isLoading: isLoadingEventCopy }] = useCreateSourcingEventCopyMutation();
  const [startNegotiationRound, { isLoading: isStartingNegotiationRound }] = useCreateNegotiationRoundMutation();

  const { roundNumber, setRoundNumber, currentRound, latestRound } = useNegotiation();

  const currentDeadline = currentRound?.deadline ?? event?.awardedFields?.deadline ?? event?.publishedFields?.deadline;

  const latestDeadline = latestRound?.deadline ?? event?.awardedFields?.deadline ?? event?.publishedFields?.deadline;

  const isLatestDeadlinePassed = (): boolean => {
    if (!latestDeadline) throw new Error("No deadline found");
    return moment().isAfter(moment(latestDeadline));
  };

  const isCurrentDeadlinePassed = (): boolean => {
    if (!currentDeadline) throw new Error("No deadline found");
    return moment().isAfter(moment(currentDeadline));
  };

  return (
    <>
      <Flex justifyContent="space-between">
        <Flex alignItems="center">
          {requireBooleanEnvVar("VITE_ENABLE_NEGOTIATIONS") && (
            <>
              <IconButton
                aria-label="previous round"
                icon={<Icon as={FaChevronLeft} />}
                size="sm"
                variant="ghost"
                cursor="pointer"
                colorScheme="teal"
                isDisabled={roundNumber === 0}
                onClick={() => setRoundNumber(roundNumber - 1)}
              />
              <Text fontWeight="semibold" mx="2">
                {roundNumber === 0 ? t("Original sourcing event") : `${t("Round")} ${roundNumber}`}
              </Text>
              <IconButton
                aria-label="next round"
                icon={<Icon as={FaChevronRight} />}
                size="sm"
                variant="ghost"
                cursor="pointer"
                colorScheme="teal"
                isDisabled={roundNumber >= (event.negotiationContext?.rounds.length ?? 0)}
                onClick={() => setRoundNumber(roundNumber + 1)}
              />
            </>
          )}
        </Flex>
        <Menu>
          <MenuButton as={Button} variant="ghost">
            <Flex columnGap={1} cursor="pointer">
              <Flex width="1.5" height="1.5" backgroundColor="smText" rounded="full"></Flex>
              <Flex width="1.5" height="1.5" backgroundColor="smText" rounded="full"></Flex>
              <Flex width="1.5" height="1.5" backgroundColor="smText" rounded="full"></Flex>
            </Flex>
          </MenuButton>
          <MenuList>
            {currentRound ? (
              <MenuItem
                isDisabled={!event.canEdit}
                icon={<Icon as={FaEdit} />}
                onClick={() =>
                  navigate(urls.events.editNegotiation.go({ eventId: event.id, negotiationId: currentRound.id }).intro)
                }
              >
                {isPublishedBse(event) && (
                  <Explanation
                    enabled={!event.canEdit}
                    text={t("Only admins and creators can edit negotiation rounds") ?? ""}
                    shouldWrapChildren
                  >
                    {t("Edit negotiation round")}
                  </Explanation>
                )}
              </MenuItem>
            ) : (
              <MenuItem
                isDisabled={!event.canEdit || !isPublishedBse(event)}
                icon={<Icon as={FaEdit} />}
                onClick={() => navigate(urls.events.edit.go(event.id).intro)}
              >
                <Explanation
                  enabled={!event.canEdit}
                  text={t("Only admins and creators can edit events") ?? ""}
                  shouldWrapChildren
                >
                  {t("Edit event")}
                </Explanation>
              </MenuItem>
            )}
            <MenuItem
              isDisabled={!event.canEdit}
              icon={<Icon as={FaCopy} />}
              onClick={async () => {
                const res = await createEventCopy({ eventId: event.id });
                if ("data" in res) navigate(urls.events.edit.go(res.data.id).intro);
                else displayer.trigger(res.error);
              }}
            >
              <Explanation enabled={!event.canEdit} text={t("Only admins and creators can copy events") ?? ""}>
                {t("Copy event")}
                {isLoadingEventCopy && <Spinner />}
              </Explanation>
            </MenuItem>
            {requireBooleanEnvVar("VITE_ENABLE_NEGOTIATIONS") && (
              <>
                <Explanation
                  enabled={!isLatestDeadlinePassed() && (isPublishedBse(event) || isCompletedBse(event))}
                  text={t("Cannot start a new negotiation round before the current deadline has passed") ?? ""}
                >
                  <MenuItem
                    icon={<Icon as={FaRedoAlt} />}
                    isDisabled={
                      !event.canEdit || !(isPublishedBse(event) || isCompletedBse(event)) || !isLatestDeadlinePassed()
                    }
                    onClick={async () => {
                      const res = await startNegotiationRound({
                        eventId: event.id,
                        createNegotiationRoundRequest: {
                          deadline: moment().endOf("day").set("ms", 0).set("s", 0).toISOString(),
                        },
                      }).unwrap();
                      navigate(urls.events.editNegotiation.go({ eventId: event.id, negotiationId: res.id }).intro);
                    }}
                  >
                    {t("New negotiation")} {isStartingNegotiationRound && <Spinner />}
                  </MenuItem>
                </Explanation>
              </>
            )}
          </MenuList>
        </Menu>
      </Flex>
      <Flex width="full" justifyContent="center" py="10">
        <Flex flexDirection="column" grow="1">
          <Heading as="h2" size="md" width="full" textAlign="center">
            {event.title}
          </Heading>
          <Flex width="full" justifyContent="center" alignItems="center" pt="2">
            <Icon
              as={isCurrentDeadlinePassed() ? FaCheckCircle : FaClock}
              color={isCurrentDeadlinePassed() ? "smSecondary" : "smPrimary"}
              w="4"
              h="4"
              mr="1.5"
            />
            <Text fontSize={"md"} color={isCurrentDeadlinePassed() ? "smSecondary" : "smPrimary"} whiteSpace="nowrap">
              {t("Bid deadline")}: {currentDeadline ? displayDateWithTime(currentDeadline, t("at")) : null}
            </Text>
          </Flex>
          <Text textAlign="center" fontSize="sm" color="smMuted" pt="1">
            {t("Created by")} <b>{`${event.createdBy.firstName} ${event.createdBy.lastName}`} </b>
            {t("on")} {displayDate(event.createdAt)}.
          </Text>
        </Flex>
      </Flex>
    </>
  );
};
