import { Box, Button, Card, CardBody, CardHeader, Flex, Heading, Icon, Text, useToast } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiCalendar, FiFileText, FiInfo, FiUsers } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {
  BseDto,
  NegotiationRoundDto,
  TextDocumentDto,
  useListTextDocumentsForSourcingEventQuery,
  usePublishNegotiationRoundMutation,
} from "../../../../autogen/bff-api";
import { TextDocumentModal } from "../../../../common/documents/TextDocumentModal";
import { ReviewRow } from "../../../../common/editing/ReviewRow/ReviewRow";
import { useApiError } from "../../../../common/errors/useApiError";
import { displayDateWithTime } from "../../../../common/formatting/displayDateWithTime";
import { TipTap } from "../../../../common/input/TipTap/TipTap";
import { urls } from "../../../../urls";

interface Props {
  previousStep: () => void;
  event: BseDto;
  negotiationRound: NegotiationRoundDto;
}

export const Review = ({ previousStep, event, negotiationRound }: Props) => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [publishRound, { isLoading }] = usePublishNegotiationRoundMutation();
  const { data } = useListTextDocumentsForSourcingEventQuery({ eventId: event.id });
  const [textDocumentToView, setTextDocumentToView] = useState<TextDocumentDto>();
  const displayer = useApiError();

  const eventDescription = useMemo(() => {
    return negotiationRound.description || "";
  }, [negotiationRound.description]);

  const publish = async () => {
    if (isValid()) {
      await publishRound({ eventId: event.id, negotiationId: negotiationRound.id })
        .unwrap()
        .then(() => {
          toast({
            title: `${t("Negotiation round published")}!`,
            description: t("Your negotiation round is now live, and the invitations have been sent"),
            status: "success",
          });
          navigate(urls.events.view.go(event.id));
        })
        .catch((error) => {
          displayer.trigger(error);
        });
    } else {
      toast({
        title: t("One or more values are invalid!"),
        description: t("Please fix missing or invalid values"),
        status: "warning",
      });
    }
  };

  const isValid = (): boolean => {
    return deadlineErrorMessage === null;
  };

  const getDeadlineErrorMessage = (value?: string | null): string | null => {
    if (value === null) {
      return t("Please provide a deadline");
    }
    return null;
  };

  const deadlineErrorMessage = getDeadlineErrorMessage(negotiationRound.deadline);

  return (
    <>
      {textDocumentToView && (
        <TextDocumentModal
          textDocument={textDocumentToView}
          onClose={() => setTextDocumentToView(undefined)}
          isUpdating={false}
          editable={false}
        />
      )}
      <Flex width="full" direction="row" grow="1">
        <Flex flex="1" flexDirection="column">
          <Card mb="6" variant="outline" borderColor="smBlueShadow" bg="smSecondarySoft">
            <CardBody p="4">
              <Flex alignItems="center">
                <Icon as={FiInfo} color="smSecondary" boxSize="5" mr="3" />
                <Text fontWeight="medium">
                  {negotiationRound.stateName === "Draft"
                    ? t("Review your negotiation round before publishing")
                    : t("Negotiation round details")}
                </Text>
              </Flex>
            </CardBody>
          </Card>

          <Card mb="4" variant="outline" boxShadow="sm">
            <CardHeader pb="0">
              <Heading size="md">{t("Negotiation Details")}</Heading>
            </CardHeader>
            <CardBody>
              <ReviewRow title={t("Title")} errorMessage={null}>
                <Text fontWeight="medium">{event.title}</Text>
              </ReviewRow>
              <ReviewRow title={t("Description")} errorMessage={null}>
                <Box maxHeight="200px" overflow="hidden" position="relative" width="full">
                  <TipTap content={eventDescription} editable={false} hasBorder={false} padding="0" />
                  {/* Gradient fade effect at the bottom */}
                  <Box
                    position="absolute"
                    bottom="0"
                    left="0"
                    right="0"
                    height="40px"
                    bgGradient="linear(to-t, smBackground, transparent)"
                  />
                </Box>
              </ReviewRow>
              <ReviewRow title={t("Negotiation Round")} errorMessage={null}>
                <Flex alignItems="center">
                  <Text fontWeight="medium" color="smSecondary">
                    #{negotiationRound.roundNumber}
                  </Text>
                  {negotiationRound.isFinalRound && (
                    <Text ml="2" fontSize="sm" bg="smTertiarySoft" color="smTertiary" px="2" py="1" borderRadius="md">
                      {t("Final Round")}
                    </Text>
                  )}
                </Flex>
              </ReviewRow>
              <ReviewRow title={t("Deadline")} errorMessage={deadlineErrorMessage}>
                <Flex alignItems="center">
                  <Icon as={FiCalendar} color="smMuted" mr="2" />
                  <Text>{negotiationRound.deadline && displayDateWithTime(negotiationRound.deadline, t("at"))}</Text>
                </Flex>
              </ReviewRow>
            </CardBody>
          </Card>

          <Card mb="4" variant="outline" boxShadow="sm">
            <CardHeader pb="0">
              <Heading size="md">{t("Documents")}</Heading>
            </CardHeader>
            <CardBody>
              <Flex alignItems="center">
                <Icon as={FiFileText} color="smMuted" mr="2" boxSize="5" />
                {data?.documents?.length ? (
                  <Text>{t("{{count}} documents attached", { count: data.documents.length })}</Text>
                ) : (
                  <Text color="smMuted">{t("No documents attached")}</Text>
                )}
              </Flex>
            </CardBody>
          </Card>

          <Card mb="4" variant="outline" boxShadow="sm">
            <CardHeader pb="0">
              <Heading size="md">{t("Invited Companies")}</Heading>
            </CardHeader>
            <CardBody>
              <Flex alignItems="flex-start">
                <Icon as={FiUsers} color="smMuted" mr="2" mt="1" boxSize="5" />
                <Flex flexDirection="column" width="full">
                  {event.organizationInvites?.length ? (
                    event.organizationInvites.map((e) => (
                      <Flex key={e.id} py="1" borderBottom="1px" borderColor="smBorder">
                        <Text>{e.organizationName}</Text>
                      </Flex>
                    ))
                  ) : (
                    <Text color="smMuted" fontStyle="italic">
                      {t("None")}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </CardBody>
          </Card>

          <Flex pb="10" flexDirection={"column"}>
            <Flex>
              <Button
                variant={"outline"}
                mr="10px"
                width="full"
                colorScheme={"teal"}
                onClick={previousStep}
                isDisabled={isLoading}
              >
                {t("Previous")}
              </Button>
              {negotiationRound.stateName === "Draft" && (
                <Button variant={"solid"} w="100%" colorScheme={"blue"} isLoading={isLoading} onClick={publish}>
                  {t("Go live!")}
                </Button>
              )}
              {negotiationRound.stateName !== "Draft" && (
                <Button
                  variant={"solid"}
                  width="full"
                  colorScheme={"blue"}
                  isLoading={isLoading}
                  onClick={() => navigate(urls.events.view.go(event.id))}
                >
                  {t("Back to event")}
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
