import { Button, Flex, FormControl, FormLabel, Icon, Input, Select } from "@chakra-ui/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import {
  ContactPersonDto,
  EmailInviteLanguage,
  OrganizationEntryDto,
  useUpdateOrganizationEntryMutation,
} from "../autogen/bff-api";
import { useApiError } from "./errors/useApiError";
import { PhoneNumberInput } from "./input/PhoneNumberInput";
import { Explanation } from "./support/Explanation/Explanation";
import { useBaseData } from "./useBaseData";

const initContactPerson = (): ContactPersonDto => ({
  name: "",
  email: "",
  language: "No",
  title: "",
});

export const ContactPersonForm = ({
  organizationEntry,
  onSubmit,
  showFormInitially = false,
}: {
  organizationEntry: OrganizationEntryDto;
  onSubmit: (newContactPerson: ContactPersonDto) => Promise<void> | void;
  showFormInitially?: boolean;
}) => {
  const baseData = useBaseData();
  const displayer = useApiError();

  const [newContactPerson, setNewContactPerson] = useState<ContactPersonDto>();

  const [updateOrgEntry, { isLoading: isUpdatingOrgEntry }] = useUpdateOrganizationEntryMutation();

  useEffect(() => {
    if (showFormInitially) setNewContactPerson(initContactPerson());
  }, [showFormInitially]);

  const getPhoneNumberInput = (): PhoneNumberInput | null => {
    if (!newContactPerson?.phoneNumber) return null;
    const callingCode = baseData.callingCodes.find(
      (c) => c.countryAlpha3Code === newContactPerson.phoneNumber?.callingCode.countryAlpha3Code
    );
    return {
      number: newContactPerson.phoneNumber.number,
      country: {
        name: callingCode?.countryName ?? "",
        alpha3Code: callingCode?.countryAlpha3Code ?? "",
        callingCode: callingCode?.value ?? "",
      },
    };
  };

  return newContactPerson ? (
    <Flex flexDirection="column" border="1px solid" borderColor="smBorder" rounded="md" p="5" mt="5">
      <form>
        <FormControl>
          <FormLabel fontSize="sm">{t("Name")}</FormLabel>
          <Input
            autoComplete="off"
            size="sm"
            rounded="md"
            backgroundColor="smBackground"
            value={newContactPerson.name}
            onChange={(e) =>
              setNewContactPerson({
                ...newContactPerson,
                name: e.target.value,
              })
            }
          />
        </FormControl>
        <FormControl pt="2">
          <FormLabel fontSize="sm">{t("Title")}</FormLabel>
          <Input
            autoComplete="off"
            size="sm"
            rounded="md"
            backgroundColor="smBackground"
            value={newContactPerson.title}
            onChange={(e) =>
              setNewContactPerson({
                ...newContactPerson,
                title: e.target.value,
              })
            }
          />
        </FormControl>
        <FormControl pt="2">
          <FormLabel fontSize="sm">{t("Email")}</FormLabel>
          <Input
            autoComplete="off"
            size="sm"
            rounded="md"
            backgroundColor="smBackground"
            value={newContactPerson.email}
            onChange={(e) =>
              setNewContactPerson({
                ...newContactPerson,
                email: e.target.value,
              })
            }
          />
        </FormControl>
        <FormControl pt="2">
          <FormLabel fontSize="sm">{t("Phone number")}</FormLabel>
          <PhoneNumberInput
            size="sm"
            value={newContactPerson.phoneNumber ? getPhoneNumberInput() : null}
            onChange={(phoneNumber) => {
              setNewContactPerson({
                ...newContactPerson,
                phoneNumber: {
                  callingCode: {
                    countryAlpha3Code: phoneNumber?.country?.alpha3Code ?? "",
                    value: phoneNumber?.country?.callingCode ?? "",
                    countryName: phoneNumber?.country?.name ?? "",
                  },
                  number: phoneNumber?.number ?? "",
                },
              });
            }}
          />
        </FormControl>
        <FormControl pt="2">
          <FormLabel fontSize="sm">{t("Invitation language")}</FormLabel>
          <Select
            value={newContactPerson.language}
            size="sm"
            rounded="md"
            backgroundColor="smBackground"
            onChange={(e) =>
              setNewContactPerson({
                ...newContactPerson,
                language: e.target.value as EmailInviteLanguage,
              })
            }
          >
            <option value="No">{t("Norwegian")}</option>
            <option value="En">{t("English")}</option>
          </Select>
        </FormControl>
        <Flex columnGap={2} pt="4">
          <Button variant="ghost" size="sm" onClick={() => setNewContactPerson(undefined)}>
            {t("Cancel")}
          </Button>
          <Explanation
            text={t("Make sure at least email and name is valid")}
            enabled={!newContactPerson.email || !newContactPerson.name}
          >
            <Button
              colorScheme="teal"
              size="sm"
              variant="outline"
              rightIcon={<Icon as={FaCheck} />}
              isLoading={isUpdatingOrgEntry}
              isDisabled={!newContactPerson.email || !newContactPerson.name}
              onClick={async () => {
                await updateOrgEntry({
                  entryId: organizationEntry.id,
                  updateOrganizationEntryRequest: { addContactPerson: newContactPerson },
                }).catch((e) => {
                  displayer.trigger(e);
                  throw e;
                });
                await onSubmit(newContactPerson);
                setNewContactPerson(undefined);
              }}
            >
              {t("Save and select")}
            </Button>
          </Explanation>
        </Flex>
      </form>
    </Flex>
  ) : (
    <Flex py="5" px="2">
      <Button size="sm" variant="link" colorScheme="blue" onClick={() => setNewContactPerson(initContactPerson())}>
        {t("Create new contact person")}
      </Button>
    </Flex>
  );
};
