import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa";
import { useNegotiation } from "../NegotiationContext";

interface Props {
  size?: string;
}

export const GoToLatestRoundButton = ({ size = "md" }: Props) => {
  const { t } = useTranslation();
  const { event, setRoundNumber } = useNegotiation();
  
  const getLatestRoundNumber = () => {
    if (!event.negotiationContextDto?.rounds?.length) return 0;
    return event.negotiationContextDto.rounds.length;
  };

  const handleGoToLatestRound = () => {
    setRoundNumber(getLatestRoundNumber());
  };

  return (
    <Button
      rightIcon={<FaArrowRight />}
      colorScheme="teal"
      variant="outline"
      size={size}
      onClick={handleGoToLatestRound}
      ml={2}
    >
      {t("Go to latest round")}
    </Button>
  );
};
