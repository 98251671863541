import { useMemo } from "react";
import { ReceivedBseBidDto, WinningProductGroupBidsDto } from "../../../../../autogen/bff-api";
import { ProductGroup } from "../../../../../common/types";
import { isAwardedProduct } from "./BidEvaluationUtils";

export const useIsAwardedProduct = ({
  group,
  productId,
  bidId,
  bids,
  selectedBids,
}: {
  group: ProductGroup;
  productId: string;
  bidId: string;
  bids: ReceivedBseBidDto[];
  selectedBids: WinningProductGroupBidsDto[];
}) =>
  useMemo(
    () => isAwardedProduct({ group, productId, bidId, bids, selectedBids }),
    [bidId, bids, group, productId, selectedBids]
  );
