import { Box, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Spinner } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PhoneNumberDto } from "../../../../../../autogen/bff-api";
import { ContactPersonForm } from "../../../../../../common/ContactPersonForm";
import { PhoneNumberInput } from "../../../../../../common/input/PhoneNumberInput";
import { SingleSelector } from "../../../../../../common/input/Selector/SingleSelector";
import { PersonSelector } from "../../../../../../common/persons/PersonSelector";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../../useContractState";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

const isPhoneNumberDto = (phoneNumber?: Partial<PhoneNumberDto> | null): phoneNumber is PhoneNumberDto =>
  !!phoneNumber && !!phoneNumber.callingCode && !!phoneNumber.number;

const mapPhoneNumberInputToPartialPhoneNumberDto = (
  input?: PhoneNumberInput | null
): Partial<PhoneNumberDto> | null => {
  return {
    callingCode: input?.country
      ? {
          countryName: input.country.name,
          countryAlpha3Code: input.country.alpha3Code,
          value: input.country.callingCode,
        }
      : undefined,
    number: input?.number,
  };
};

const mapPhoneNumberDtoToInput = (phoneNumber?: Partial<PhoneNumberDto> | null): Partial<PhoneNumberInput> | null => ({
  country: phoneNumber?.callingCode
    ? {
        name: phoneNumber?.callingCode?.countryName,
        alpha3Code: phoneNumber?.callingCode?.countryAlpha3Code,
        callingCode: phoneNumber?.callingCode?.value,
      }
    : undefined,
  number: phoneNumber?.number,
});

export const CounterpartyContactPerson = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const contractState = useContractState();
  const [isLoading, setIsLoading] = useState(false);
  const errorMessage = containsError(contractState, "EditCounterpartyContactPerson") ? t("Update failed") : null;
  const dispatch = useAppDispatch();
  const [fullName, setFullName] = useState<string | null>();
  const [email, setEmail] = useState<string | null>();
  const [phoneNumber, setSelectedPhoneNumber] = useState<Partial<PhoneNumberDto> | null>();

  const counterpartyOrganization = useMemo(
    () => contractState.contract.counterparty?.organization,
    [contractState.contract.counterparty?.organization]
  );

  const counterpartyOrganizationEntry = useMemo(
    () => contractState.contract.counterparty?.organizationEntry,
    [contractState.contract.counterparty?.organizationEntry]
  );

  const counterpartyContactPerson = useMemo(() => {
    return contractState.contract.dataFields.counterpartyContactPerson;
  }, [contractState.contract.dataFields.counterpartyContactPerson]);

  useEffect(() => {
    if (counterpartyContactPerson) {
      if (phoneNumber === undefined) setSelectedPhoneNumber(counterpartyContactPerson.phoneNumber ?? null);
      if (fullName === undefined) setFullName(counterpartyContactPerson.fullName ?? null);
      if (email === undefined) setEmail(counterpartyContactPerson.email ?? null);
    }
  }, [counterpartyContactPerson, email, fullName, phoneNumber]);

  const eventHandler = useCallback(
    async (values: {
      personId?: string | null;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: Partial<PhoneNumberDto> | null;
    }) => {
      setIsLoading(true);
      await dispatch(
        editContractThunk({
          command: {
            type: "EditCounterpartyContactPerson",
            value: {
              fullName: values.fullName ?? null,
              email: values.email ?? null,
              phoneNumber: isPhoneNumberDto(values.phoneNumber) ? values.phoneNumber : null,
              personId: values.personId ?? null,
            },
          },
        })
      );
      setIsLoading(false);
    },
    [dispatch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedEventHandler = useMemo(() => debounce(eventHandler, 300), [eventHandler]);

  useEffect(() => {
    return () => {
      debouncedEventHandler.cancel();
    };
  }, [debouncedEventHandler]);

  const counterpartyPersonIsSelected = (): boolean => {
    if (contractState.contract.counterparty?.organization) return counterpartyContactPerson?.person !== null;
    return false;
  };

  return (
    <Box position="relative">
      <Box position="absolute" right="0" top="0" zIndex="1">
        <RemoveDataFieldButton removeDataField={removeDataField} />
      </Box>
      <FormControl isInvalid={errorMessage !== null} isRequired={isRequiredButNotProvided}>
        <FormLabel htmlFor={"value"} py="2">
          {t("Counterparty contact person")} {isLoading && <Spinner size="xs" />}
        </FormLabel>
        <Flex width="full">
          <Flex borderRadius={"10px"} width="full" flexDirection={"column"}>
            {counterpartyOrganization && (
              <>
                <PersonSelector
                  placeholder={`${t("Select contact person")}...`}
                  organizationIds={[counterpartyOrganization.id]}
                  selectedPerson={counterpartyContactPerson?.person}
                  onChange={(p) => {
                    if (p?.id) eventHandler({ personId: p?.id });
                    else eventHandler({ fullName, email, phoneNumber });
                  }}
                />
                <Input
                  mt="1"
                  backgroundColor="smBackground"
                  placeholder={t("Full name") ?? ""}
                  type="text"
                  size="sm"
                  rounded="md"
                  value={fullName ?? ""}
                  isDisabled={counterpartyPersonIsSelected()}
                  onChange={(e) => {
                    const fullName = e.target.value ? e.target.value : null;
                    setFullName(fullName);
                    debouncedEventHandler({ fullName, email, phoneNumber });
                  }}
                />
                <Input
                  backgroundColor="smBackground"
                  mt="1"
                  placeholder={t("Email") ?? ""}
                  type="text"
                  size="sm"
                  rounded="md"
                  value={email ?? ""}
                  isDisabled={counterpartyPersonIsSelected()}
                  onChange={(e) => {
                    const email = e.target.value ? e.target.value : null;
                    setEmail(email);
                    debouncedEventHandler({ fullName, email, phoneNumber });
                  }}
                />
                <Box mt="1">
                  <PhoneNumberInput
                    bg="smBackground"
                    size="sm"
                    onChange={(input) => {
                      const phoneNumber = mapPhoneNumberInputToPartialPhoneNumberDto(input);
                      setSelectedPhoneNumber(phoneNumber);
                      debouncedEventHandler({ phoneNumber: phoneNumber ?? undefined, email, fullName });
                    }}
                    value={mapPhoneNumberDtoToInput(phoneNumber)}
                    isDisabled={counterpartyPersonIsSelected()}
                  />
                </Box>
              </>
            )}
            {counterpartyOrganizationEntry && (
              <>
                <SingleSelector
                  placeholder={{ text: `${t("Select contact person")}...`, color: "black" }}
                  value={
                    counterpartyContactPerson?.email
                      ? {
                          value: counterpartyContactPerson.email ?? "",
                          label: `${counterpartyContactPerson.fullName ?? ""} ${
                            counterpartyContactPerson.email ? `(${counterpartyContactPerson.email})` : ""
                          }`,
                        }
                      : undefined
                  }
                  onChange={(option) => {
                    setEmail(option?.value ?? null);
                    eventHandler({ email: option?.value });
                  }}
                  options={
                    counterpartyOrganizationEntry.contactPersons?.map((p) => ({
                      value: p.email,
                      label: `${p.name} (${p.email})`,
                    })) ?? []
                  }
                />
                <ContactPersonForm
                  showFormInitially={!counterpartyOrganizationEntry.contactPersons?.length}
                  organizationEntry={counterpartyOrganizationEntry}
                  onSubmit={({ email }) => {
                    setEmail(email);
                    debouncedEventHandler({ email });
                  }}
                />
              </>
            )}
          </Flex>
        </Flex>
        <FormHelperText>{t("Provide contact info")}</FormHelperText>
        <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
      </FormControl>
    </Box>
  );
};
