import { Box, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { BseDto } from "../../../../autogen/bff-api";
import { Awarded } from "./Awarded";
import { AwardBasicSourcingEvent } from "./award-modal/AwardBasicSourcingEvent";

export const Award = ({ sourcingEvent }: { sourcingEvent: BseDto }) => {
  return (
    <>
      {(sourcingEvent.status === "Completed" || sourcingEvent.status === "DeadlineHasPassed") && (
        <Box display="flex" borderRadius="md" padding="5" flexDirection="column">
          <Text fontSize="md" fontWeight="semibold" pb="4">
            {t("Select winners and award event")}
          </Text>
          <AwardBasicSourcingEvent sourcingEvent={sourcingEvent} />
        </Box>
      )}
      {sourcingEvent.status === "Awarded" && <Awarded sourcingEvent={sourcingEvent} />}
    </>
  );
};
