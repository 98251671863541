import {
  Badge,
  Button,
  Flex,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import moment from "moment";
import { useState } from "react";
import { FaBullhorn, FaChevronUp, FaComment, FaComments, FaQuestion } from "react-icons/fa";
import {
  bffApi,
  BseDto,
  BseQuestionDto,
  BseQuestionStatus,
  useUpdateBasicSourcingEventMutation,
} from "../../../../autogen/bff-api";
import { ConversationModal } from "../../../../common/ConversationModal";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { setSourcingEvent } from "../../../../common/redux/reducers/basicSourcingEventReducer";
import { useQueryParams } from "../../../../common/useQueryParams";
import { Announcements } from "./announcements/Announcements";
import { AnnouncementData } from "./announcements/NewAnnouncementModal";
import { SupplierQuestions } from "./questions/SupplierQuestions";

export const EventDialog = ({ event }: { event: BseDto }) => {
  const dispatch = useAppDispatch();
  const params = useQueryParams();

  const [editEvent, { isLoading: isLoadingEditEvent }] = useUpdateBasicSourcingEventMutation();

  const [showDialog, setShowDialog] = useState(false);
  const [isConversationModalOpen, setIsConversationModalOpen] = useState(false);
  const [isSupplierQuestionsOpen, setIsSupplierQuestionsOpen] = useState(false);
  const [isAnnouncementsOpen, setIsAnnouncementsOpen] = useState(false);

  const unresolvedQuestionsCount = event.questions?.filter((e) => e.status === "Unresolved").length ?? 0;

  const [initialAnnouncementValues, setInitialAnnouncementValues] = useState<AnnouncementData | null>(null);

  if (!event) return null;

  return (
    <>
      <ConversationModal
        canComment={event.canEdit}
        isOpen={isConversationModalOpen}
        onClose={() => setIsConversationModalOpen(false)}
        conversation={event.internalConversation.map((msg) => ({
          id: msg.id,
          value: msg.value,
          createdBy: `${msg.createdBy?.firstName ?? ""} ${msg.createdBy?.lastName ?? ""}`,
          createdAt: moment(msg.createdAt).format("DD MMM YY hh.mm"),
        }))}
        handleMessage={async (msg: string) => {
          const updatedEvent = await editEvent({
            eventId: event.id,
            editSourcingEventRequest: { addInternalMessage: msg },
          }).unwrap();
          dispatch(setSourcingEvent(updatedEvent));
        }}
        isLoading={isLoadingEditEvent}
      />
      <Modal isOpen={isSupplierQuestionsOpen} onClose={() => setIsSupplierQuestionsOpen(false)} size={"3xl"}>
        <ModalOverlay />
        <ModalContent width="full" height={"80%"} maxHeight={"80%"} padding="5" overflow={"hidden"}>
          <ModalCloseButton />
          <ModalBody height={"full"}>
            <Flex height={"full"} flexDirection={"column"}>
              <Heading as="h2" size="md" pb="2">
                {t("Supplier questions")}
              </Heading>
              <SupplierQuestions
                canManageQuestions={event.canEdit}
                addComment={async (values: {
                  comment: string;
                  question: BseQuestionDto;
                  createAnnouncementDraft: boolean;
                }) => {
                  const result = await dispatch(
                    bffApi.endpoints.createBseQuestionCommentAsBuyer.initiate({
                      eventId: event.id,
                      questionId: values.question.id,
                      createBseQuestionCommentRequest: {
                        content: values.comment,
                      },
                    })
                  ).unwrap();
                  if (values.createAnnouncementDraft) {
                    setInitialAnnouncementValues({
                      title: values.question.subject,
                      content: values.comment,
                    });
                    params.setCreateAnnouncement();
                    setIsAnnouncementsOpen(true);
                    setIsSupplierQuestionsOpen(false);
                  }
                  dispatch(setSourcingEvent(result));
                }}
                changeStatus={async (status: BseQuestionStatus, questionId: string) => {
                  const response = dispatch(
                    bffApi.endpoints.updateBseQuestion.initiate({
                      eventId: event.id,
                      questionId: questionId,
                      updateBseQuestionRequest: {
                        status: status,
                      },
                    })
                  );
                  response.reset();
                  const result = await response;
                  if ("data" in result) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                questions={event.questions ?? []}
                announcements={event.announcements}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isAnnouncementsOpen} onClose={() => setIsAnnouncementsOpen(false)} size={"3xl"}>
        <ModalOverlay />
        <ModalContent width="full" height={"80%"} maxHeight={"80%"} padding="5" overflow={"hidden"}>
          <ModalCloseButton />
          <ModalBody height={"full"}>
            <Flex height={"full"} flexDirection={"column"}>
              <Heading as="h2" size="md" pb="2">
                {t("Announcements")}
              </Heading>
              <Announcements
                canCreateAnnouncement={event.canEdit}
                announcements={event.announcements}
                initialAnnouncementValues={initialAnnouncementValues}
                createAnnouncement={async (values: { title: string; content: string }) => {
                  const result = await dispatch(
                    bffApi.endpoints.createBseAnnouncement.initiate({
                      eventId: event.id,
                      createBseAnnouncementRequest: {
                        title: values.title,
                        content: values.content,
                      },
                    })
                  ).unwrap();
                  setInitialAnnouncementValues(null);
                  dispatch(setSourcingEvent(result));
                  return true;
                }}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex flexDirection="column" position="fixed" bottom="0" left="0" zIndex="10">
        <Menu size="lg" colorScheme="cyan" onOpen={() => setShowDialog(true)} onClose={() => setShowDialog(false)}>
          <MenuButton
            as={Button}
            position="relative"
            size="lg"
            roundedBottom="0"
            roundedLeft="0"
            backgroundColor="smBackgroundSecondary"
            _hover={{ backgroundColor: "smBackgroundTertiary" }}
            leftIcon={<Icon as={FaComments} />}
            rightIcon={<Icon boxSize="3" as={FaChevronUp} />}
          >
            {t("Event dialog")}{" "}
            {unresolvedQuestionsCount > 0 && !showDialog && (
              <Badge position="absolute" top="-2" right="-2" colorScheme="red" variant="solid" rounded="md">
                {unresolvedQuestionsCount} {t("unresolved!")}
              </Badge>
            )}
          </MenuButton>
          <MenuList>
            <MenuItem icon={<Icon as={FaComment} />} onClick={() => setIsConversationModalOpen(true)}>
              {t("Internal conversation")}
            </MenuItem>
            <MenuItem icon={<Icon as={FaQuestion} />} onClick={() => setIsSupplierQuestionsOpen(true)}>
              <Flex alignItems="center">
                <Text>{t("Supplier Questions")}</Text>
                {unresolvedQuestionsCount > 0 && (
                  <Badge colorScheme="red" variant="solid" rounded="md" ml="3">
                    {unresolvedQuestionsCount} {t("unresolved!")}
                  </Badge>
                )}
              </Flex>
            </MenuItem>
            <MenuItem icon={<Icon as={FaBullhorn} />} onClick={() => setIsAnnouncementsOpen(true)}>
              {t("Announcements")}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </>
  );
};
