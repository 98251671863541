import { Box, Flex, Grid, GridItem, Image, useColorMode } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { assets } from "../../../assets/assets";
import { AcceptOrgUserInvite } from "../../../common/email-invites/AcceptOrgUserInvite";
import { useQueryParams } from "../../../common/useQueryParams";
import { requireStringEnvVar } from "../../../config";
import { parseEmailInviteType } from "./parseEmailinviteType";

export const AcceptEmailInviteWhenLoggedIn = () => {
  const { emailInviteId } = useParams();
  const params = useQueryParams();
  const theme = useColorMode();
  const secret = params.get("secret");
  const rawType = params.get("type");
  const type = parseEmailInviteType(rawType);
  const { t } = useTranslation();
  if (!emailInviteId || !secret || !type) return <Box>{t("Invalid invite params!")}</Box>;

  switch (type) {
    case "SourcingEvent":
      window.location.replace(requireStringEnvVar("VITE_WEBAPP_BASE_URL"));
      return null;
    case "Supplier":
      window.location.replace(requireStringEnvVar("VITE_WEBAPP_BASE_URL"));
      return null;
  }

  return (
    <Box
      width="full"
      height="100vh"
      bgGradient="linear(to-br, smTealShadow, smBackground, smBackground, smBackground, smBlueShadow)"
    >
      <Grid
        gridTemplateColumns={"1fr 450px 1fr"}
        height="100%"
        gridTemplateRows={"1fr auto 2fr"}
        templateAreas={`
                "left top right"
                "left login right"
                "left bottom right"
                `}
      >
        <GridItem area={"left"}></GridItem>
        <GridItem area={"top"}></GridItem>
        <GridItem area={"bottom"}></GridItem>
        <GridItem area={"right"}></GridItem>
        <GridItem area={"login"} borderRadius={10} boxShadow="0 9px 46px 8px rgb(0,0,0,0.2)" padding={10}>
          <Flex width="full" height={"full"} flexDirection="column">
            <Flex>
              <Image src={theme.colorMode === "dark" ? assets.logo_dark : assets.logo} alt="Logo" h="30px" mt="10px" />
            </Flex>
            <br /> <br />
            <AcceptOrgUserInvite inviteId={emailInviteId} secret={secret} />
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};
