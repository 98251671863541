import { Box, Button, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BseDto, NegotiationRoundDto } from "../../../../autogen/bff-api";
import { InvitesTable } from "./InvitesTable";

export const InvitedParties = ({
  event,
  nextStep,
  previousStep,
  negotiationRound,
}: {
  nextStep: () => void;
  previousStep: () => void;
  event: BseDto;
  negotiationRound: NegotiationRoundDto;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box width="full" maxWidth="full" overflow="hidden">
        <InvitesTable event={event} negotiationRound={negotiationRound} />
      </Box>
      <Flex pt="10">
        <Button variant={"outline"} mr="5px" w="100%" colorScheme={"teal"} onClick={previousStep}>
          {t("Previous")}
        </Button>
        <Button variant={"solid"} w="100%" colorScheme={"teal"} onClick={nextStep}>
          {t("Next")}
        </Button>
      </Flex>
    </>
  );
};
