import {
  Badge,
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { BseDto, ReceivedBseBidDto, useListReceivedBidsQuery } from "../../../../autogen/bff-api";
import { TipTap } from "../../../../common/input/TipTap/TipTap";
import { BseReports } from "./BseReports";
import { EventValue } from "./EventValue";
import { useNegotiation } from "./NegotiationContext";

interface OrganizationListProps {
  organizations: string[];
  isWinner: boolean;
}

const OrganizationList = ({ organizations, isWinner }: OrganizationListProps) => {
  if (organizations.length === 0) return null;

  return (
    <Stack spacing={1} mt={2} mb={3}>
      {organizations.map((org, index) => (
        <Flex key={index} alignItems="center">
          <Text fontSize="sm" fontWeight="medium">
            {org}
          </Text>
          {isWinner && (
            <Badge ml={2} colorScheme="teal" variant="subtle" fontSize="xs">
              {t("Winner")}
            </Badge>
          )}
        </Flex>
      ))}
    </Stack>
  );
};

interface AwardSectionProps {
  title: string;
  message?: string;
  organizations: string[];
  accentColor: string;
  isWinner: boolean;
}

const AwardSection = ({ title, message, organizations, accentColor, isWinner }: AwardSectionProps) => {
  const { t } = useTranslation();

  const bgColor = useColorModeValue(`${accentColor}.50`, `${accentColor}.900`);
  const borderColor = useColorModeValue(`${accentColor}.200`, `${accentColor}.700`);
  const headingColor = useColorModeValue(`${accentColor}.700`, `${accentColor}.200`);
  const subheadingColor = useColorModeValue(`${accentColor}.600`, `${accentColor}.300`);
  const noContentColor = useColorModeValue("gray.500", "gray.400");

  return (
    <Card
      variant="outline"
      mb={4}
      borderColor={borderColor}
      boxShadow="sm"
      bg={useColorModeValue("white", "gray.800")}
      borderRadius="md"
      overflow="hidden"
    >
      <CardHeader pb={3} bg={bgColor} borderBottomWidth="1px" borderBottomColor={borderColor}>
        <Heading size="md" color={headingColor}>
          {title}
        </Heading>
      </CardHeader>
      <CardBody p={0}>
        <Box p={4} borderBottomWidth={message ? "1px" : "0"} borderBottomColor={borderColor}>
          <Heading size="xs" textTransform="uppercase" color={subheadingColor} mb={3}>
            {t("Organizations")}
          </Heading>

          {organizations.length > 0 ? (
            <OrganizationList organizations={organizations} isWinner={isWinner} />
          ) : (
            <Text fontSize="sm" fontStyle="italic" color={noContentColor}>
              {t("No organizations")}
            </Text>
          )}
        </Box>

        <Box p={4}>
          <Heading size="xs" textTransform="uppercase" color={subheadingColor} mb={3}>
            {t("Message")}
          </Heading>

          {message ? (
            <TipTap content={message} editable={false} />
          ) : (
            <Text fontSize="sm" fontStyle="italic" color={noContentColor}>
              {t("No message added")}
            </Text>
          )}
        </Box>
      </CardBody>
    </Card>
  );
};

export const Awarded = ({ sourcingEvent }: { sourcingEvent: BseDto }) => {
  const { t } = useTranslation();
  const { latestRound } = useNegotiation();
  const { data: bidsResponse } = useListReceivedBidsQuery({
    eventId: sourcingEvent.id,
    negotiationRound: latestRound?.id,
  });

  const winningBidIds = sourcingEvent.awardedFields?.winningBidIds || [];

  const winnerOrganizations: string[] = [];
  const loserOrganizations: string[] = [];

  if (bidsResponse?.bids) {
    const processedOrgs = new Set<string>();

    bidsResponse.bids.forEach((bid: ReceivedBseBidDto) => {
      const orgId = bid.owningOrganization.id;
      const orgName = bid.owningOrganization.name;

      if (processedOrgs.has(orgId)) return;
      if (winningBidIds.includes(bid.id)) winnerOrganizations.push(orgName);
      else loserOrganizations.push(orgName);

      processedOrgs.add(orgId);
    });
  }

  return (
    <>
      <Flex width="full" flexDirection="column">
        <AwardSection
          title={t("Winners")}
          message={sourcingEvent.awardedFields?.messageToWinners}
          organizations={winnerOrganizations}
          accentColor="teal"
          isWinner={true}
        />
        <AwardSection
          title={t("Losers")}
          message={sourcingEvent.awardedFields?.messageToLosers}
          organizations={loserOrganizations}
          accentColor="gray"
          isWinner={false}
        />
      </Flex>
      <EventValue data={sourcingEvent} />
      <BseReports data={sourcingEvent} />
    </>
  );
};
