import { Box, Checkbox, Icon, IconButton, Td, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { Dispatch, SetStateAction, useState } from "react";
import { FaRegComments } from "react-icons/fa";
import { BseDto, ReceivedBseBidDto } from "../../../../../autogen/bff-api";
import { Message } from "../../../../../common/ConversationModal";
import { Product, ProductDraft, ProductGroup } from "../../../../../common/types";
import { useNegotiation } from "../NegotiationContext";
import { getAwardedProductPrice, getAwardedProductSuppliers, getSelectedOrWinningBids } from "./BidEvaluationUtils";

export const EvaluationReportRow = ({
  product,
  group,
  bids,
  sourcingEvent,
  setProductConversation,
}: {
  product: Product;
  group: ProductGroup;
  bids?: ReceivedBseBidDto[];
  sourcingEvent: BseDto;
  setProductConversation: Dispatch<
    SetStateAction<
      | {
          conversation: Message[];
          title: string;
          productGroup: ProductGroup;
          product: Product | ProductDraft;
        }
      | undefined
    >
  >;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>();
  const { currentRound } = useNegotiation();
  const selectedBids = getSelectedOrWinningBids(sourcingEvent, currentRound);
  return (
    <Tr key={product.id}>
      {group.productFields
        .filter((field) => field.populatedBy === "Buyer")
        .map((field) => (
          <Td key={field.name} position={"relative"} minWidth="40" verticalAlign={"top"} pt="4" pb="0">
            {field.type === "Boolean" ? (
              <Checkbox
                isDisabled={true}
                isChecked={!!product.productFields.find((f) => f.name === field.name)?.value}
              />
            ) : (
              <Box
                as={"pre"}
                fontFamily={"body"}
                whiteSpace={"pre-wrap"}
                maxHeight={isExpanded ? "none" : "10"}
                overflow={"hidden"}
                cursor={"pointer"}
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {product.productFields.find((f) => f.name === field.name)?.value}
              </Box>
            )}
            <Box
              width={"full"}
              position={"absolute"}
              top="5"
              bottom="0"
              left="0"
              bgGradient={isExpanded ? "" : "linear(to bottom, transparent, smBackground)"}
              pointerEvents={"none"}
            ></Box>
          </Td>
        ))}
      <Td minWidth="40" verticalAlign={"top"} py="4">
        {product.quantity.value}
      </Td>
      <Td textAlign={"right"} verticalAlign={"top"} fontWeight={"bold"} py="4" pr="1">
        {getAwardedProductPrice({
          group,
          productId: product.id,
          bids: bids ?? [],
          selectedBids,
        })
          ? getAwardedProductPrice({
              group,
              productId: product.id,
              bids: bids ?? [],
              selectedBids,
            })?.toLocaleString()
          : ""}
      </Td>
      {
        <Td textAlign={"center"} verticalAlign={"top"} p="1">
          {getAwardedProductSuppliers({
            group,
            productId: product.id,
            bids: bids ?? [],
            selectedBids,
          })}
        </Td>
      }
      <Td verticalAlign={"top"} p="1">
        <IconButton
          variant={"outline"}
          aria-label="Product comments"
          icon={<Icon as={FaRegComments} />}
          onClick={() =>
            setProductConversation({
              conversation:
                product.conversation?.map((msg, i) => ({
                  id: `${i}`,
                  value: msg.comment,
                  createdBy: `${msg.createdBy?.firstName} ${msg.createdBy?.lastName}`,
                  createdAt: msg.createdAt,
                })) ?? [],
              title: `${t("Conversation regarding product")} - ${product.productFields[0].value}`,
              product,
              productGroup: group,
            })
          }
        />
      </Td>
    </Tr>
  );
};
