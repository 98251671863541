import { t } from "i18next";
import { utils, write } from "xlsx";
import { BseDto, NegotiationRoundDto, ReceivedBseBidDto } from "../../../../../autogen/bff-api";
import { ProductGroup } from "../../../../../common/types";
import { generateUniqueExcelSheetName, sanitizeFilename } from "../../../../../common/utils/sanitizeUtils";
import { findProductBid, getSelectedOrWinningBids, getTotalProductPrice } from "./BidEvaluationUtils";

/**
 * Generates an Excel report for the bid evaluation process
 * @param productGroups - The product groups in the sourcing event
 * @param bids - The received bids from suppliers
 * @param sourcingEvent - The sourcing event data
 */
export const generateReport = (
  productGroups: ProductGroup[],
  bids: ReceivedBseBidDto[] | undefined,
  sourcingEvent: BseDto,
  negotiationRound?: NegotiationRoundDto
) => {
  if (!productGroups?.length || !bids) throw Error("Could not generate report. No product groups found.");

  const workbook = utils.book_new();
  const selectedBids = getSelectedOrWinningBids(sourcingEvent, negotiationRound);
  const usedSheetNames = new Set<string>();

  for (const group of productGroups) {
    const headings = group.productFields.filter((f) => f.populatedBy === "Buyer").map((field) => field.name);
    headings.push(t("Quantity"));
    headings.push(t("Unit Price"));
    headings.push(t("Total price"));
    headings.push(t("Awarded to"));
    headings.push(...group.productFields.filter((f) => f.populatedBy === "Supplier").map((f) => f.name));

    const table: (string | number | undefined | boolean | null)[][] = [headings];
    if (!group.products) continue;

    const selectedGroupBidIds = selectedBids?.find((s) => s.productGroupId === group.id);
    for (const product of group.products) {
      if (!product.id) throw Error("No product id");

      const selectedProductBidIds = selectedGroupBidIds?.bids.find((b) => b.productId === product.id)?.bids;
      const selectedBids = bids.filter((bid) => selectedProductBidIds?.includes(bid.id));
      const selectedGroupBids = selectedBids?.map((b) => ({
        bid: b,
        groupBid: b.products?.find((p) => p.productGroupId === group.id),
        supplierName: b.owningOrganization.name,
      }));

      for (const selectedGroupBid of selectedGroupBids) {
        const productRow = group.productFields
          .filter((f) => f.populatedBy === "Buyer")
          .map((field) => product.productFields.find((f) => f.id === field.id)?.value);

        productRow.push(product.quantity.value);
        const productBid = findProductBid({ productId: product.id, groupId: group.id, bid: selectedGroupBid.bid });
        productRow.push(productBid?.unitPrice.value);

        if (selectedBids) {
          productRow.push(getTotalProductPrice({ group, productId: product.id, bid: selectedGroupBid.groupBid }));
        }

        productRow.push(selectedGroupBid.supplierName);
        productRow.push(
          ...group.productFields
            .filter((f) => f.populatedBy === "Supplier")
            .map((field) => productBid?.productFields.find((f) => f.id === field.id)?.value)
        );

        table.push(productRow);
      }
    }

    const sheet = utils.aoa_to_sheet(table);
    const sheetName = generateUniqueExcelSheetName(group.name, usedSheetNames);
    utils.book_append_sheet(workbook, sheet, sheetName);
  }

  const arrayBuffer = write(workbook, { bookType: "xlsx", type: "buffer" });
  const blob = new Blob([arrayBuffer], { type: "application/octet-stream" });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = `${sanitizeFilename(sourcingEvent.title)}.xlsx`;

  a.click();

  window.URL.revokeObjectURL(url);
};
