import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { t } from "i18next";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  BseDto,
  useListTextDocumentsForNegotiationRoundQuery,
  useListTextDocumentsForSourcingEventQuery,
} from "../../../../autogen/bff-api";
import { useSub } from "../../../../common/subscription/useSub";
import { urls } from "../../../../urls";
import { requirePublishedOrAwardedState } from "../../requirePublishedOrAwardedState";
import { Documents } from "./Documents";
import { Intro } from "./Intro";
import { useNegotiation } from "./NegotiationContext";

const requireDescription = (bse: BseDto): string => {
  const fields = requirePublishedOrAwardedState(bse);
  return fields.description;
};

export const Request = ({ event }: { event: BseDto }) => {
  const navigate = useNavigate();
  const subscriptions = useSub();
  const { currentRound } = useNegotiation();

  const { data: eventTextDocRes, isLoading: isLoadingEventTextDocuments } = useListTextDocumentsForSourcingEventQuery({
    eventId: event.id,
  });

  const { data: roundTextDocRes, isLoading: isLoadingRoundTextDocuments } =
    useListTextDocumentsForNegotiationRoundQuery(currentRound ? { negotiationRoundId: currentRound.id } : skipToken);

  const combinedDocs = useMemo(() => {
    // Get file documents from both event and current round
    const eventDocs = event?.documents ?? [];
    const roundDocs = currentRound?.documents ?? [];

    // Get text documents based on whether we have a current round
    const textDocs = currentRound ? roundTextDocRes?.documents ?? [] : eventTextDocRes?.documents ?? [];

    return [...eventDocs, ...textDocs, ...roundDocs];
  }, [event?.documents, eventTextDocRes?.documents, roundTextDocRes?.documents, currentRound]);

  return (
    <>
      <Flex columnGap="5" width="full" maxWidth="full" overflowX="hidden">
        <Box width="calc(50% - 10px)" maxWidth="calc(50% - 10px)" minWidth="calc(50% - 10px)" pt="5">
          <Intro
            description={currentRound ? currentRound?.description ?? "" : event ? requireDescription(event) : ""}
          />
        </Box>
        <Flex
          width="calc(50% - 10px)"
          maxWidth="calc(50% - 10px)"
          minWidth="calc(50% - 10px)"
          flexDirection="column"
          alignItems="end"
          py="5"
        >
          {subscriptions.hasProjectAccess && (
            <Flex flexDirection="column" alignItems="end" fontWeight="semibold" width="full" maxWidth="full">
              <Text fontSize="sm" pb="2">
                {t("Projects")}
              </Text>
              <Box width="full" maxWidth="full">
                {event?.projects?.map((p) => (
                  <Button
                    key={p.id}
                    size="xs"
                    textAlign="right"
                    display="inline-block"
                    width="full"
                    maxWidth="full"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    onClick={() => navigate(urls.projects.view.go(p.id))}
                  >{`${p.externalId ? `${p.externalId} - ` : ""}${p.name}`}</Button>
                ))}
              </Box>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex width="full" maxWidth="full" overflow="hidden" rounded="md" flexDirection="column" pt="8">
        <Text fontSize="sm" fontWeight="semibold" pb="2">
          {t("Documents")}
        </Text>
        {!(isLoadingEventTextDocuments || isLoadingRoundTextDocuments) && <Documents documents={combinedDocs} />}
      </Flex>
    </>
  );
};
