import { Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Spinner } from "@chakra-ui/react";
import { useState } from "react";
import { DateTimeSelector } from "./DateTimeSelector";

interface Props {
  id: string;
  title: string;
  helperText: string;
  defaultDate?: string;
  errorMessage: string | null;
  onChange: (date?: string) => Promise<void> | void;
  isDisabled?: boolean;
}

export const DateTimeFormControl = ({
  id,
  title,
  helperText,
  defaultDate,
  errorMessage,
  onChange,
  isDisabled,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <FormControl id={id} isInvalid={errorMessage !== null}>
      <FormLabel fontSize={"sm"}>
        {title}
        {isLoading && <Spinner ml="5px" size="xs" />}
      </FormLabel>
      <Flex justify="space-between" align="center">
        <DateTimeSelector
          defaultDate={defaultDate}
          isDisabled={isDisabled}
          onChange={async (date) => {
            setIsLoading(true);
            await onChange(date);
            setIsLoading(false);
          }}
        />
      </Flex>
      <FormHelperText>{helperText}</FormHelperText>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
