import { ReactElement } from "react";
import { ReceivedBseBidDto } from "../../../../../../autogen/bff-api";
import { ProductGroup } from "../../../../../../common/types";
import { PriceEvaluationModel } from "./PriceEvaluationModel";
import { QualitativeAIModel } from "./QualitativeAIModel";
import { QualityAdjustedPriceModel } from "./QualityAdjustedPriceModel";
import { WeightedPriceQualityModel } from "./WeightedPriceQualityModel";

// Enum for model types
export enum EvaluationModelType {
  LOWEST_PRICE = "lowest_price",
  PRICE_QUALITY = "price_quality",
  QUALITY_ADJUSTED = "quality_adjusted",
  QUALITATIVE_AI = "qualitative_ai",
}

// Common type for all evaluation model contexts
export interface EvaluationModelContext {
  bids: ReceivedBseBidDto[];
  productGroups: ProductGroup[];
  selectedBids?: unknown[];
}

// Interface for bid scores
export interface ScoredBid {
  bidId: string;
  supplierId: string;
  supplierName: string;
  totalScore: number;
  priceScore?: number;
  qualityScore?: number;
  details?: Record<string, unknown>;
  isWinner?: boolean;
}

// Interface for evaluation results
export interface EvaluationResult {
  scoredBids: ScoredBid[];
  bestBidId?: string;
  bestScore?: number;
  selectedBidIds?: string[];
}

// Extended winning bids with the winning bids property
export interface ExtendedWinningBids {
  productGroupId: string;
  winningBids: string[];
}

// Field type for model configuration
export type ConfigFieldType = "text" | "number" | "toggle" | "select" | "custom";

// Basic configuration field interface
export interface EvaluationModelConfigField {
  name: string;
  type: ConfigFieldType;
  label: string;
  description?: string;
  defaultValue?: unknown;
  options?: { label: string; value: string }[];
  render?: (value: unknown, onChange: (value: unknown) => void, config: Record<string, unknown>) => ReactElement;
}

// Evaluation model interface - all models must implement this
export interface EvaluationModel {
  type: EvaluationModelType;
  name: string;
  description: string;
  configFields: EvaluationModelConfigField[];
  getModelIcon(): JSX.Element;
  getConfigComponent?: (props: ModelConfigProps) => ReactElement | null;
  evaluateBids(context: EvaluationModelContext, config?: Record<string, unknown>): EvaluationResult;
}

// Props for model configuration components
export interface ModelConfigProps {
  config: Record<string, unknown>;
  onChange: (config: Record<string, unknown>) => void;
}

export const evaluationModels = {
  price: new PriceEvaluationModel(),
  priceQuality: new WeightedPriceQualityModel(),
  qualityAdjusted: new QualityAdjustedPriceModel(),
  qualitativeAi: new QualitativeAIModel(),
};
