import { Navigation } from "../edit/Navigation";

export const NegotiationNavigation = ({
  navigationHandlers,
}: {
  navigationHandlers: {
    navigateToIntro: () => void;
    navigateToProducts: () => void;
    navigateToDocs: () => void;
    navigateToParties: () => void;
    navigateToReview: () => void;
    navigateToEventView: () => void;
  } | null;
}) => {
  if (!navigationHandlers) return null;
  return (
    <Navigation
      navigateToIntro={navigationHandlers.navigateToIntro}
      navigateToProducts={navigationHandlers.navigateToProducts}
      navigateToDocs={navigationHandlers.navigateToDocs}
      navigateToParties={navigationHandlers.navigateToParties}
      navigateToReview={navigationHandlers.navigateToReview}
      navigateToEventView={navigationHandlers.navigateToEventView}
    />
  );
};
