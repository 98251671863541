import { Button, Icon } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { t } from "i18next";
import moment, { Moment } from "moment";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FaBan, FaCheckCircle, FaEdit, FaRegClock } from "react-icons/fa";
import { FaExclamationCircle } from "react-icons/fa";
import { BseSupplierViewDto, useDeclineInvitationToBidMutation, useGetBseBidQuery } from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { Explanation } from "../../../../common/support/Explanation/Explanation";
import { EditBidDraftButton } from "../../../bids/view-all/EditBidDraftButton";
import { CreateDraftButton } from "./buttons/CreateDraftButton";
import { EditDeliveredBidButton } from "./buttons/EditDeliveredBidButton";
import { GoToLatestRoundButton } from "./buttons/GoToLatestRoundButton";
import { ViewBidButton } from "./buttons/ViewBidButton";
import { useNegotiation } from "./NegotiationContext";
import { StatusBanner } from "./StatusBanner";

interface Props {
  event: BseSupplierViewDto;
}

export const BseBidBanner = ({ event }: Props) => {
  const { t } = useTranslation();
  const displayer = useApiError();
  const { roundNumber, currentRound } = useNegotiation();

  const bidId = currentRound ? currentRound.bidId : event.createdBidId;

  const { data: createdBid, error, isLoading } = useGetBseBidQuery(bidId ? { bidId } : skipToken);

  const isLastRound = useMemo(() => {
    if (!event.negotiationContextDto?.rounds?.length) return true; // No rounds means original event is the only one
    return roundNumber === event.negotiationContextDto.rounds.length; // Last round or original event if roundNumber is 0
  }, [event.negotiationContextDto?.rounds, roundNumber]);

  const currentDeadline = currentRound ? currentRound.deadline : event.deadline;

  const hasDeadlinePassed = deadlineHasPassed(moment(currentDeadline));

  const showActions = isLastRound && !hasDeadlinePassed;

  useEffect(() => {
    if (error) displayer.trigger(error);
  }, [displayer, error]);

  if (isLoading)
    return (
      <StatusBanner
        icon={FaRegClock}
        text={`${t("Loading bid status")}...`}
        border="1px solid"
        borderColor="smPrimarySofter"
        color="smPrimary"
      >
        <Button size="sm" isDisabled leftIcon={<Icon as={FaEdit} w="15px" h="15px" />}>
          {t("Edit")}
        </Button>
        <Button size="sm" isDisabled leftIcon={<Icon as={FaEdit} w="15px" h="15px" />}>
          {t("Decline")}
        </Button>
      </StatusBanner>
    );

  // Use the status from the current negotiation round if available, otherwise use the event status
  // currentRound is already cast to ExtendedSupplierNegotiationRoundDto in the NegotiationContext
  const activeStatus = currentRound?.status || event.status;

  switch (activeStatus) {
    case "DeadlinePassedAndBidDelivered":
      if (!createdBid) throw Error("In deadline passed and bid delivered state, but no bid found.");
      return (
        <StatusBanner
          icon={FaCheckCircle}
          text={`${t("Your bid is delivered")}`}
          secondaryText={`${t("The deadline has now passed")}.`}
          border="1px solid"
          borderColor="smPrimarySofter"
          color="smPrimary"
        >
          <ViewBidButton bid={createdBid} />
        </StatusBanner>
      );
    case "BidDelivered":
      if (!createdBid) throw Error("In bid delivered state, but no bid found.");
      return (
        <StatusBanner
          icon={FaCheckCircle}
          text={`${t("Your bid is delivered")}`}
          secondaryText={
            !isLastRound
              ? `${t("There are later negotiation rounds available")}.`
              : hasDeadlinePassed
              ? `${t("The deadline has passed")}.`
              : ""
          }
          border="1px solid"
          borderColor="smPrimarySofter"
          color="smPrimary"
        >
          {showActions && <EditDeliveredBidButton bid={createdBid} />}
          <ViewBidButton bid={createdBid} />
          {!isLastRound && <GoToLatestRoundButton size="sm" />}
        </StatusBanner>
      );
    case "DraftCreated":
      if (!createdBid) throw Error("In draft created state, but no draft found.");
      return (
        <StatusBanner
          icon={FaEdit}
          text={`${t("A draft has been created")}`}
          secondaryText={
            !isLastRound
              ? `${t("There are later negotiation rounds available")}.`
              : hasDeadlinePassed
              ? `${t("The deadline has passed")}.`
              : ""
          }
          border="1px solid"
          borderColor="smPrimarySofter"
          color="smPrimary"
        >
          {showActions && <EditBidDraftButton bid={createdBid} size="sm" />}
          {showActions && <DeclineButton eventId={event.id} />}
          {!isLastRound && <GoToLatestRoundButton size="sm" />}
        </StatusBanner>
      );
    case "InviteAccepted":
    case "InviteNotAccepted":
      return (
        <StatusBanner
          icon={FaExclamationCircle}
          text={`${t("Do you intend to deliver a bid?")}`}
          secondaryText={
            !isLastRound
              ? `${t("There are later negotiation rounds available")}.`
              : hasDeadlinePassed
              ? `${t("The deadline has passed")}.`
              : ""
          }
          border="1px solid"
          borderColor="smPrimarySofter"
          color="smPrimary"
        >
          {showActions && <CreateDraftButton event={event} shouldAcceptInvite={false} />}
          {showActions && <DeclineButton eventId={event.id} />}
          {!isLastRound && <GoToLatestRoundButton size="sm" />}
        </StatusBanner>
      );
    case "DeadlinePassedWithoutBidDelivered":
      return (
        <StatusBanner
          icon={FaBan}
          text={`${t("Deadline has passed")}`}
          secondaryText={`${t("No bid was delivered")}`}
          border="1px solid"
          borderColor="smBackgroundTertiary"
          color="smMuted"
        />
      );
    case "IsLoser":
      if (!createdBid) throw Error("In IsLoser state, but no bid found.");
      return (
        <StatusBanner icon={FaBan} text={`${t("You lost this event")}`} border="1px solid" borderColor="smPrimarySofter" color="smPrimary">
          <ViewBidButton bid={createdBid} />
        </StatusBanner>
      );
    case "IsWinner":
      if (!createdBid) throw Error("In IsWinner state, but no bid found.");
      return (
        <StatusBanner
          icon={FaCheckCircle}
          text={`${t("You won this event!")}`}
          border="1px solid"
          borderColor="smPrimarySofter"
          color="smPrimary"
        >
          <ViewBidButton bid={createdBid} />
        </StatusBanner>
      );
    case "InviteDeclined":
      return (
        <StatusBanner
          icon={FaBan}
          text={`${t("Invitation declined")}`}
          border="1px solid"
          borderColor="smTertiarySoft"
          color="smTertiary"
        ></StatusBanner>
      );
  }
};

const deadlineHasPassed = (deadline: Moment): boolean => {
  return moment().isAfter(deadline);
};

export const DeclineButton = ({ eventId, isDisabled = false }: { eventId: string; isDisabled?: boolean }) => {
  const [declineInvitation, { isLoading: isDeclining }] = useDeclineInvitationToBidMutation();

  return (
    <Explanation text={t("Decline the invitation to indicate that you will not deliver a bid")}>
      <Button
        colorScheme="red"
        variant="outline"
        leftIcon={<Icon as={FaBan} />}
        onClick={() => declineInvitation({ eventId })}
        isLoading={isDeclining}
        isDisabled={isDisabled}
        size="sm"
        ml="2"
      >
        {t("Decline")}
      </Button>
    </Explanation>
  );
};
