import { Box, Button, Flex, Icon, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  BseDto,
  EmailInviteDto,
  EmailInviteStatusDto,
  OrganizationInviteStatus,
  OrganizationType,
  PhoneNumberDto,
} from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { OrganizationTypeTag } from "../../../../common/organization/OrganizationTypeTag";
import { urls } from "../../../../urls";
import { displayPersonName } from "../../../contracts/view-single/sharing/AddExternalParticipantModal";
import { requireDeadlineHasPassed } from "../../requireDeadlineHasPassed";
import { EmaiInviteStatusTag } from "./EmailInviteStatusTag";
import { EmployeeInfo } from "./EmployeeInfo";
import { InvitedOrganizationStatusTag } from "./InvitedOrganizationStatusTag";
import { useNegotiation } from "./NegotiationContext";
import { ViewBidAsBuyerModal } from "./ViewBidAsBuyerModal";

interface Props {
  event: BseDto;
  awardToBid: (bidId: string) => Promise<void>;
  isAwarding: boolean;
}

interface CombinedInvite {
  organizationName: string;
  organizationId?: string;
  type: OrganizationType;
  persons: {
    name?: string;
    email: string;
    phoneNumber?: PhoneNumberDto;
    title?: string;
    status?: EmailInviteStatusDto;
  }[];
  status?: OrganizationInviteStatus;
  bidId?: string;
}

export const InvitedOrganizations = ({ event }: Props) => {
  const [bidIdToView, setBidIdToView] = useState<string>();
  const navigate = useNavigate();
  const deadlineHasPassed = requireDeadlineHasPassed(event);
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();
  const { currentRound } = useNegotiation();

  const orgInvites = useMemo(
    () => (currentRound ? currentRound.invites : event.organizationInvites),
    [currentRound, event.organizationInvites]
  );

  const groupedOrgEntryInvites = useMemo(() => {
    const invites: { [entryId: string]: EmailInviteDto[] } = {};
    const emailInvites = currentRound ? [] : event.emailInvites;
    emailInvites.forEach((invite) => {
      const invitesForOrgEntry = invites[invite.organizationEntry?.id ?? ""] ?? [];
      invites[invite.organizationEntry?.id ?? ""] = [...invitesForOrgEntry, invite];
    });
    return invites;
  }, [event.emailInvites, currentRound]);

  const combinedInvites: CombinedInvite[] = useMemo(() => {
    const organizationInvites: CombinedInvite[] = orgInvites.map((invite) => ({
      organizationName: invite.organizationName,
      organizationId: invite.organizationId,
      type: "Organization",
      persons: invite.persons.map((p) => ({
        name: displayPersonName(p),
        email: p.email,
        phone: p.phoneNumber,
        title: p.corporateTitle,
      })),
      status: invite.status,
      bidId: invite.bidId,
    }));
    const organizationEntryInvites: CombinedInvite[] = Object.values(groupedOrgEntryInvites).map((emailInvites) => ({
      organizationName: emailInvites[0].organizationEntry?.name ?? `${t("No organization")}`,
      type: "OrganizationEntry",
      persons: emailInvites.map((p) => ({
        name: displayPersonName(p),
        email: p.email,
        status: p.status,
      })),
    }));
    return [...organizationInvites, ...organizationEntryInvites];
  }, [orgInvites, groupedOrgEntryInvites, t]);

  return (
    <Box
      width="full"
      border="1px solid"
      borderColor="smBorder"
      borderRadius="md"
      overflowX={{ base: "auto", md: "visible" }}
      py="2.5"
    >
      {bidIdToView && (
        <ViewBidAsBuyerModal
          eventId={event.id}
          bidId={bidIdToView}
          isOpen={bidIdToView !== undefined}
          onClose={() => setBidIdToView(undefined)}
        />
      )}
      <Table variant="simple" size={"sm"}>
        <Thead>
          <Tr>
            <Th>{t("Company")}</Th>
            <Th>{t("Invited persons")}</Th>
            <Th textAlign="center">{t("Status")}</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {combinedInvites.length === 0 && (
            <Tr>
              <Td colSpan={4} width="full" textAlign="center">
                {t("No one have accepted their email invites, yet")}
              </Td>
            </Tr>
          )}
          {combinedInvites.map((invite) => {
            return (
              <Tr key={`${invite.organizationName}-${invite.type}`}>
                <Td>
                  {invite.type === "Organization" && !!invite.organizationId ? (
                    <Button
                      variant={"link"}
                      size="sm"
                      colorScheme={"teal"}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      onClick={() => navigate(urls.organizations.profile.go(invite.organizationId!))}
                    >
                      {invite.organizationName}
                    </Button>
                  ) : (
                    <Text fontWeight="semibold">{invite.organizationName}</Text>
                  )}
                  <Box pt="2">
                    <OrganizationTypeTag type={invite.type} />
                  </Box>
                </Td>
                <Td>
                  {invite.persons.map((p) => (
                    <EmployeeInfo
                      key={p.email}
                      fullName={p.name}
                      email={p.email}
                      phoneNumber={p.phoneNumber ?? null}
                      corporateTitle={p.title ?? null}
                    />
                  ))}
                </Td>
                <Td textAlign={"center"}>
                  {invite.type === "Organization" ? (
                    <InvitedOrganizationStatusTag status={invite.status as OrganizationInviteStatus} />
                  ) : (
                    <>
                      {invite.persons.map(({ status, email }) => (
                        <Box key={email} pb="2">
                          <EmaiInviteStatusTag status={status as EmailInviteStatusDto} />
                        </Box>
                      ))}
                    </>
                  )}
                </Td>
                <Td>
                  <Flex flexDirection="column" alignItems={"flex-start"} justifyContent="start">
                    {invite.bidId && (deadlineHasPassed || authState.selectedOrg.canSeeDeliveredBidsBeforeDeadline) && (
                      <Button
                        leftIcon={<Icon as={FaEye} w="13px" h="13px" />}
                        w="100px"
                        variant={"solid"}
                        colorScheme="teal"
                        size={"sm"}
                        onClick={() => setBidIdToView(invite.bidId)}
                      >
                        {t("View bid")}
                      </Button>
                    )}
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};
