import { Flex, Skeleton } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BseStatusDto, useGetBasicSourcingEventQuery } from "../../../../autogen/bff-api";
import { ProcessBar } from "../../../../common/ProcessBar";

import { isFetchBaseQueryError } from "../../../../common/errors/isFetchBaseQueryError";
import { useApiError } from "../../../../common/errors/useApiError";
import { Layout } from "../../../../common/layout/Layout";
import { useAppDispatch, useAppSelector } from "../../../../common/redux/hooks";
import { setSourcingEvent } from "../../../../common/redux/reducers/basicSourcingEventReducer";
import { useSub } from "../../../../common/subscription/useSub";
import { BseBuyerTab, useQueryParams } from "../../../../common/useQueryParams";
import { CONTENT_WIDTH, requireBooleanEnvVar } from "../../../../config";
import { BseTabs, getCurrentStep } from "./BseTabs";
import { EventDialog } from "./EventDialog";
import { EventMeta } from "./EventMeta";
import { NegotiationProvider } from "./NegotiationContext";

export const tabs = ["request", "bids", "evaluation", "award", "contract"].filter((tab) => !!tab) as BseBuyerTab[];

export const BseView = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const params = useQueryParams();
  const tab = params.get("tab");
  const subscriptions = useSub();
  const displayer = useApiError();
  const eventState = useAppSelector((state) => state.basicSourcingEvent.state);
  const { eventId } = useParams();

  const { data: event, error, isLoading } = useGetBasicSourcingEventQuery(eventId ? { eventId } : skipToken);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (eventState?.id !== eventId) {
      if (event) dispatch(setSourcingEvent(event));
      else dispatch(setSourcingEvent(null));
    }
  }, [event, dispatch, eventState, eventId]);

  const stateToStepMap = useMemo(() => {
    const stateToStepMap: { [state in BseStatusDto]: string } = {
      Draft: t("Create event"),
      Published: t("Receive bids"),
      DeadlineHasPassed: subscriptions.hasProductAccess ? t("Evaluate bids") : t("Award winners"),
      Completed: t("Award winners"),
      Awarded: t("Event completed"),
    };
    return stateToStepMap;
  }, [subscriptions.hasProductAccess, t]);

  useEffect(() => {
    const index = tabs.indexOf(tab as BseBuyerTab);
    if (tab && index > -1) setTabIndex(index);
  }, [tab]);

  useEffect(() => {
    return () => {
      dispatch(setSourcingEvent(null));
    };
  }, [dispatch]);

  if (error) {
    if (isFetchBaseQueryError(error) && error.status === 403) return displayer.showNoAccess();
    return displayer.show(error);
  }

  return (
    <>
      {event && <EventDialog event={event} />}
      <Layout>
        <Flex width="full" justifyContent="center" px="5">
          <Flex
            flexDirection="column"
            height="full"
            width={`${CONTENT_WIDTH}`}
            maxWidth={`min(${CONTENT_WIDTH}, 100%)`}
          >
            <Flex flexDirection="column" justifyContent="center">
              {eventState && eventId === eventState?.id && (
                <>
                  <ProcessBar
                    steps={Array.from(new Set(Object.values(stateToStepMap)))}
                    currentStep={getCurrentStep(eventState, stateToStepMap)}
                    loopTo={
                      requireBooleanEnvVar("VITE_ENABLE_NEGOTIATIONS")
                        ? [{ step: stateToStepMap.Published, description: t("Negotiation") }]
                        : undefined
                    }
                  />
                  <NegotiationProvider event={eventState.event}>
                    <EventMeta event={eventState.event} />
                    <Flex flexDirection="column" width="full" maxWidth="full" overflowX="hidden">
                      <BseTabs
                        eventState={eventState}
                        subscriptions={subscriptions}
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                        params={params}
                      />
                    </Flex>
                  </NegotiationProvider>
                </>
              )}
              {isLoading && (
                <Flex flexDirection="column" justifyContent="center">
                  <ProcessBar steps={Array.from(new Set(Object.values(stateToStepMap)))} currentStep="" />
                  <Flex width="full" flexDirection="column" alignItems="center" px="2" py="10">
                    <Flex flexDirection="column" rowGap="2" justifyContent="space-between" alignItems="center">
                      <Skeleton
                        width="60"
                        height="6"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                      <Skeleton
                        width="48"
                        height="6"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                      <Skeleton
                        width="80"
                        height="4"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                    </Flex>
                  </Flex>
                  <Flex flexDirection="column" px="2" pt="8">
                    <Flex width="full" columnGap="2" justifyContent="center" pt="2" pb="12">
                      <Skeleton
                        width="32"
                        height="8"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                      <Skeleton
                        width="32"
                        height="8"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                      <Skeleton
                        width="32"
                        height="8"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                      <Skeleton
                        width="32"
                        height="8"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                      <Skeleton
                        width="32"
                        height="8"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                    </Flex>
                    <Flex flexDirection="column" rowGap="4" pt="2">
                      <Skeleton
                        width="50%"
                        height="40"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                      <Skeleton
                        width="full"
                        height="40"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Layout>
    </>
  );
};
