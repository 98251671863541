import { Box, CircularProgress, CircularProgressLabel, Flex, Text, useToast } from "@chakra-ui/react";
import { uniqBy } from "lodash";
import { useTranslation } from "react-i18next";
import { BseDto, useAwardToBidMutation } from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { InvitedOrganizations } from "./InvitedOrganizations";
import { useNegotiation } from "./NegotiationContext";

interface Props {
  data: BseDto;
}

export const Bids = ({ data }: Props) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [awardToBidMutation, { isLoading: isAwarding }] = useAwardToBidMutation();
  const apiErrorDisplayer = useApiError();
  const { currentRound } = useNegotiation();

  const awardToBid = async (bidId: string) => {
    await awardToBidMutation({
      eventId: data.id,
      awardToBidRequest: {
        bidIds: [bidId],
      },
    })
      .unwrap()
      .catch((error) => {
        apiErrorDisplayer.trigger(error);
      })
      .then(() => {
        toast({
          title: t("Event awarded!"),
          description: t("The event has been successfully awarded!"),
          status: "success",
        });
      });
  };

  const invites = currentRound?.invites || data.organizationInvites;
  const emailInvites = currentRound ? [] : data.emailInvites; // Email invites are only in the original event

  const draftCreated = invites.filter(
    ({ status }) =>
      status === "DraftCreated" || status === "BidIsSubmitted" || status === "IsWinner" || status === "IsLoser"
  ).length;

  const numberOfInvitedParties = uniqBy(emailInvites, (invite) => invite.organizationEntry?.id).length + invites.length;

  const numberOfDeliveredBids = invites.filter(
    (invite) => invite.status === "BidIsSubmitted" || invite.status === "IsWinner" || invite.status === "IsLoser"
  ).length;

  const declinedInvitations = invites.filter((invite) => invite.status === "InvitationDeclined").length;

  return (
    <>
      <Flex width="full" justifyContent="center" columnGap={{ base: 5, md: 20 }} pt="5">
        <Flex flexDirection="column" alignItems="center">
          <CircularProgress
            capIsRound
            value={(declinedInvitations / numberOfInvitedParties) * 100}
            size={32}
            color="smDanger"
            trackColor="smBackgroundSecondary"
          >
            <CircularProgressLabel fontSize="lg">{`${declinedInvitations} ${t(
              "of"
            )} ${numberOfInvitedParties}`}</CircularProgressLabel>
          </CircularProgress>
          <Text size="sm" fontWeight="semibold">
            {t("Declined")}
          </Text>
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <CircularProgress
            capIsRound
            value={(draftCreated / numberOfInvitedParties) * 100}
            size={32}
            color="smPrimary"
            trackColor="smBackgroundSecondary"
          >
            <CircularProgressLabel fontSize="lg">{`${draftCreated} ${t(
              "of"
            )} ${numberOfInvitedParties}`}</CircularProgressLabel>
          </CircularProgress>
          <Text size="sm" fontWeight="semibold">
            {t("Draft created")}
          </Text>
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <CircularProgress
            capIsRound
            value={(numberOfDeliveredBids / numberOfInvitedParties) * 100}
            size={32}
            color="smTertiary"
            trackColor="smBackgroundSecondary"
          >
            <CircularProgressLabel fontSize="lg">
              {`${numberOfDeliveredBids} ${t("of")} ${numberOfInvitedParties}`}
            </CircularProgressLabel>
          </CircularProgress>
          <Text size="sm" fontWeight="semibold">
            {t("Bid delivered")}
          </Text>
        </Flex>
      </Flex>
      <Box display={"flex"} borderRadius={"md"} pt="10" flexDirection={"column"}>
        <Text fontSize={"lg"} fontWeight="semibold">
          {t("Invited companies & bids")}
        </Text>
        <Box h="15px"></Box>
        <InvitedOrganizations event={data} awardToBid={awardToBid} isAwarding={isAwarding} />
      </Box>
    </>
  );
};
