import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  Spinner,
  Switch,
  Table,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";
import { cloneDeep } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";

import { FaDownload, FaEye } from "react-icons/fa";
import {
  BseDto,
  ReceivedBseBidDto,
  useCompleteEvaluationMutation,
  useCompleteNegotiationRoundEvaluationMutation,
  useEditNegotiationRoundMutation,
  useListReceivedBidsQuery,
  useOpenCompletedEventMutation,
  useOpenNegotiationRoundMutation,
  useUpdateBasicSourcingEventMutation,
} from "../../../../../autogen/bff-api";
import { ConversationModal, Message } from "../../../../../common/ConversationModal";
import { useLoggedInWithOrgContextState } from "../../../../../common/auth/useLoggedInWithOrgContextState";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import {
  selectAllBids,
  setNegotiationRound,
  setSourcingEvent,
} from "../../../../../common/redux/reducers/basicSourcingEventReducer";
import { updateProductGroup } from "../../../../../common/redux/reducers/productReducer";
import { editProductGroupThunk } from "../../../../../common/redux/thunks/product/editProductGroupThunk";
import { Product, ProductDraft, ProductGroup } from "../../../../../common/types";

import { NegotiationRoundDto } from "../../../../../autogen/bff-api";
import { eventIsAwarded } from "../../../eventIsAwarded";
import { eventIsCompleted } from "../../../eventIsCompleted";
import { useProductGroups } from "../../common/useProductGroups";
import { useNegotiation } from "../NegotiationContext";
import { ViewBidAsBuyerModal } from "../ViewBidAsBuyerModal";
import {
  getQuotesDelivered,
  getQuotesDeliveredInBid,
  getQuotesRequested,
  getSelectedOrWinningBids,
  getSumOfBestPrices,
  getSumOfSelectedPrices,
  getTotalPrice,
  isBestTotalPrice,
} from "./BidEvaluationUtils";
import { EvaluationTable } from "./EvaluationTable";
import { EvaluationResults } from "./components/EvaluationResults";
import { ModelConfigurationPanel } from "./components/ModelConfigurationPanel";
import { ModelSelector } from "./components/ModelSelector";
import { QualityAssessmentPanel } from "./components/QualityAssessmentPanel";
import { QualityPriceAdjustmentPanel } from "./components/QualityPriceAdjustmentPanel";
import {
  EvaluationModel,
  evaluationModels,
  EvaluationModelType,
  EvaluationResult,
} from "./evaluation-models/EvaluationModelTypes";
import { generateReport } from "./reportGenerator";

const isDeadlinePassed = ({ event, round }: { event: BseDto; round?: NegotiationRoundDto }): boolean => {
  if (round) return moment().isAfter(moment(round.deadline));
  return moment().isAfter(moment(event.publishedFields?.deadline ?? event.awardedFields?.deadline));
};

export const BidEvaluation = ({ sourcingEvent }: { sourcingEvent: BseDto }) => {
  const dispatch = useAppDispatch();
  const authState = useLoggedInWithOrgContextState();
  const { productGroups } = useProductGroups(sourcingEvent.id);
  const [updateSourcingEvent] = useUpdateBasicSourcingEventMutation();
  const [editNegotiationRound] = useEditNegotiationRoundMutation();
  const [completeEvent] = useCompleteEvaluationMutation();
  const [completeNegotiationRound] = useCompleteNegotiationRoundEvaluationMutation();
  const [openCompletedEvent] = useOpenCompletedEventMutation();
  const [openCompletedNegotiationRound] = useOpenNegotiationRoundMutation();

  const { currentRound } = useNegotiation();

  const {
    data: bidsResponse,
    error,
    isLoading,
  } = useListReceivedBidsQuery({ eventId: sourcingEvent.id, negotiationRound: currentRound?.id });

  const bids = useMemo(() => bidsResponse?.bids ?? [], [bidsResponse?.bids]);

  const selectedBids = getSelectedOrWinningBids(sourcingEvent, currentRound);

  const [isToggleLoading, setIsToggleLoading] = useState(false);

  // Model evaluation state - default to price model
  const [selectedModel, setSelectedModel] = useState<EvaluationModel>(evaluationModels.price);
  const [modelConfig, setModelConfig] = useState<Record<string, unknown>>({});
  const [evaluationResult, setEvaluationResult] = useState<EvaluationResult>();
  const [qualityAssessments, setQualityAssessments] = useState<Record<string, Record<string, number>>>({});
  const [priceAdjustments, setPriceAdjustments] = useState<Record<string, Record<string, number>>>({});

  // Initialize evaluation with price model
  // Determine if we're using a model that needs quality assessment
  const needsQualityAssessment = selectedModel?.type === EvaluationModelType.PRICE_QUALITY;
  const needsPriceAdjustment = selectedModel?.type === EvaluationModelType.QUALITY_ADJUSTED;

  // Get available quality factors from the selected model if applicable
  const qualityFactors = useMemo(() => {
    // Include both quality assessment and price adjustment models
    if ((needsQualityAssessment || needsPriceAdjustment) && selectedModel) {
      const factorField = selectedModel.configFields.find((field) => field.name === "qualityFactors");
      if (factorField && factorField.type === "select") {
        return (modelConfig.qualityFactors as string[]) || (factorField.defaultValue as string[]);
      }
    }
    return [];
  }, [selectedModel, modelConfig, needsQualityAssessment, needsPriceAdjustment]);

  // Get factor options from the model for labels
  const factorOptions = useMemo(() => {
    if ((needsQualityAssessment || needsPriceAdjustment) && selectedModel) {
      const factorField = selectedModel.configFields.find((field) => field.name === "qualityFactors");
      if (factorField && factorField.type === "select" && factorField.options) {
        return factorField.options as { label: string; value: string }[];
      }
    }
    return [];
  }, [selectedModel, needsQualityAssessment, needsPriceAdjustment]);

  // Handle quality assessment changes
  const handleQualityAssessmentChange = useCallback((assessments: Record<string, Record<string, number>>) => {
    setQualityAssessments(assessments);
  }, []);

  // Handle price adjustment changes with immediate evaluation trigger
  const handlePriceAdjustmentChange = useCallback(
    (adjustments: Record<string, Record<string, number>>) => {
      // Create deep copies to break reference chains and ensure clean state updates
      const adjustmentsCopy = JSON.parse(JSON.stringify(adjustments));
      setPriceAdjustments(adjustmentsCopy);

      // Force immediate evaluation when adjustments change
      if (bids && bids.length > 0 && productGroups && selectedModel) {
        const context = {
          bids,
          productGroups,
          selectedBids: selectedBids,
        };

        // Update model configuration with fresh data
        const configWithAssessments = {
          ...modelConfig,
          qualityAssessments: needsQualityAssessment ? qualityAssessments : undefined,
          priceAdjustments: needsPriceAdjustment ? adjustmentsCopy : undefined,
        };

        // Update model config state
        setModelConfig(configWithAssessments);

        // Evaluate and update results
        const result = selectedModel.evaluateBids(context, configWithAssessments);
        setEvaluationResult({ ...result }); // Use object spread to force state update

        // Use requestAnimationFrame to ensure UI updates properly after state changes
        requestAnimationFrame(() => {
          const refreshedResult = selectedModel.evaluateBids(context, configWithAssessments);
          setEvaluationResult({ ...refreshedResult });
        });
      }
    },
    [
      bids,
      modelConfig,
      needsQualityAssessment,
      needsPriceAdjustment,
      productGroups,
      qualityAssessments,
      selectedBids,
      selectedModel,
    ]
  );

  // Evaluate bids when necessary inputs change
  useEffect(() => {
    if (bids && bids.length > 0 && productGroups && selectedModel) {
      const context = {
        bids,
        productGroups,
        selectedBids: selectedBids,
      };

      // Add quality assessments or price adjustments to config based on model type
      const configWithAssessments = {
        ...modelConfig,
        qualityAssessments: needsQualityAssessment ? qualityAssessments : undefined,
        priceAdjustments: needsPriceAdjustment ? priceAdjustments : undefined,
      };

      const result = selectedModel.evaluateBids(context, configWithAssessments);
      setEvaluationResult(result);
    }
  }, [
    bids,
    productGroups,
    selectedBids,
    modelConfig,
    selectedModel,
    qualityAssessments,
    priceAdjustments,
    needsQualityAssessment,
    needsPriceAdjustment,
  ]);

  const completeEvaluation = async () => {
    setIsToggleLoading(true);
    try {
      if (currentRound) {
        const res = await completeNegotiationRound({ eventId: sourcingEvent.id, negotiationId: currentRound.id });
        if ("data" in res) dispatch(setNegotiationRound(res.data));
      } else {
        const res = await completeEvent({ eventId: sourcingEvent.id });
        if ("data" in res) dispatch(setSourcingEvent(res.data));
      }
    } finally {
      setIsToggleLoading(false);
    }
  };

  const openCompleted = async () => {
    setIsToggleLoading(true);
    try {
      if (currentRound) {
        const res = await openCompletedNegotiationRound({ eventId: sourcingEvent.id, negotiationId: currentRound.id });
        if ("data" in res) dispatch(setNegotiationRound(res.data));
      } else {
        const res = await openCompletedEvent({ eventId: sourcingEvent.id });
        if ("data" in res) dispatch(setSourcingEvent(res.data));
      }
    } finally {
      setIsToggleLoading(false);
    }
  };

  const [productConversation, setProductConversation] = useState<{
    conversation: Message[];
    title: string;
    productGroup: ProductGroup;
    product: Product | ProductDraft;
  }>();

  const [bidIdToView, setBidIdToView] = useState<string>();

  const selectAllBidsFromSupplier = useCallback(
    (bid: ReceivedBseBidDto) => {
      dispatch(selectAllBids(bid));

      const selectProductBidsPayload = bid.products?.map((groupBid) => ({
        productGroupId: groupBid.productGroupId,
        bids: groupBid.productBids.map((productBid) => ({
          productId: productBid.productId,
          bids: [bid.id],
        })),
      }));

      if (currentRound?.id) {
        editNegotiationRound({
          negotiationId: currentRound.id,
          editNegotiationRequest: {
            selectProductBids: selectProductBidsPayload,
          },
        });
      } else {
        updateSourcingEvent({
          eventId: sourcingEvent.id,
          editSourcingEventRequest: {
            selectProductBids: selectProductBidsPayload,
          },
        });
      }
    },
    [dispatch, currentRound, editNegotiationRound, sourcingEvent.id, updateSourcingEvent]
  );

  const handleComment = async ({
    group,
    product,
    comment,
  }: {
    group: ProductGroup;
    product: Product | ProductDraft;
    comment: string;
  }) => {
    const updatedGroup = cloneDeep(group);
    if (!updatedGroup.products) throw Error("No products found - could not handle comment.");
    const productIndex = updatedGroup.products.findIndex((p) => p.id === product.id);
    if (productIndex && productIndex < 0) throw Error(`Product w id=${product.id} not found.`);
    const updatedProduct = updatedGroup.products[productIndex];
    const message = {
      comment,
      createdAt: moment().toISOString(),
      modifiedAt: moment().toISOString(),
      createdBy: authState.me,
    };
    productConversation?.conversation.push({
      id: `${productConversation.conversation.length}`,
      value: message.comment,
      createdAt: message.createdAt,
      createdBy: `${message.createdBy.firstName} ${message.createdBy.lastName}`,
    });
    updatedProduct?.conversation?.push(message);
    dispatch(updateProductGroup(updatedGroup));
    await dispatch(
      editProductGroupThunk({
        id: group.id,
        editProduct: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: updatedProduct.id!,
          comment: { comment },
        },
      })
    );
  };

  const handleGenerateReport = useCallback(() => {
    generateReport(productGroups, bids, sourcingEvent, currentRound);
  }, [bids, productGroups, sourcingEvent, currentRound]);

  // Handle model selection and automatically evaluate
  const handleModelSelect = useCallback(
    (model: EvaluationModel) => {
      setSelectedModel(model);
      // Initialize with default configuration
      const defaultConfig = model.configFields.reduce((acc, field) => {
        if (field.defaultValue !== undefined) {
          acc[field.name] = field.defaultValue;
        }
        return acc;
      }, {} as Record<string, unknown>);

      setModelConfig(defaultConfig);

      // Only evaluate if we have bids
      if (bids && bids.length > 0 && productGroups) {
        const context = {
          bids,
          productGroups,
          selectedBids: selectedBids,
        };

        const result = model.evaluateBids(context, defaultConfig);
        setEvaluationResult(result);
      } else {
        setEvaluationResult(undefined);
      }
    },
    [bids, productGroups, selectedBids]
  );

  // Handle bid selection from evaluation results
  const handleSelectBid = useCallback(
    (bidId: string) => {
      const bid = bids?.find((b) => b.id === bidId);
      if (bid) {
        selectAllBidsFromSupplier(bid);
      }
    },
    [bids, selectAllBidsFromSupplier]
  );

  // Handle configuration changes and automatically evaluate
  const handleConfigChange = useCallback(
    (config: Record<string, unknown>) => {
      setModelConfig(config);

      // Auto-evaluate when config changes
      if (selectedModel && bids && bids.length > 0 && productGroups) {
        const context = {
          bids,
          productGroups,
          selectedBids: selectedBids,
        };

        const result = selectedModel.evaluateBids(context, config);
        setEvaluationResult(result);
      }
    },
    [selectedModel, bids, productGroups, selectedBids]
  );

  if (isLoading)
    return (
      <Flex w="full" height="full" minHeight={"96"} justify={"center"} alignItems={"center"}>
        <Spinner />
      </Flex>
    );

  if (error) return <p>{t("Something went wrong, our team is on it!")}</p>;

  if (!productGroups?.length)
    return (
      <Text color="gray.500" textAlign={"center"} pt="8">
        {t("No products to evaluate found for this event")}.
      </Text>
    );

  if (!bids?.length)
    return (
      <Text color="gray.500" textAlign={"center"} pt="8">
        {t("No bids delivered for this event")}.
      </Text>
    );

  return (
    <>
      <ConversationModal
        isOpen={!!productConversation}
        title={productConversation?.title}
        conversation={productConversation?.conversation.map((msg) => ({
          id: msg.id,
          value: msg.value,
          createdBy: msg.createdBy,
          createdAt: moment(msg.createdAt).format("DD MMM YY hh.mm"),
        }))}
        onClose={() => setProductConversation(undefined)}
        handleMessage={(comment: string) => {
          if (!productConversation) return;
          handleComment({ comment, product: productConversation.product, group: productConversation.productGroup });
        }}
        isLoading={false}
        canComment={sourcingEvent.canEdit}
      />
      {bidIdToView && (
        <ViewBidAsBuyerModal
          eventId={sourcingEvent.id}
          bidId={bidIdToView}
          isOpen={bidIdToView !== undefined}
          onClose={() => setBidIdToView(undefined)}
        />
      )}
      {/* Advanced Evaluation Section */}
      <VStack spacing={5} align="stretch">
        {/* Model Selection */}
        <Box>
          <ModelSelector selectedModelType={selectedModel?.type} onSelectModel={handleModelSelect} />
        </Box>

        {/* Model Configuration - only show if model has config options */}
        {selectedModel && selectedModel.configFields && selectedModel.configFields.length > 0 && (
          <Box>
            <ModelConfigurationPanel
              model={selectedModel}
              onConfigChange={handleConfigChange}
              initialConfig={modelConfig}
            />
          </Box>
        )}

        {/* Quality Assessment - only show for weighted price-quality model */}
        {needsQualityAssessment && qualityFactors.length > 0 && bids && bids.length > 0 && (
          <Box mt={4}>
            <QualityAssessmentPanel
              bids={bids}
              qualityFactors={qualityFactors}
              onAssessmentChange={handleQualityAssessmentChange}
              factorOptions={factorOptions}
            />
          </Box>
        )}

        {/* Price Adjustment - only show for quality adjusted price model */}
        {needsPriceAdjustment && qualityFactors.length > 0 && bids && bids.length > 0 && (
          <Box mt={4}>
            <QualityPriceAdjustmentPanel
              bids={bids}
              qualityFactors={qualityFactors}
              onAdjustmentChange={handlePriceAdjustmentChange}
              factorOptions={factorOptions}
              maxAdjustmentPercent={Number(modelConfig.factorValues) || 15}
              productGroups={productGroups}
            />
          </Box>
        )}

        {/* Evaluation Results - only show if not using price model */}
        {evaluationResult && selectedModel?.type !== EvaluationModelType.LOWEST_PRICE && (
          <Box mt={4}>
            <Divider mb={6} />
            <Heading size="md" mb={4}>
              {t("Evaluation Results")}
            </Heading>
            <EvaluationResults
              result={evaluationResult}
              onSelectBid={handleSelectBid}
              modelType={selectedModel?.type}
            />
          </Box>
        )}
      </VStack>

      <FormControl display="flex" justifyContent="center" alignItems={"center"} flexDirection={"column"} py="10">
        <FormLabel htmlFor="email-alerts" m="0">
          {t("Complete Evaluation")}
        </FormLabel>
        <Flex align="center" mt="2" position="relative" justifyContent="center" minHeight="8">
          <Switch
            size={"lg"}
            colorScheme="teal"
            disabled={
              isToggleLoading ||
              eventIsAwarded(sourcingEvent) ||
              !isDeadlinePassed({ event: sourcingEvent, round: currentRound })
            }
            isChecked={eventIsCompleted({ event: sourcingEvent, round: currentRound })}
            onChange={async (e) => {
              if (e.target.checked) {
                completeEvaluation();
              } else {
                openCompleted();
              }
            }}
          />
          {isToggleLoading && (
            <Box position="absolute" right="-8" display="flex" alignItems="center" justifyContent="center">
              <Spinner size="sm" color="smPrimary" />
            </Box>
          )}
        </Flex>
        <FormHelperText pb="8">
          {eventIsCompleted({ event: sourcingEvent, round: currentRound })
            ? t("The evaluation is completed")
            : t("Toggle to complete the evaluation with the current price selection")}
        </FormHelperText>
      </FormControl>
      <Table>
        <Thead>
          <Tr>
            <Th p="1"></Th>
            {bids.map((bid) => (
              <Th key={bid.id} p="1" textAlign={"center"}>
                {bid.owningOrganization.name}
              </Th>
            ))}
            <Th textAlign={"center"}>{t("Best prices")}</Th>
            <Th textAlign={"center"}>{t("Selected")}</Th>
          </Tr>
          {productGroups && (
            <>
              <Tr fontWeight={"bold"}>
                <Td>{t("SUM Total")}</Td>
                {bids.map((bid) => (
                  <Td
                    key={bid.id}
                    textAlign={"center"}
                    cursor={"pointer"}
                    color={isBestTotalPrice({ bid, bids, groups: productGroups }) ? "green.500" : "yellow.500"}
                    onClick={() => selectAllBidsFromSupplier(bid)}
                  >
                    {getTotalPrice({ bid, groups: productGroups })?.toLocaleString()}
                  </Td>
                ))}
                <Td textAlign={"center"}>{getSumOfBestPrices({ groups: productGroups, bids })?.toLocaleString()}</Td>
                <Td textAlign={"center"}>
                  {getSumOfSelectedPrices({
                    groups: productGroups,
                    bids,
                    selectedBids,
                  }).toLocaleString()}
                </Td>
              </Tr>
              <Tr>
                <Td py="1">{t("Product quotes")}</Td>
                {bids.map((bid) => (
                  <Td
                    key={bid.id}
                    textAlign={"center"}
                    color={
                      getQuotesDeliveredInBid({ bid }) >= getQuotesRequested(productGroups) ? "green.500" : "yellow.500"
                    }
                  >
                    {getQuotesDeliveredInBid({ bid })}/{getQuotesRequested(productGroups)}
                  </Td>
                ))}
                <Td
                  textAlign={"center"}
                  color={
                    getQuotesDelivered({ groups: productGroups, bids }) >= getQuotesRequested(productGroups)
                      ? "green.500"
                      : "yellow.500"
                  }
                >
                  {getQuotesDelivered({ groups: productGroups, bids })}/{getQuotesRequested(productGroups)}
                </Td>
                <Td></Td>
              </Tr>
              <Tr>
                <Td></Td>
                {bids.map((bid) => (
                  <Td key={bid.id} textAlign={"center"} py="1">
                    <Button
                      size="sm"
                      colorScheme="teal"
                      variant="ghost"
                      leftIcon={<Icon as={FaEye} />}
                      onClick={() => setBidIdToView(bid.id)}
                    >
                      {t("View bid")}
                    </Button>
                  </Td>
                ))}
                <Td></Td>
                <Td></Td>
              </Tr>
            </>
          )}
        </Thead>
      </Table>
      <Box rounded="lg" p="4" mt="20">
        <Box overflow={"auto"}>
          {productGroups?.map((group) => (
            <EvaluationTable
              key={group.id}
              sourcingEvent={sourcingEvent}
              bids={bids}
              group={group}
              setProductConversation={setProductConversation}
            />
          ))}
        </Box>
      </Box>
      {(eventIsCompleted({ event: sourcingEvent, round: currentRound }) ||
        authState.selectedOrg.canSeeDeliveredBidsBeforeDeadline) && (
        <Flex justifyContent={"end"} pb="4" pr="4">
          <Button colorScheme="teal" variant={"outline"} onClick={handleGenerateReport}>
            {t("Report")} <Icon as={FaDownload} ml="2" />
          </Button>
        </Flex>
      )}
    </>
  );
};
