import { Icon } from "@chakra-ui/react";
import React from "react";
import { FaBalanceScale } from "react-icons/fa";
import { ReceivedBseBidDto } from "../../../../../../autogen/bff-api";
import { getTotalPrice } from "../BidEvaluationUtils";
import {
  EvaluationModel,
  EvaluationModelConfigField,
  EvaluationModelContext,
  EvaluationModelType,
  EvaluationResult,
  ExtendedWinningBids,
  ScoredBid,
} from "./EvaluationModelTypes";

/**
 * The WeightedPriceQualityModel implements a traditional weighted evaluation model
 * where price and quality are assigned relative weights for the final score.
 */
export class WeightedPriceQualityModel implements EvaluationModel {
  public type = EvaluationModelType.PRICE_QUALITY;
  public name = "Weighted Price-Quality";
  public description = "Traditional model with configurable weights for price and quality factors";

  public configFields: EvaluationModelConfigField[] = [
    {
      name: "priceWeight",
      type: "number",
      label: "Price Weight (%)",
      description: "Weight given to price in the evaluation (quality weight will be the remainder)",
      defaultValue: 60,
    },
    {
      name: "qualityFactors",
      type: "select",
      label: "Quality Factors",
      description: "Select quality factors to include in evaluation",
      defaultValue: ["deliveryTime", "technicalCompliance", "warranty"],
      options: [
        { label: "Delivery Time", value: "deliveryTime" },
        { label: "Technical Compliance", value: "technicalCompliance" },
        { label: "Warranty Terms", value: "warranty" },
        { label: "Support Services", value: "support" },
        { label: "Environmental Impact", value: "environmental" },
        { label: "Supplier Reliability", value: "reliability" },
      ],
    },
  ];

  public getModelIcon(): JSX.Element {
    return React.createElement(Icon, { as: FaBalanceScale });
  }

  public evaluateBids(context: EvaluationModelContext, config?: Record<string, unknown>): EvaluationResult {
    const { bids, productGroups, selectedBids } = context;
    const priceWeight = ((config?.priceWeight as number) ?? 60) / 100;
    const qualityWeight = 1 - priceWeight;
    const qualityFactors = (config?.qualityFactors as string[]) ?? ["deliveryTime", "technicalCompliance", "warranty"];

    const scoredBids: ScoredBid[] = [];

    // Calculate lowest price for normalization
    const prices = bids.map((bid) => getTotalPrice({ bid, groups: productGroups }));
    const lowestPrice = prices.length > 0 ? Math.min(...prices) : 0;

    bids.forEach((bid) => {
      // Calculate price score (normalized so lowest price = 100)
      const totalPrice = getTotalPrice({ bid, groups: productGroups });
      const priceScore = lowestPrice > 0 ? (lowestPrice / totalPrice) * 100 : 100;

      // Calculate quality score based on selected factors and user assessments
      const qualityScore = this.calculateQualityScore(bid, qualityFactors, config);

      // Calculate weighted total score
      const totalScore = priceScore * priceWeight + qualityScore * qualityWeight;

      // Check if any bids from this supplier are selected
      const isSelected = selectedBids ? 
        this.getSelectedBidIdsForSupplier(bid, selectedBids as unknown as ExtendedWinningBids[]).length > 0 : 
        false;

      // Factor details for reporting
      const factorScores = this.calculateFactorScores(bid, qualityFactors, config);

      scoredBids.push({
        bidId: bid.id,
        supplierId: bid.owningOrganization.id,
        supplierName: bid.owningOrganization.name,
        totalScore,
        priceScore,
        qualityScore,
        details: {
          totalPrice,
          priceWeight: priceWeight * 100,
          qualityWeight: qualityWeight * 100,
          factorScores,
        },
        isWinner: isSelected,
      });
    });

    // Sort by total score (highest first)
    scoredBids.sort((a, b) => (b.totalScore || 0) - (a.totalScore || 0));

    // Best bid is the one with highest score
    const bestBid = scoredBids.length > 0 ? scoredBids[0] : undefined;

    return {
      scoredBids,
      bestBidId: bestBid?.bidId,
      bestScore: bestBid?.totalScore,
      selectedBidIds: selectedBids ? 
        (selectedBids as unknown as ExtendedWinningBids[]).flatMap((sb) => sb.winningBids || []) : 
        [],
    };
  }

  // Helper methods with dummy implementations for now

  private calculateQualityScore(bid: ReceivedBseBidDto, factors: string[], config?: Record<string, unknown>): number {
    if (!factors.length) return 0;

    // Calculate the average of all factor scores
    const factorScores = this.calculateFactorScores(bid, factors, config);
    const totalFactorScore = Object.values(factorScores).reduce((sum, score) => sum + score, 0);

    return totalFactorScore / factors.length;
  }

  private calculateFactorScores(bid: ReceivedBseBidDto, factors: string[], config?: Record<string, unknown>): Record<string, number> {
    const result: Record<string, number> = {};
    const qualityAssessments = config?.qualityAssessments as Record<string, Record<string, number>> | undefined;

    factors.forEach((factor) => {
      // If we have user-provided assessments, use those
      if (qualityAssessments && qualityAssessments[bid.id] && qualityAssessments[bid.id][factor] !== undefined) {
        result[factor] = qualityAssessments[bid.id][factor];
      } else {
        // Fall back to a default score if no assessments are available
        const hash = this.simpleHash(bid.id + factor);
        result[factor] = 60 + (hash % 41); // Score between 60-100
      }
    });

    return result;
  }

  // Utility functions

  private simpleHash(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
  }

  // The getTotalPrice utility has been moved to BidEvaluationUtils
  
  private getSelectedBidIdsForSupplier(
    bid: ReceivedBseBidDto,
    selectedBids: ExtendedWinningBids[] | undefined
  ): string[] {
    if (!selectedBids || selectedBids.length === 0) {
      return [];
    }
    
    // Simply return all selected bid IDs and check if this bid's ID is included
    return selectedBids.flatMap((sb) => sb.winningBids || []);
  }
}
