import { Icon } from "@chakra-ui/react";
import React from "react";
import { FaCalculator } from "react-icons/fa";
import { ReceivedBseBidDto } from "../../../../../../autogen/bff-api";
import { getTotalPrice } from "../BidEvaluationUtils";
import {
  EvaluationModel,
  EvaluationModelConfigField,
  EvaluationModelContext,
  EvaluationModelType,
  EvaluationResult,
  ExtendedWinningBids,
  ScoredBid,
} from "./EvaluationModelTypes";

/**
 * The QualityAdjustedPriceModel implements a model where quality is priced
 * and subtracted from the total price to get an adjusted price for comparison.
 */
export class QualityAdjustedPriceModel implements EvaluationModel {
  public type = EvaluationModelType.QUALITY_ADJUSTED;
  public name = "Quality Adjusted Price";
  public description =
    "Price adjustment model where quality benefits are converted to monetary value and subtracted from bid price";

  public configFields: EvaluationModelConfigField[] = [
    {
      name: "qualityFactors",
      type: "select",
      label: "Quality Factors",
      description: "Select quality factors to include in price adjustment",
      defaultValue: ["deliveryTime", "warranty", "support"],
      options: [
        { label: "Delivery Time", value: "deliveryTime" },
        { label: "Warranty Terms", value: "warranty" },
        { label: "Support Services", value: "support" },
        { label: "Technical Quality", value: "technical" },
        { label: "Sustainability", value: "sustainability" },
      ],
    },
    {
      name: "factorValues",
      type: "number",
      label: "Max Adjustment Value (%)",
      description: "Maximum percentage of bid price that can be adjusted for quality factors",
      defaultValue: 15,
    },
  ];

  public getModelIcon(): JSX.Element {
    return React.createElement(Icon, { as: FaCalculator });
  }

  public evaluateBids(context: EvaluationModelContext, config?: Record<string, unknown>): EvaluationResult {
    const { bids, productGroups, selectedBids } = context;
    const qualityFactors = (config?.qualityFactors as string[]) ?? ["deliveryTime", "warranty", "support"];
    const maxAdjustmentPercent = (config?.factorValues as number) ?? 15;
    // Get user-provided price adjustments if available
    const priceAdjustments = config?.priceAdjustments as Record<string, Record<string, number>> | undefined;

    const scoredBids: ScoredBid[] = [];

    // Calculate quality scores and adjusted prices
    bids.forEach((bid) => {
      // Use the getTotalPrice utility from BidEvaluationUtils
      const originalPrice = getTotalPrice({ bid, groups: productGroups });

      let adjustmentAmount = 0;
      let factorAdjustments: Record<string, number> = {};

      // If user has provided price adjustments, use those directly
      if (priceAdjustments && priceAdjustments[bid.id]) {
        // Get a fresh copy of adjustments to avoid stale data
        const bidAdjustments = {...priceAdjustments[bid.id]};
        
        // Sum all factor adjustments for this bid
        adjustmentAmount = Object.values(bidAdjustments).reduce((sum, value) => sum + (Number(value) || 0), 0);
        factorAdjustments = bidAdjustments;

        // Ensure adjustment doesn't exceed maximum allowed percentage
        const maxAllowedAdjustment = originalPrice * (maxAdjustmentPercent / 100);
        if (adjustmentAmount > maxAllowedAdjustment) {
          const ratio = maxAllowedAdjustment / adjustmentAmount;
          // Scale down all adjustments proportionally
          Object.keys(factorAdjustments).forEach(key => {
            factorAdjustments[key] = factorAdjustments[key] * ratio;
          });
          adjustmentAmount = maxAllowedAdjustment;
        }
      } else {
        // Fall back to automatic calculation using quality scores
        // Calculate quality score (0-100) using quality assessments
        const qualityScore = this.calculateQualityScore(bid, qualityFactors, config);
        
        // Calculate price adjustment amount
        // Higher quality = more adjustment (discount)
        const maxAdjustment = originalPrice * (maxAdjustmentPercent / 100);
        adjustmentAmount = maxAdjustment * (qualityScore / 100);
        
        // Calculate factor adjustments for reporting
        factorAdjustments = this.calculateFactorAdjustments(bid, qualityFactors, maxAdjustment, config);
      }

      // Calculate the adjusted price by subtracting the adjustment amount from original price
      const adjustedPrice = Math.max(0, originalPrice - adjustmentAmount);
      
      // For this model, we don't use traditional scoring - the adjusted price IS the score
      // The lowest adjusted price wins, and we track the adjustment amount as the quality score
      
      // 1. Quality Score = percentage of maximum possible adjustment that was applied
      // Higher values mean better quality assessment
      let qualityScore = 0;
      if (originalPrice > 0) {
        const maxPossibleAdjustment = originalPrice * (maxAdjustmentPercent / 100);
        qualityScore = maxPossibleAdjustment > 0 ? (adjustmentAmount / maxPossibleAdjustment) * 100 : 0;
      }
      
      // 2. Price Score = percentage of price reduction due to quality adjustments
      // Higher values mean more price reduction
      const priceScore = originalPrice > 0 ? (adjustmentAmount / originalPrice) * 100 : 0;
      
      // 3. Total Score = adjusted price (inverse - lower is better)
      // For display purposes, we invert this so higher is better
      const totalScore = originalPrice > 0 ? 100 - ((adjustedPrice / originalPrice) * 100) : 0;

      // Check if any bids from this supplier are selected
      const isSelected = selectedBids ? 
        this.getSelectedBidIdsForSupplier(bid, selectedBids as unknown as ExtendedWinningBids[]).length > 0 : 
        false;

      scoredBids.push({
        bidId: bid.id,
        supplierId: bid.owningOrganization.id,
        supplierName: bid.owningOrganization.name,
        totalScore: totalScore,
        priceScore,
        qualityScore,
        details: {
          originalPrice,
          adjustedPrice,
          adjustmentAmount,  // The actual monetary value of the adjustment
          totalAdjustment: adjustmentAmount,  // Keep for compatibility
          adjustmentPercentage: priceScore,  // % of price adjusted
          maxAdjustmentPercent,
          factorAdjustments,
        },
        isWinner: isSelected,
      });
    });

    // Sort by adjusted price (lowest first)
    scoredBids.sort((a, b) => {
      const aDetails = a.details as { adjustedPrice: number } | undefined;
      const bDetails = b.details as { adjustedPrice: number } | undefined;
      return (aDetails?.adjustedPrice || 0) - (bDetails?.adjustedPrice || 0);
    });

    // Best bid is the one with lowest adjusted price
    const bestBid = scoredBids.length > 0 ? scoredBids[0] : undefined;

    return {
      scoredBids,
      bestBidId: bestBid?.bidId,
      bestScore: bestBid?.totalScore,
      selectedBidIds: selectedBids ? 
        (selectedBids as unknown as ExtendedWinningBids[]).flatMap((sb) => sb.winningBids || []) : 
        [],
    };
  }

  // Helper methods with dummy implementations for now

  private calculateQualityScore(bid: ReceivedBseBidDto, factors: string[], config?: Record<string, unknown>): number {
    if (!factors.length) return 0;

    // Calculate the average of all factor scores
    let totalFactorScore = 0;
    const qualityAssessments = config?.qualityAssessments as Record<string, Record<string, number>> | undefined;

    factors.forEach((factor) => {
      let factorScore;
      
      // If we have user-provided assessments, use those
      if (qualityAssessments && qualityAssessments[bid.id] && qualityAssessments[bid.id][factor] !== undefined) {
        factorScore = qualityAssessments[bid.id][factor];
      } else {
        // Fall back to a default score if no assessments are available
        const hash = this.simpleHash(bid.id + factor);
        factorScore = 60 + (hash % 41); // Score between 60-100
      }
      
      totalFactorScore += factorScore;
    });

    return totalFactorScore / factors.length;
  }

  private calculateFactorAdjustments(
    bid: ReceivedBseBidDto,
    factors: string[],
    maxAdjustment: number,
    config?: Record<string, unknown>
  ): Record<string, number> {
    const result: Record<string, number> = {};

    const qualityAssessments = config?.qualityAssessments as Record<string, Record<string, number>> | undefined;

    factors.forEach((factor) => {
      let factorScore;
      
      // If we have user-provided assessments, use those
      if (qualityAssessments && qualityAssessments[bid.id] && qualityAssessments[bid.id][factor] !== undefined) {
        factorScore = qualityAssessments[bid.id][factor];
      } else {
        // Fall back to a default score if no assessments are available
        const hash = this.simpleHash(bid.id + factor);
        factorScore = 60 + (hash % 41); // Score between 60-100
      }
      
      // Calculate this factor's contribution to the total adjustment
      const factorPercent = factorScore / 100;
      const factorAdjustment = (maxAdjustment / factors.length) * factorPercent;
      
      result[factor] = factorAdjustment;
    });

    return result;
  }

  // Utility functions

  private simpleHash(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
  }
  // Get list of bid IDs for bids from the same supplier that are selected
  private getSelectedBidIdsForSupplier(bid: ReceivedBseBidDto, selectedBids: ExtendedWinningBids[]): string[] {
    if (!selectedBids || selectedBids.length === 0) {
      return [];
    }
    
    // Simply return all selected bid IDs and check if this bid's ID is included
    return selectedBids.flatMap((sb) => sb.winningBids || []);
  }
}
