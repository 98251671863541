import { Flex, Grid, GridItem, Icon, Image, Spacer, Text, useColorMode } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaBuilding, FaHourglass } from "react-icons/fa";
import { assets } from "../../../assets/assets";
import { useGetSeEmailInviteQuery } from "../../../autogen/bff-api";
import { displayDateWithTime } from "../../../common/formatting/displayDateWithTime";
import { useSkeletons } from "../../../common/useSkeletons";
import { AlreadyAccepted } from "../AlreadyAccepted";
import { LoginDrawer } from "./LoginDrawer";
import { SignupDrawer } from "./SignupDrawer/SignupDrawer";
import { SignupOrLogin } from "./SignupOrLogin";

interface Props {
  inviteId: string;
  secret: string;
}

export const SourcingEventEmailInvite = ({ inviteId, secret }: Props) => {
  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const skeletons = useSkeletons();
  const { t } = useTranslation();
  const theme = useColorMode();
  const { data, error } = useGetSeEmailInviteQuery({
    secret: secret,
    inviteId: inviteId,
  });

  if (error) return <AlreadyAccepted />;

  if (!data) return <div>{t("Loading")}...</div>;

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      {showSignup && (
        <SignupDrawer
          asOrg={true}
          isOpen={showSignup}
          onClose={() => setShowSignup(false)}
          invitationContext={{
            emailInviteId: inviteId,
            emailInviteType: "SourcingEvent",
            secret: secret,
          }}
        />
      )}
      {showLogin && (
        <LoginDrawer
          isOpen={showLogin}
          onClose={() => setShowLogin(false)}
          invitationContext={{
            emailInviteId: inviteId,
            emailInviteType: "SourcingEvent",
            secret: secret,
          }}
        />
      )}
      <Grid
        gridTemplateColumns={"1fr minmax(0, 500px) 1fr"}
        height="100%"
        gridTemplateRows={"1fr minmax(480px, auto) 2fr"}
        templateAreas={`
                "left top right"
                "left login right"
                "left bottom right"
                `}
      >
        <GridItem area={"left"} bg="#f8f8f8"></GridItem>
        <GridItem area={"top"} bg="#f8f8f8"></GridItem>
        <GridItem area={"bottom"} bg="#f8f8f8"></GridItem>
        <GridItem area={"right"} bg="#f8f8f8"></GridItem>
        <GridItem area={"login"} borderRadius={10} boxShadow="0 9px 46px 8px rgb(0,0,0,0.2)" padding={10}>
          {!data && skeletons.stackedLines(20)}
          {data && (
            <Flex w="100%" h={"100%"} flexDirection="column">
              <Flex paddingTop={"20px"} paddingBottom="35px">
                <Image
                  src={theme.colorMode === "dark" ? assets.logo_dark : assets.logo}
                  alt="Logo"
                  h="30px"
                  mt="10px"
                />
              </Flex>
              <Text fontSize="2xl" mt="5px">
                <b>{t("Invitation to bid")}</b>
              </Text>
              <Text fontSize="md" mt="20px">
                {t("You have been invited to bid on")}: <b>{data.eventTitle}</b>.
              </Text>
              <Flex alignItems="center" mt="20px">
                <Flex>
                  <Icon as={FaBuilding} color="teal" w="12px" h="12px" />
                </Flex>
                <Flex ml="5px">
                  <Text fontSize={"md"} color="teal">
                    <b>{t("Buyer")}: </b> {data.owningOrganizationName}
                  </Text>
                </Flex>
                <Spacer />
              </Flex>
              <Flex alignItems="center" mt="20px">
                <Flex>
                  <Icon as={FaHourglass} color="teal" w="12px" h="12px" />
                </Flex>
                <Flex ml="5px">
                  <Text fontSize={"md"} color="teal">
                    <b>{t("Bid delivery deadline")}: </b>
                    {displayDateWithTime(data.eventDeadline, t("at"))}
                  </Text>
                </Flex>
                <Spacer />
              </Flex>
              <Text fontSize="md" mt="20px" color="black">
                <b>{t("Sign up")}</b> {t("or")} <b>{t("log in singular")}</b> {t("to view the complete bid details")}.
              </Text>
              <SignupOrLogin onSignup={() => setShowSignup(true)} onLogin={() => setShowLogin(true)} />
            </Flex>
          )}
        </GridItem>
      </Grid>
    </div>
  );
};
