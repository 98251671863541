import { Box, Flex, Heading, Icon, IconButton, Text, useColorMode } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaChevronLeft, FaChevronRight, FaClock } from "react-icons/fa";
import { BseSupplierViewDto } from "../../../../autogen/bff-api";
import { displayDateWithTime } from "../../../../common/formatting/displayDateWithTime";
import { requireBooleanEnvVar } from "../../../../config";
import { BidInvitationStatus } from "../../../bids/view-all/BidInvitationStatus";
import { BseBidBanner } from "./BseBidBanner";
import { useNegotiation } from "./NegotiationContext";

export const EventMeta = ({ event }: { event: BseSupplierViewDto }) => {
  const { t } = useTranslation();
  const theme = useColorMode();
  const { roundNumber, setRoundNumber, currentRound } = useNegotiation();

  const deadline = currentRound ? currentRound.deadline : event.deadline;

  return (
    <Flex flexDirection="column" width="full" alignItems="center">
      {requireBooleanEnvVar("VITE_ENABLE_NEGOTIATIONS") && (
        <Flex alignItems="center" justifyContent="start" width="full">
          <IconButton
            aria-label="previous round"
            icon={<Icon as={FaChevronLeft} />}
            size="sm"
            variant="ghost"
            cursor="pointer"
            colorScheme="teal"
            isDisabled={roundNumber === 0}
            onClick={() => setRoundNumber(roundNumber - 1)}
          />
          <Text fontWeight="semibold" mx="2">
            {roundNumber === 0 ? t("Original sourcing event") : `${t("Round")} ${roundNumber}`}
          </Text>
          <IconButton
            aria-label="next round"
            icon={<Icon as={FaChevronRight} />}
            size="sm"
            variant="ghost"
            cursor="pointer"
            colorScheme="teal"
            isDisabled={roundNumber >= (event.negotiationContextDto?.rounds.length ?? 0)}
            onClick={() => setRoundNumber(roundNumber + 1)}
          />
        </Flex>
      )}
      <Flex alignItems="center" pt="10">
        <Heading as="h2" size="md">
          {event.title}
        </Heading>
        <Box pl="2.5">
          <BidInvitationStatus status={event.status} />
        </Box>
      </Flex>
      <Flex alignItems="center" pt="2">
        <Icon as={FaClock} color={theme.colorMode === "dark" ? "teal.200" : "teal"} w="14px" h="14px" />
        <Text fontSize={"md"} color={theme.colorMode === "dark" ? "teal.200" : "teal"} pl="1">
          {t("Bid deadline")}: {displayDateWithTime(deadline, t("at"))}
        </Text>
      </Flex>
      <Box pt="8">
        <BseBidBanner event={event} />
      </Box>
    </Flex>
  );
};
