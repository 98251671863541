import { Alert, AlertDescription, AlertIcon, Box, Button, Flex, FormControl, FormHelperText, FormLabel, Icon } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFileAlt } from "react-icons/fa";
import {
  TextDocumentDto,
  useCreateNegotiationTextDocumentMutation,
  useDeleteTextDocumentMutation,
  useGetNegotiationRoundQuery,
  useListTextDocumentsForNegotiationRoundQuery,
  useUpdateTextDocumentMutation,
} from "../../../../autogen/bff-api";
import { TextDocumentModal } from "../../../../common/documents/TextDocumentModal";
import { useApiError } from "../../../../common/errors/useApiError";
import { TextDocumentTable } from "../../../../common/input/TipTap/TextDocumentTable";
import { UppyUploader } from "../../../../common/documents/UppyUploader/UppyUploader";

interface Props {
  negotiationId: string;
  nextStep: () => void;
  previousStep: () => void;
}

export const Docs = ({ negotiationId, nextStep, previousStep }: Props) => {
  const { t } = useTranslation();
  const displayer = useApiError();

  const { data: negotiationRound, refetch } = useGetNegotiationRoundQuery(
    negotiationId ? { negotiationId } : skipToken
  );

  const [createTextDocument, { isLoading: isCreatingTextDocument }] = useCreateNegotiationTextDocumentMutation();
  const [updateTextDocument, { isLoading: isUpdatingTextDocument }] = useUpdateTextDocumentMutation();
  const [deleteTextDocument] = useDeleteTextDocumentMutation();

  const { data: textDocumentsData, isLoading: isLoadingTextDocuments } = useListTextDocumentsForNegotiationRoundQuery(
    negotiationId ? { negotiationRoundId: negotiationId } : skipToken
  );

  const [textDocumentToEdit, setTextDocumentToEdit] = useState<TextDocumentDto>();

  const reloadNegotiation = useCallback(() => {
    refetch();
  }, [refetch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateTextDocument = useCallback(
    debounce(
      ({
        textDocumentId,
        name,
        text,
        tags,
      }: {
        textDocumentId: string;
        name?: string;
        text?: string;
        tags?: string[];
      }) => {
        updateTextDocument({
          updateTextDocumentRequest: {
            id: textDocumentId,
            name,
            text,
            tags,
          },
        });
      },
      300
    ),
    []
  );

  return (
    <>
      {negotiationRound?.stateName === "Published" && (
        <Alert status="info" borderRadius={"10px"} mb="8">
          <AlertIcon />
          <Box>
            <AlertDescription>{t("Documents you upload will be visible to invited suppliers")}</AlertDescription>
          </Box>
        </Alert>
      )}
      <UppyUploader
        uploadEntityType={"NegotiationRound"}
        entityId={negotiationId}
        uploads={[]}
        documents={negotiationRound?.documents || []}
        reloadEntity={reloadNegotiation}
        target={"negotiationFileTarget"}
        helperText={t("Upload documents for this negotiation round")}
      />
      
      {textDocumentToEdit && (
        <TextDocumentModal
          textDocument={textDocumentToEdit}
          onClose={() => setTextDocumentToEdit(undefined)}
          onUpdate={({ title, content, tags }: { title?: string; content?: string; tags?: string[] }) => {
            if (!textDocumentToEdit) throw Error("No text document id - could not edit");
            debouncedUpdateTextDocument({
              textDocumentId: textDocumentToEdit.id,
              name: title,
              text: content,
              tags,
            });
          }}
          isUpdating={isUpdatingTextDocument}
        />
      )}
      
      <FormControl pt={8}>
        <FormLabel>{t("Create document")}</FormLabel>
        <Flex flexDirection="column" alignItems="start">
          <Button
            colorScheme="teal"
            rightIcon={<Icon as={FaFileAlt} />}
            isLoading={isCreatingTextDocument}
            onClick={async () => {
              if (!negotiationId) return;
              const res = await createTextDocument({
                createNegotiationTextDocumentRequest: {
                  name: "",
                  text: "",
                  negotiationRoundId: negotiationId,
                  tags: [],
                },
              });
              if ("error" in res) displayer.show(res.error);
              else setTextDocumentToEdit(res.data);
            }}
          >
            {t("Create")}
          </Button>
          <FormHelperText>{t("Create a new document from scratch or based on templates")}.</FormHelperText>
        </Flex>
      </FormControl>
      
      <FormControl pt={8}>
        <FormLabel>{t("Text documents")}</FormLabel>
        <TextDocumentTable
          isLoading={isLoadingTextDocuments}
          documents={textDocumentsData?.documents || []}
          onClick={setTextDocumentToEdit}
          onDelete={async (id: string) => {
            await deleteTextDocument({ id });
            refetch();
          }}
        />
      </FormControl>
      
      <Flex py="10">
        <Button variant={"outline"} mr="5px" w="100%" colorScheme={"teal"} onClick={previousStep}>
          {t("Previous")}
        </Button>
        <Button variant={"solid"} w="100%" colorScheme={"teal"} onClick={nextStep}>
          {t("Next")}
        </Button>
      </Flex>
    </>
  );
};
