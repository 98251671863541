import { Icon } from "@chakra-ui/react";
import React from "react";

import { FaRobot } from "react-icons/fa";
import { ReceivedBseBidDto } from "../../../../../../autogen/bff-api";
import { ProductGroup } from "../../../../../../common/types";
import { getTotalPrice } from "../BidEvaluationUtils";
import {
  EvaluationModel,
  EvaluationModelConfigField,
  EvaluationModelContext,
  EvaluationModelType,
  EvaluationResult,
  ExtendedWinningBids,
  ScoredBid,
} from "./EvaluationModelTypes";

/**
 * The QualitativeAIModel implements an AI-based evaluation model.
 * It analyzes bid responses using AI to score on qualitative aspects.
 */
export class QualitativeAIModel implements EvaluationModel {
  public type = EvaluationModelType.QUALITATIVE_AI;
  public name = "AI Qualitative Evaluation";
  public description = "Use AI to analyze and score qualitative aspects of bids";

  public configFields: EvaluationModelConfigField[] = [
    {
      name: "instructions",
      type: "text",
      label: "Evaluation Instructions",
      description:
        "Provide instructions for how the AI should evaluate the bids (focus on quality factors like technical compliance, delivery, support)",
      defaultValue:
        "Please evaluate all bids based on their quality, focusing on technical compliance, delivery timelines, and support terms.",
    },
    {
      name: "submitInstructions",
      type: "custom" as const,
      label: "Submit to AI",
      description:
        "Send these instructions to the AI for evaluation (this is a costly operation and will only happen when you click Submit)",
      defaultValue: false,
      render: (value: unknown, onChange: (value: unknown) => void, config: Record<string, unknown>) => {
        const handleSubmit = () => {
          // When the user clicks Submit, copy the current instructions to submittedInstructions
          // and set instructionsSubmitted to true
          onChange({
            submittedInstructions: config.instructions,
            instructionsSubmitted: true,
          });
        };

        const isSubmitted = config.instructionsSubmitted as boolean;
        const buttonText = isSubmitted ? "Instructions Submitted" : "Submit Instructions to AI";

        return (
          <div style={{ marginTop: "10px" }}>
            <button
              onClick={handleSubmit}
              style={{
                backgroundColor: isSubmitted ? "#68D391" : "#4299E1",
                color: "white",
                padding: "8px 16px",
                borderRadius: "4px",
                cursor: "pointer",
                border: "none",
                fontWeight: "600",
              }}
              disabled={isSubmitted}
            >
              {buttonText}
            </button>
            {isSubmitted && (
              <p style={{ fontSize: "12px", color: "#718096", marginTop: "4px" }}>
                AI is using your submitted instructions for evaluation
              </p>
            )}
          </div>
        );
      },
    },
    {
      name: "includePrice",
      type: "toggle",
      label: "Consider Price",
      description: "Include price in the final evaluation",
      defaultValue: true,
    },
  ];

  public getModelIcon(): JSX.Element {
    return React.createElement(Icon, { as: FaRobot });
  }

  // Don't use custom config component, let the standard fields be rendered
  public getConfigComponent = undefined;

  public evaluateBids(context: EvaluationModelContext, config?: Record<string, unknown>): EvaluationResult {
    const { bids, productGroups, selectedBids } = context;

    // First look for submitted instructions, fall back to draft instructions if needed
    const instructions =
      (config?.submittedInstructions as string) ||
      (config?.instructions as string) ||
      "Please evaluate all bids based on their quality.";
    const includePrice = config?.includePrice ?? true;
    const instructionsSubmitted = (config?.instructionsSubmitted as boolean) || false;

    // Only proceed with AI evaluation if instructions have been submitted
    // In a real implementation, we might show an error message if not submitted
    if (!instructionsSubmitted) {
      console.log("Instructions have not been submitted to the AI yet");
    }

    // In a real implementation, we would pass these instructions to the AI service
    console.log(`AI Evaluation Instructions: ${instructions}`);
    console.log(`Instructions submitted: ${instructionsSubmitted}`);

    const scoredBids: ScoredBid[] = [];

    bids.forEach((bid) => {
      // Mock AI evaluation scores (in a real system, these would come from an AI service)
      const qualityScore = this.generateDummyQualityScore(bid, productGroups);

      // Get price score if needed
      let priceScore: number | undefined;
      let totalScore = qualityScore;

      if (includePrice) {
        priceScore = this.calculatePriceScore(bid, bids, productGroups);
        // Combine quality and price (50/50 in this example)
        totalScore = (qualityScore + priceScore) / 2;
      }

      // Calculate totalPrice for display in results table
      const totalPrice = getTotalPrice({ bid, groups: productGroups });
      
      // Check if any bids from this supplier are selected
      // Cast selectedBids to ExtendedWinningBids[] to use our customized version with winningBids property
      const isSelected =
        this.getSelectedBidIdsForSupplier(bid, selectedBids as unknown as ExtendedWinningBids[]).length > 0;

      scoredBids.push({
        bidId: bid.id,
        supplierId: bid.owningOrganization.id,
        supplierName: bid.owningOrganization.name,
        totalScore,
        priceScore,
        qualityScore,
        details: {
          totalPrice,   // Include total price in details for display in results
          qualitativeAssessment: this.generateDummyQualitativeAssessment(bid),
          meetsThreshold: true, // Always true since we're using instructions
          strengths: this.generateDummyStrengths(bid),
          weaknesses: this.generateDummyWeaknesses(bid),
          // Would use instructions to customize this assessment in a real implementation
          instructionsUsed: true,
        },
        isWinner: isSelected,
      });
    });

    // Sort by total score (highest first)
    scoredBids.sort((a, b) => b.totalScore - a.totalScore);

    // Best bid is the one with highest score
    const bestBid = scoredBids.length > 0 ? scoredBids[0] : undefined;

    return {
      scoredBids,
      bestBidId: bestBid?.bidId,
      bestScore: bestBid?.totalScore,
      // Cast selectedBids to our extended type before accessing the winningBids property
      selectedBidIds: (selectedBids as unknown as ExtendedWinningBids[]).flatMap((sb) => sb.winningBids || []),
    };
  }

  // Helper methods with dummy implementations for now

  private generateDummyQualityScore(bid: ReceivedBseBidDto, groups: ProductGroup[]): number {
    // This would be replaced with actual AI scoring logic
    // For demo purposes, generate a random score between 50-95
    const baseScore = 50 + Math.floor(Math.random() * 45);

    // Add a slight preference for suppliers with more quotes
    const quotesFactor = (this.getQuotesDeliveredInBid(bid) / this.getQuotesRequested(groups)) * 5;

    return Math.min(Math.round(baseScore + quotesFactor), 100);
  }

  private calculatePriceScore(bid: ReceivedBseBidDto, allBids: ReceivedBseBidDto[], groups: ProductGroup[]): number {
    // Simple price score calculation based on how close the bid is to the lowest price
    const bidPrice = getTotalPrice({ bid, groups });
    if (bidPrice <= 0) return 100; // Free is perfect score

    const lowestPrice = Math.min(...allBids.map((b) => getTotalPrice({ bid: b, groups })));
    if (lowestPrice <= 0) return 50; // Avoid division by zero

    return Math.round((lowestPrice / bidPrice) * 100);
  }

  private generateDummyQualitativeAssessment(bid: ReceivedBseBidDto): string {
    const assessments = [
      "Supplier demonstrates strong understanding of requirements with detailed responses.",
      "Proposal meets basic requirements but lacks detail in some areas.",
      "High quality response with innovative approaches to delivery.",
      "Standard response with adequate information but no standout features.",
      "Strong technical approach but timeline concerns noted.",
    ];

    // Use supplier ID to create deterministic but seemingly random selection
    const assessmentIndex = parseInt(bid.id.substring(0, 8), 16) % assessments.length;
    return assessments[assessmentIndex];
  }

  private generateDummyStrengths(bid: ReceivedBseBidDto): string[] {
    const strengths = [
      "Clear delivery timeline",
      "Comprehensive support package",
      "Strong technical expertise",
      "Previous experience with similar projects",
      "Innovative approach to requirements",
      "Cost-effective solution",
      "Scalable implementation plan",
    ];

    // Generate 2-3 strengths based on bid ID
    const numStrengths = 2 + (parseInt(bid.id.substring(0, 4), 16) % 2);
    const result: string[] = [];

    for (let i = 0; i < numStrengths; i++) {
      const index = parseInt(bid.id.substring(i * 4, (i + 1) * 4), 16) % strengths.length;
      if (!result.includes(strengths[index])) {
        result.push(strengths[index]);
      }
    }

    return result;
  }

  private generateDummyWeaknesses(bid: ReceivedBseBidDto): string[] {
    const weaknesses = [
      "Delivery timeline might be optimistic",
      "Limited detail on support services",
      "Unclear implementation methodology",
      "Higher pricing than alternatives",
      "Vague response on technical specifications",
      "Limited scalability details",
    ];

    // Generate 1-2 weaknesses based on bid ID
    const numWeaknesses = 1 + (parseInt(bid.id.substring(8, 12), 16) % 2);
    const result: string[] = [];

    for (let i = 0; i < numWeaknesses; i++) {
      const index = parseInt(bid.id.substring(i * 4 + 12, (i + 1) * 4 + 12), 16) % weaknesses.length;
      if (!result.includes(weaknesses[index])) {
        result.push(weaknesses[index]);
      }
    }

    return result;
  }

  private getQuotesDeliveredInBid(bid: ReceivedBseBidDto): number {
    // Count all product bids across all product groups
    if (!bid.products || !Array.isArray(bid.products)) {
      return 0;
    }
    
    return bid.products.reduce((total, group) => {
      // Skip if group doesn't exist or productBids isn't an array
      if (!group || !group.productBids || !Array.isArray(group.productBids)) {
        return total;
      }
      
      // Count only valid product bids (ones with an actual product ID)
      return total + group.productBids.filter(pb => pb && pb.productId).length;
    }, 0);
  }

  private getQuotesRequested(groups: ProductGroup[]): number {
    if (!groups || !Array.isArray(groups)) {
      return 0;
    }
    
    return groups.reduce((sum, group) => {
      if (!group || !group.products || !Array.isArray(group.products)) {
        return sum;
      }
      
      // Count only valid products (with an ID)
      return sum + group.products.filter(p => p && p.id).length;
    }, 0);
  }

  private getSelectedBidIdsForSupplier(
    bid: ReceivedBseBidDto,
    selectedBids: ExtendedWinningBids[] | undefined
  ): string[] {
    if (!selectedBids || selectedBids.length === 0) {
      return [];
    }
    
    // Simply return all selected bid IDs and check if this bid's ID is included
    return selectedBids.flatMap((sb) => sb.winningBids || []);
  }
}