import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  CheckboxGroup,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";
import { ChangeEvent, useEffect, useState } from "react";
import { EvaluationModel } from "../evaluation-models/EvaluationModelTypes";

interface ModelConfigurationPanelProps {
  model?: EvaluationModel;
  onConfigChange: (config: Record<string, unknown>) => void;
  initialConfig?: Record<string, unknown>;
}

export const ModelConfigurationPanel = ({
  model,
  onConfigChange,
  initialConfig,
}: ModelConfigurationPanelProps) => {
  const [config, setConfig] = useState<Record<string, unknown>>(initialConfig || {});

  useEffect(() => {
    // Reset config when model changes
    if (model) {
      setConfig(initialConfig || {});
    }
  }, [model, initialConfig]);

  // Internal function to handle individual field changes
  const handleFieldChange = (key: string, value: unknown) => {
    const newConfig = { ...config, [key]: value };
    setConfig(newConfig);
    onConfigChange(newConfig);
  };
  
  // External function to handle complete config changes (matches the expected props type)
  const handleConfigChange = (newConfig: Record<string, unknown>) => {
    setConfig(newConfig);
    onConfigChange(newConfig);
  };
  
  // Initialize default values if config is empty
  useEffect(() => {
    if (model && Object.keys(config).length === 0) {
      const defaults = model.configFields.reduce((acc, field) => {
        if (field.defaultValue !== undefined) {
          acc[field.name] = field.defaultValue;
        }
        return acc;
      }, {} as Record<string, unknown>);
      
      if (Object.keys(defaults).length > 0) {
        setConfig(defaults);
        onConfigChange(defaults);
      }
    }
  }, [model, config, onConfigChange]);

  if (!model) {
    return (
      <Card variant="outline" mt={4}>
        <CardHeader>
          <Heading size="md">{t("Configuration")}</Heading>
        </CardHeader>
        <CardBody>
          <Text color="gray.500">{t("No model selected")}</Text>
        </CardBody>
      </Card>
    );
  }

  // If the model has a custom config component, use that
  if (model.getConfigComponent) {
    return (
      <Card variant="outline" mt={4}>
        <CardHeader>
          <Heading size="md">{t("Model Configuration")}</Heading>
        </CardHeader>
        <CardBody>
          {model.getConfigComponent({
            config: config,
            onChange: handleConfigChange,
          })}
        </CardBody>
      </Card>
    );
  }

  // If there are no config fields, show a message
  if (!model.configFields || model.configFields.length === 0) {
    return (
      <Card variant="outline" mt={4}>
        <CardHeader>
          <Heading size="md">{t("Configuration")}</Heading>
        </CardHeader>
        <CardBody>
          <Text color="gray.500">{t("No configuration options available for this model.")}</Text>
        </CardBody>
      </Card>
    );
  }

  // Otherwise, render the standard configuration UI
  return (
    <Card variant="outline" mt={4}>
      <CardHeader>
        <Heading size="md">{t("Model Configuration")}</Heading>
      </CardHeader>
      <CardBody>
        <VStack spacing={4} align="stretch">
          {model.configFields.map((field) => {
            const fieldValue = config[field.name];

            // If the field has a custom render function, use that
            if (field.render) {
              return (
                <Box key={field.name}>
                  {field.render(fieldValue, (value) => {
                    handleFieldChange(field.name, value);
                  }, config)}
                  <Divider mt={4} />
                </Box>
              );
            }

            // Otherwise, render the standard field based on its type
            return (
              <Box key={field.name}>
                {renderField(field, fieldValue, (value) => {
                  handleFieldChange(field.name, value);
                })}
                <Divider mt={4} />
              </Box>
            );
          })}
        </VStack>
      </CardBody>
    </Card>
  );
};

function renderField(
  field: EvaluationModel["configFields"][0],
  value: unknown,
  onChange: (value: unknown) => void
) {
  const handleTextChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  const handleNumberChange = (valueAsString: string, valueAsNumber: number) => {
    onChange(valueAsNumber);
  };

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  // Check if field is meant to be multi-select based on its default value
  const isMultiSelect = field.type === "select" && Array.isArray(field.defaultValue);

  switch (field.type) {
    case "text":
      return (
        <FormControl>
          <FormLabel>{t(field.label)}</FormLabel>
          <Input
            value={(value as string) || ""}
            onChange={handleTextChange}
          />
          {field.description && <FormHelperText>{t(field.description)}</FormHelperText>}
        </FormControl>
      );
    case "number":
      return (
        <FormControl>
          <FormLabel>{t(field.label)}</FormLabel>
          <NumberInput
            value={value as number}
            onChange={handleNumberChange}
            defaultValue={field.defaultValue as number}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          {field.description && <FormHelperText>{t(field.description)}</FormHelperText>}
        </FormControl>
      );
    case "select":
      // Handle multi-select (e.g., for quality factors)
      if (isMultiSelect) {
        const selectedValues = (value as string[]) || [];
        return (
          <FormControl>
            <FormLabel>{t(field.label)}</FormLabel>
            <CheckboxGroup
              value={selectedValues}
              onChange={(values) => onChange(values)}
              defaultValue={field.defaultValue as string[]}
            >
              <Stack spacing={2} mt={2}>
                {field.options?.map((option) => (
                  <Checkbox
                    key={option.value}
                    value={option.value}
                    borderColor="gray.300"
                    _hover={{ borderColor: "blue.500" }}
                  >
                    {t(option.label)}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
            {field.description && <FormHelperText>{t(field.description)}</FormHelperText>}
          </FormControl>
        );
      }
      // Regular single-select
      return (
        <FormControl>
          <FormLabel>{t(field.label)}</FormLabel>
          <Select
            value={(value as string) || ""}
            onChange={handleTextChange}
          >
            {field.options?.map((option) => (
              <option key={option.value} value={option.value}>
                {t(option.label)}
              </option>
            ))}
          </Select>
          {field.description && <FormHelperText>{t(field.description)}</FormHelperText>}
        </FormControl>
      );
    case "toggle":
      return (
        <FormControl display="flex" alignItems="center">
          <FormLabel mb="0">
            {t(field.label)}
          </FormLabel>
          <Switch
            isChecked={value as boolean}
            onChange={handleSwitchChange}
            colorScheme="teal"
            defaultChecked={field.defaultValue as boolean}
          />
          {field.description && <FormHelperText ml={2}>{t(field.description)}</FormHelperText>}
        </FormControl>
      );
    case "custom":
      // Custom types should have a render function, but this is a fallback
      return (
        <FormControl>
          <FormLabel>{t(field.label)}</FormLabel>
          <Text color="gray.500">{t("Custom field without render function")}</Text>
          {field.description && <FormHelperText>{t(field.description)}</FormHelperText>}
        </FormControl>
      );
    default:
      return (
        <Text color="red.500">
          {t("Unknown field type")} {String(field.type)}
        </Text>
      );
  }
}
