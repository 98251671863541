import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { ReceivedBseBidDto } from "../../../../../../autogen/bff-api";
import { ProductGroup } from "../../../../../../common/types";
import { getTotalPrice } from "../BidEvaluationUtils";

interface QualityPriceAdjustmentPanelProps {
  bids: ReceivedBseBidDto[];
  qualityFactors: string[];
  onAdjustmentChange: (adjustments: Record<string, Record<string, number>>) => void;
  factorOptions: { label: string; value: string }[];
  maxAdjustmentPercent: number;
  productGroups: ProductGroup[];
}

/**
 * Component for assigning monetary values to quality factors in the Quality Adjusted Price model
 * Allows the user to specify monetary adjustments for each quality factor per bid
 */
export const QualityPriceAdjustmentPanel = ({
  bids,
  qualityFactors,
  onAdjustmentChange,
  factorOptions,
  maxAdjustmentPercent,
  productGroups,
}: QualityPriceAdjustmentPanelProps) => {
  const [adjustments, setAdjustments] = useState<Record<string, Record<string, number>>>({});
  const [bidPrices, setBidPrices] = useState<Record<string, number>>();

  // Calculate bid prices
  useEffect(() => {
    if (!bids || bids.length === 0) return;

    const prices: Record<string, number> = {};
    bids.forEach((bid) => {
      // Calculate total price using the utility function
      prices[bid.id] = getTotalPrice({ bid, groups: productGroups });
    });

    setBidPrices(prices);
  }, [bids, productGroups]);

  // Initialize adjustments only once when component mounts or when bids/factors change
  useEffect(() => {
    const initialAdjustments: Record<string, Record<string, number>> = {};

    bids.forEach((bid) => {
      initialAdjustments[bid.id] = {};
      qualityFactors.forEach((factor) => {
        initialAdjustments[bid.id][factor] = 0;
      });
    });

    setAdjustments(initialAdjustments);
    // Only depend on bids and qualityFactors - this should only run when those change
  }, [bids, qualityFactors]);

  // Handle input change for a specific bid and factor
  const handleAdjustmentChange = useCallback((bidId: string, factor: string, value: string) => {
    const numericValue = value.trim() === "" ? 0 : Number(value);

    if (!isNaN(numericValue)) {
      // Update state in a way that creates a new reference
      setAdjustments(prev => {
        const newAdjustments = { ...prev };
        if (!newAdjustments[bidId]) {
          newAdjustments[bidId] = {};
        }
        
        // Only update if value actually changed
        if (newAdjustments[bidId][factor] !== numericValue) {
          newAdjustments[bidId] = { ...newAdjustments[bidId], [factor]: numericValue };
          
          // Only notify parent if value actually changed
          // Use setTimeout to ensure this runs after the state update
          setTimeout(() => {
            onAdjustmentChange({ ...newAdjustments });
          }, 0);
        }
        
        return newAdjustments;
      });
    }
  }, [onAdjustmentChange]);
  
  // Memoize factor labels for better performance
  const getFactorLabel = useCallback((factorValue: string): string => {
    const factor = factorOptions.find((f) => f.value === factorValue);
    return factor ? t(factor.label) : factorValue;
  }, [factorOptions]);

  // Calculate total adjustment for a bid, using useMemo to improve performance and ensure consistent values
  const calculateTotalAdjustment = useCallback((bidId: string): number => {
    if (!adjustments[bidId]) return 0;

    return Object.values(adjustments[bidId]).reduce((sum, value) => sum + (Number(value) || 0), 0);
  }, [adjustments]);

  // Calculate adjusted price for a bid
  const calculateAdjustedPrice = useCallback((bidId: string): number => {
    const totalPrice = bidPrices?.[bidId] || 0;
    const totalAdjustment = calculateTotalAdjustment(bidId);
    return totalPrice - totalAdjustment;
  }, [bidPrices, calculateTotalAdjustment]);

  // Check if adjustment exceeds the maximum allowed percentage
  const isAdjustmentExceedingMax = useCallback((bidId: string): boolean => {
    const totalPrice = bidPrices?.[bidId] || 0;
    if (totalPrice === 0) return false;

    const totalAdjustment = calculateTotalAdjustment(bidId);
    const adjustmentPercent = (totalAdjustment / totalPrice) * 100;

    return adjustmentPercent > maxAdjustmentPercent;
  }, [bidPrices, calculateTotalAdjustment, maxAdjustmentPercent]);

  return (
    <Card variant="outline" mb={4} boxShadow="sm">
      <CardHeader bg="smBackgroundSecondary" py={3}>
        <Heading size="md">{t("Quality Price Adjustments")}</Heading>
      </CardHeader>
      <CardBody>
        <Text mb={4}>
          {t(
            "Assign monetary value adjustments to each quality factor. These values will be subtracted from the bid price to calculate the quality-adjusted price."
          )}
        </Text>
        <Text mb={4} fontWeight="medium">
          {t("Maximum adjustment:")} {maxAdjustmentPercent}% {t("of bid price")}
        </Text>

        <Box overflowX="auto">
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>{t("Supplier")}</Th>
                {qualityFactors.map((factor) => (
                  <Th key={factor}>{getFactorLabel(factor)}</Th>
                ))}
                <Th>{t("Total Adjustment")}</Th>
                <Th>{t("Adjusted Price")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {bids.map((bid) => {
                const isExceedingMax = isAdjustmentExceedingMax(bid.id);
                return (
                  <Tr key={bid.id}>
                    <Td fontWeight="medium">{bid.owningOrganization.name}</Td>
                    {qualityFactors.map((factor) => (
                      <Td key={`${bid.id}-${factor}`} minW="150px">
                        <FormControl>
                          <InputGroup size="sm">
                            <Input
                              type="number"
                              min={0}
                              step="0.01"
                              value={adjustments[bid.id]?.[factor] ?? 0}
                              onChange={(e) => handleAdjustmentChange(bid.id, factor, e.target.value)}
                              onBlur={(e) => {
                                // Ensure value is processed on blur
                                handleAdjustmentChange(bid.id, factor, e.target.value);
                              }}
                              isInvalid={isExceedingMax}
                            />
                          </InputGroup>
                        </FormControl>
                      </Td>
                    ))}
                    <Td>
                      <FormControl>
                        <InputGroup size="sm">
                          <Input
                            type="number"
                            value={calculateTotalAdjustment(bid.id)}
                            isReadOnly
                            fontWeight="bold"
                            isInvalid={isExceedingMax}
                            bg={isExceedingMax ? "red.50" : "gray.50"}
                          />
                        </InputGroup>
                        {isExceedingMax && (
                          <Text color="red.500" fontSize="xs" mt={1}>
                            {t("Exceeds maximum adjustment")}
                          </Text>
                        )}
                      </FormControl>
                    </Td>
                    <Td>
                      <Flex align="center">
                        <FormControl>
                          <InputGroup size="sm">
                            <Input
                              type="number"
                              value={calculateAdjustedPrice(bid.id)}
                              isReadOnly
                              fontWeight="bold"
                              bg="gray.50"
                            />
                          </InputGroup>
                        </FormControl>
                      </Flex>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </CardBody>
    </Card>
  );
};
