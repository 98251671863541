import { Icon, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaAward, FaDollarSign, FaHandshake, FaInfo, FaSearch } from "react-icons/fa";
import { BseStatusDto } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { BasicSourcingEventState } from "../../../../common/redux/reducers/basicSourcingEventReducer";
import { useSub } from "../../../../common/subscription/useSub";
import { BseBuyerTab, useQueryParams } from "../../../../common/useQueryParams";
import { tabs } from "./BseView";
import { Award } from "./Award";
import { Bids } from "./Bids";
import { CreateContracts } from "./CreateContracts";
import { useNegotiation } from "./NegotiationContext";
import { Request } from "./Request";
import { BidEvaluation } from "./bid-evaluation/BidEvaluation";

export const getCurrentStep = (state: BasicSourcingEventState, stateToStepMap: { [state in BseStatusDto]: string }) =>
  stateToStepMap[state.event.status];

export const indexToQueryValue = (index: number): BseBuyerTab => {
  return tabs[index];
};

export const BseTabs = ({
  eventState,
  subscriptions,
  tabIndex,
  setTabIndex,
  params,
}: {
  eventState: BasicSourcingEventState;
  subscriptions: ReturnType<typeof useSub>;
  tabIndex: number;
  setTabIndex: (index: number) => void;
  params: ReturnType<typeof useQueryParams>;
}) => {
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();
  const { latestRound } = useNegotiation();

  return (
    <Tabs
      colorScheme="teal"
      width="full"
      maxWidth="full"
      overflowX="hidden"
      index={tabIndex}
      onChange={(index) => {
        setTabIndex(index);
        params.setSelectedBseTab(indexToQueryValue(index));
      }}
      variant="line"
      mx="0"
      pt="8"
    >
      <TabList justifyContent="center" mx="2">
        <Tab>
          <Icon as={FaSearch} mr="1" />
          {t("Request")}
        </Tab>
        <Tab>
          <Icon as={FaInfo} mr="1" />
          {t("Bids")}
        </Tab>
        {subscriptions.hasProductAccess && (
          <Tab
            isDisabled={
              !authState.selectedOrg.canSeeDeliveredBidsBeforeDeadline &&
              (eventState.event.status === "Draft" || eventState.event.status === "Published")
            }
          >
            <Icon as={FaDollarSign} mr="1" />
            {t("Evaluation")}
          </Tab>
        )}
        <Tab
          isDisabled={
            latestRound
              ? latestRound.stateName === "Draft" || latestRound.stateName === "Published"
              : eventState.event.status === "Draft" || eventState.event.status === "Published"
          }
        >
          <Icon as={FaAward} mr="1" />
          {t("Award")}
        </Tab>
        <Tab isDisabled={eventState.event.status !== "Awarded"}>
          <Icon as={FaHandshake} mr="1" />
          {t("Contract")}
        </Tab>
      </TabList>
      <TabPanels width="full" maxWidth="full" overflowX="hidden">
        <TabPanel width="full" maxWidth="full" overflowX="hidden">
          <Request event={eventState.event} />
        </TabPanel>
        <TabPanel>
          <Bids data={eventState.event} />
        </TabPanel>
        {subscriptions.hasProductAccess && (
          <TabPanel>
            <BidEvaluation sourcingEvent={eventState.event} />
          </TabPanel>
        )}
        <TabPanel>
          <Award sourcingEvent={eventState.event} />
        </TabPanel>
        <TabPanel>
          <CreateContracts sourcingEvent={eventState.event} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
