import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  Select,
  Stack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAward } from "react-icons/fa";
import {
  BseDto,
  Currency,
  EventValueDto,
  useAwardToBidMutation,
  useListReceivedBidsQuery,
} from "../../../../../autogen/bff-api";
import { useApiError } from "../../../../../common/errors/useApiError";
import { TipTap } from "../../../../../common/input/TipTap/TipTap";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { setSourcingEvent } from "../../../../../common/redux/reducers/basicSourcingEventReducer";
import { useProductGroups } from "../../common/useProductGroups";
import {
  getQuotesDeliveredInBid,
  getQuotesRequested,
  getQuotesSelectedInBid,
  getSelectedOrWinningBids,
  getSumOfSelectedPricesInBid,
  getTotalPrice,
} from "../bid-evaluation/BidEvaluationUtils";
import { useNegotiation } from "../NegotiationContext";

export interface AwardBasicSourcingEventRequest {
  bidIds: string[];
  messageToWinners: string;
  messageToLosers: string;
  eventValue?: EventValueDto;
}

export const AwardBasicSourcingEvent = ({ sourcingEvent }: { sourcingEvent: BseDto }) => {
  const dispatch = useAppDispatch();
  const { latestRound } = useNegotiation();
  const { data } = useListReceivedBidsQuery({ eventId: sourcingEvent.id, negotiationRound: latestRound?.id });
  const { productGroups } = useProductGroups(sourcingEvent.id);
  const selectedBids = getSelectedOrWinningBids(sourcingEvent, latestRound);
  const [awardToBids, { isLoading }] = useAwardToBidMutation();
  const toast = useToast();
  const { t } = useTranslation();
  const apiErrorDisplayer = useApiError();

  const [awardRequest, setAwardRequest] = useState<AwardBasicSourcingEventRequest>({
    bidIds: [],
    messageToLosers: "",
    messageToWinners: "",
    eventValue: undefined,
  });

  const awardEvent = async () => {
    const result = await awardToBids({
      eventId: sourcingEvent.id,
      awardToBidRequest: awardRequest,
    });

    if ("data" in result) {
      dispatch(setSourcingEvent(result.data));
      toast({
        title: t("Award success!"),
        description: t("The event was awarded successfully"),
        status: "success",
      });
    } else {
      apiErrorDisplayer.trigger(result.error);
    }
  };

  return (
    <VStack spacing={6} align="stretch" width="full">
      <Card variant="outline">
        <CardHeader pb={0}>
          <Heading size="md">{t("Select Bid Winners")}</Heading>
        </CardHeader>
        <CardBody>
          <CheckboxGroup colorScheme="purple" value={awardRequest.bidIds}>
            <Stack spacing={4} direction="column">
              {data?.bids?.map((bid) => (
                <Box key={bid.id} borderWidth="1px" borderRadius="md" p={0} overflow="hidden">
                  <Flex direction="column">
                    <Flex p={3} align="center">
                      <Checkbox
                        colorScheme="purple"
                        value={bid.id}
                        size="lg"
                        onChange={() => {
                          setAwardRequest((req) => {
                            if (awardRequest.bidIds.indexOf(bid.id) === -1) {
                              return { ...req, bidIds: [...req.bidIds, bid.id] };
                            }
                            return { ...req, bidIds: req.bidIds.filter((id) => id !== bid.id) };
                          });
                        }}
                      >
                        <Text fontWeight="bold" fontSize="lg" ml={2}>
                          {bid.owningOrganization.name}
                        </Text>
                      </Checkbox>
                    </Flex>

                    <Box px={4} pb={4} pt={1}>
                      <StatGroup>
                        <Stat>
                          <StatLabel>{t("Selected Products Price")}</StatLabel>
                          <StatNumber
                            fontSize="lg"
                            color={
                              getSumOfSelectedPricesInBid({ groups: productGroups, bid, selectedBids }) > 0
                                ? "purple.600"
                                : "gray.500"
                            }
                          >
                            {getSumOfSelectedPricesInBid({ groups: productGroups, bid, selectedBids }).toLocaleString()}{" "}
                            NOK
                          </StatNumber>
                          <Text fontSize="xs" color="gray.500">
                            {t("Total quoted")}: {getTotalPrice({ bid, groups: productGroups })?.toLocaleString() || 0}{" "}
                            NOK
                          </Text>
                        </Stat>

                        <Stat>
                          <StatLabel>{t("Selected Products")}</StatLabel>
                          <StatNumber
                            fontSize="lg"
                            color={getQuotesSelectedInBid({ bid, selectedBids }) > 0 ? "purple.600" : "gray.500"}
                          >
                            {getQuotesSelectedInBid({ bid, selectedBids })}
                            <Text as="span" fontSize="sm" color="gray.500" ml={1}>
                              {getQuotesSelectedInBid({ bid, selectedBids }) > 0 && (
                                <>
                                  (
                                  {Math.round(
                                    (getQuotesSelectedInBid({ bid, selectedBids }) /
                                      getQuotesRequested(productGroups)) *
                                      100
                                  )}
                                  %)
                                </>
                              )}
                            </Text>
                          </StatNumber>
                          <Text fontSize="xs" color="gray.500">
                            {t("Delivered quotes")}: {getQuotesDeliveredInBid({ bid })} /{" "}
                            {getQuotesRequested(productGroups)}
                          </Text>
                        </Stat>
                      </StatGroup>
                    </Box>
                  </Flex>
                </Box>
              ))}
            </Stack>
          </CheckboxGroup>
        </CardBody>
      </Card>

      <Card variant="outline">
        <CardHeader pb={0}>
          <Heading size="md">{t("Award Details")}</Heading>
        </CardHeader>
        <CardBody>
          <FormControl>
            <FormLabel fontWeight="medium">{t("Event value")}</FormLabel>
            <Flex>
              <InputGroup>
                <Input
                  type="number"
                  placeholder={t("Enter value") || ""}
                  value={awardRequest.eventValue?.value ?? ""}
                  onChange={(e) => {
                    const value = e.target.value === "" ? undefined : parseFloat(e.target.value);
                    setAwardRequest((req) => ({
                      ...req,
                      eventValue: value ? { currency: req.eventValue?.currency ?? "NOK", value } : undefined,
                    }));
                  }}
                />
                <Select
                  width="120px"
                  ml="2"
                  value={awardRequest.eventValue?.currency ?? "NOK"}
                  onChange={(e) => {
                    const currency = e.target.value as Currency;
                    setAwardRequest((req) => ({
                      ...req,
                      eventValue: req.eventValue ? { currency, value: req.eventValue.value } : undefined,
                    }));
                  }}
                >
                  <option key={"NOK"} value={"NOK"}>
                    NOK
                  </option>
                  <option key={"USD"} value={"USD"}>
                    USD
                  </option>
                </Select>
              </InputGroup>
            </Flex>
          </FormControl>
        </CardBody>
      </Card>

      <Card variant="outline">
        <CardHeader pb={0}>
          <Heading size="md">{t("Award Messages")}</Heading>
        </CardHeader>
        <CardBody>
          <VStack spacing={5} align="stretch">
            <Box>
              <FormControl>
                <FormLabel fontWeight="medium">{t("Message to winners")}</FormLabel>
                <TipTap
                  content=""
                  onUpdate={(value) => setAwardRequest((req) => ({ ...req, messageToWinners: value }))}
                />
                <Text fontSize="sm" color="gray.500" mt={1}>
                  {t("Provide a message to the winners")}.
                </Text>
              </FormControl>
            </Box>

            <Divider />

            <Box>
              <FormControl>
                <FormLabel fontWeight="medium">{t("Message to losers")}</FormLabel>
                <TipTap
                  content=""
                  onUpdate={(value) => setAwardRequest((req) => ({ ...req, messageToLosers: value }))}
                />
                <Text fontSize="sm" color="gray.500" mt={1}>
                  {t("Provide a message to the losers")}.
                </Text>
              </FormControl>
            </Box>
          </VStack>
        </CardBody>
      </Card>

      <Flex justifyContent="flex-end" pt={2}>
        <Button
          leftIcon={<Icon as={FaAward} width="4" height="4" />}
          colorScheme="purple"
          variant="solid"
          size="md"
          isLoading={isLoading}
          onClick={() => awardEvent()}
        >
          {t("Award to selected winner(s)")}
        </Button>
      </Flex>
    </VStack>
  );
};
