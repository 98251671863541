import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { ReceivedBseBidDto } from "../../../../../../autogen/bff-api";

interface QualityAssessmentPanelProps {
  bids: ReceivedBseBidDto[];
  qualityFactors: string[];
  onAssessmentChange: (assessments: Record<string, Record<string, number>>) => void;
  factorOptions: { label: string; value: string }[];
}



/**
 * Component for assessing the quality of bids across multiple dimensions
 * Allows the user to rate each bid for each quality factor on a scale of 0-100
 */
export const QualityAssessmentPanel = ({
  bids,
  qualityFactors,
  onAssessmentChange,
  factorOptions,
}: QualityAssessmentPanelProps) => {
  // Initialize assessments with default value of 70 for each bid/factor combination
  const [assessments, setAssessments] = useState<Record<string, Record<string, number>>>({});

  // Initialize assessments when bids or quality factors change
  useEffect(() => {
    const initialAssessments: Record<string, Record<string, number>> = {};
    
    bids.forEach((bid) => {
      initialAssessments[bid.id] = initialAssessments[bid.id] || {};
      
      qualityFactors.forEach((factor) => {
        // If we already have a score for this bid/factor, preserve it
        if (assessments[bid.id]?.[factor] !== undefined) {
          initialAssessments[bid.id][factor] = assessments[bid.id][factor];
        } else {
          // Otherwise set a default score of 70
          initialAssessments[bid.id][factor] = 70;
        }
      });
    });
    
    setAssessments(initialAssessments);
  }, [bids, qualityFactors, assessments]);

  // Notify parent component when assessments change
  useEffect(() => {
    if (Object.keys(assessments).length > 0) {
      onAssessmentChange(assessments);
    }
  }, [assessments, onAssessmentChange]);

  // Handle slider change for a specific bid and factor
  const handleSliderChange = (bidId: string, factor: string, value: number) => {
    setAssessments((prev) => ({
      ...prev,
      [bidId]: {
        ...prev[bidId],
        [factor]: value,
      },
    }));
  };

  // Get the label for a factor from its value
  const getFactorLabel = (factorValue: string): string => {
    const factor = factorOptions.find((f) => f.value === factorValue);
    return factor ? t(factor.label) : factorValue;
  };

  return (
    <Card variant="outline" mb={4} boxShadow="sm">
      <CardHeader bg="smBackgroundSecondary" py={3}>
        <Heading size="md">{t("Quality Assessment")}</Heading>
      </CardHeader>
      <CardBody>
        <Text mb={4}>
          {t(
            "Rate each bid on the selected quality factors below. Drag the sliders to assign a score from 0 (poor) to 100 (excellent)."
          )}
        </Text>

        <Box overflowX="auto">
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>{t("Supplier")}</Th>
                {qualityFactors.map((factor) => (
                  <Th key={factor}>{getFactorLabel(factor)}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {bids.map((bid) => (
                <Tr key={bid.id}>
                  <Td fontWeight="medium">{bid.owningOrganization.name}</Td>
                  {qualityFactors.map((factor) => (
                    <Td key={`${bid.id}-${factor}`} minW="200px">
                      <Flex align="center">
                        <Slider
                          aria-label={`${bid.owningOrganization.name} ${factor} score`}
                          defaultValue={70}
                          value={assessments[bid.id]?.[factor] || 70}
                          min={0}
                          max={100}
                          step={1}
                          onChange={(val) => handleSliderChange(bid.id, factor, val)}
                          colorScheme="teal"
                        >
                          <SliderTrack>
                            <SliderFilledTrack />
                          </SliderTrack>
                          <SliderThumb boxSize={6} />
                        </Slider>
                        <Text ml={3} fontWeight="bold" minW="35px">
                          {assessments[bid.id]?.[factor] || 70}
                        </Text>
                      </Flex>
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </CardBody>
    </Card>
  );
};
