import { Box, Flex, Icon, IconButton, Table, Tag, Tbody, Td, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaFileDownload } from "react-icons/fa";
import { TextDocumentDto, ViewDocumentAsOwnerDto } from "../../../../autogen/bff-api";
import { DownloadDocumentIconButton } from "../../../../common/documents/DownloadDocumentIconButton/DownloadDocumentIconButton";
import { TextDocumentModal } from "../../../../common/documents/TextDocumentModal";
import { isTextDocument, isViewDocumentAsOwnerDto } from "../../../../common/documents/utils";
import { downloadTextDocument } from "../../../../common/input/TipTap/downloadTextDocument";

interface Props {
  documents: (ViewDocumentAsOwnerDto | TextDocumentDto)[];
}

export const Documents = ({ documents }: Props) => {
  const toast = useToast();

  const [textDocumentToView, setTextDocumentToView] = useState<TextDocumentDto>();

  return (
    <>
      {textDocumentToView && (
        <TextDocumentModal
          textDocument={textDocumentToView}
          onClose={() => setTextDocumentToView(undefined)}
          isUpdating={false}
          editable={false}
        />
      )}
      <Box
        width="full"
        maxWidth="full"
        overflowX={{ base: "auto", md: "visible" }}
        border="1px solid"
        borderColor="smBorder"
        borderRadius={"md"}
        py="2.5"
      >
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>{t("Name")}</Th>
              <Th>{t("Tags")}</Th>
              <Th>{t("Last updated")}</Th>
              <Th>{t("Download")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {documents.length === 0 && (
              <Tr>
                <Td colSpan={4} width={"100%"} textAlign="center">
                  {t("No documents")}.
                </Td>
              </Tr>
            )}
            {documents.map((d) => (
              <Tr
                key={d.id}
                onClick={isTextDocument(d) ? () => setTextDocumentToView(d) : undefined}
                cursor={isTextDocument(d) ? "pointer" : "auto"}
              >
                <Td>
                  <Text fontSize={"sm"}>{d.name}</Text>
                </Td>
                <Td>
                  {isViewDocumentAsOwnerDto(d) ? null : (
                    <Flex flexWrap="wrap" columnGap="1" rowGap="1" maxWidth="60">
                      {d.tags.map((tag) => (
                        <Tag key={tag} fontSize="sm">
                          {tag}
                        </Tag>
                      ))}
                    </Flex>
                  )}
                </Td>
                <Td>
                  {isViewDocumentAsOwnerDto(d)
                    ? new Date(d.uploadedAt).toLocaleString()
                    : new Date(d.modifiedAt).toLocaleString()}
                </Td>
                <Td>
                  {isViewDocumentAsOwnerDto(d) ? (
                    <DownloadDocumentIconButton documentId={d.id} />
                  ) : (
                    <IconButton
                      aria-label="download text document"
                      variant="ghost"
                      icon={<Icon as={FaFileDownload} />}
                      onClick={async (e) => {
                        e.stopPropagation();
                        if (!d.text) {
                          toast({ variant: "subtle", status: "warning", title: t("Cannot download empty document") });
                          return;
                        }
                        try {
                          await downloadTextDocument({ fileName: d.name, content: d.text });
                        } catch (e) {
                          toast({ variant: "subtle", status: "error", title: t("Failed to download document") });
                        }
                      }}
                    />
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
