import { createAsyncThunk } from "@reduxjs/toolkit";
import { bffApi } from "../../../../autogen/bff-api";
import { BasicBidState } from "../../reducers/basicBidReducer";

export const loadBasicBidThunk = createAsyncThunk(
  "basicBid/load",
  async (
    props: {
      bidId: string;
    },
    { dispatch }
  ): Promise<BasicBidState | null> => {
    const response = dispatch(
      bffApi.endpoints.getBseBid.initiate({
        bidId: props.bidId,
      })
    );
    response.unsubscribe();
    const result = await response;

    if ("data" in result && result.data) {
      const body = result.data;
      const forEvent = body.forEvent;
      return {
        id: body.id,
        forEvent: forEvent,
        forNegotiationRound: body.forNegotiationRound,
        lastChanged: body.modifiedAt,
        comment: {
          value: body.deliveredFields?.comment ?? body.draftFields?.comment ?? null,
          errorMessage: null,
        },
        productGroupBids: {
          value: body.deliveredFields?.products ?? body.draftFields?.products ?? null,
          errorMessage: null,
        },
        documents: {
          value: body.documents,
          errorMessage: null,
        },
        uploads: {
          value: body.uploads,
          errorMessage: null,
        },
      };
    } else {
      return null;
    }
  }
);
