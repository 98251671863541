import { Box, Flex, Grid, GridItem, Icon, LinkBox, LinkOverlay } from "@chakra-ui/react";
import moment from "moment";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  FaAward,
  FaBullhorn,
  FaClock,
  FaComment,
  FaEnvelope,
  FaQuestion,
  FaShareAlt,
  FaShoppingBasket,
  FaSignature,
  FaTasks,
} from "react-icons/fa";
import { NotificationDto, useMarkNotificationsAsViewedMutation } from "../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../common/auth/useLoggedInWithOrgContextState";
import { displayDateWithTime } from "../../common/formatting/displayDateWithTime";
import { Explanation } from "../../common/support/Explanation/Explanation";
import { urls } from "../../urls";
import {
  getNotificationDescription,
  requireBidMeta,
  requireContractDocumentMeta,
  requireContractReminderMeta,
  requireContractkMeta,
  requireSourcingEventAnnouncementMeta,
  requireSourcingEventMeta,
  requireSourcingEventQuestionCommentMeta,
  requireSourcingEventQuestionMeta,
  requireTaskMeta,
} from "./get-notification-description";

interface Props {
  notification: NotificationDto;
  index: number;
}

export const Notification = ({ notification, index }: Props) => {
  const { t } = useTranslation();
  const [markAsViewed] = useMarkNotificationsAsViewedMutation();

  const authState = useLoggedInWithOrgContextState();

  const getIcon = (): ReactElement => {
    switch (notification.type) {
      case "ContractDateReminder":
        return <Icon as={FaClock} w="18px" h="18px" color="smBackground" />;
      case "ContractDocumentCompletedSigning":
        return <Icon as={FaSignature} w="15px" h="15px" color="smBackground" />;
      case "ContractSharedWithDepartment":
      case "ContractSharedWithOrg":
      case "ContractSharedWithPerson":
        return <Icon as={FaShareAlt} w="18px" h="18px" color="smBackground" />;
      case "ContractTaskDelegated":
      case "ContractTaskStatusUpdated":
      case "ContractTaskCommentAdded":
      case "ContractTaskDeleted":
      case "ContractTaskDueDateUpdated":
      case "ContractTaskDueDateReminder":
        return <Icon as={FaTasks} w="15px" h="15px" color="smBackground" />;
      case "SourcingEventAnnouncement":
        return <Icon as={FaBullhorn} w="18px" h="18px" color="smBackground" />;
      case "SourcingEventAwarded":
        return <Icon as={FaAward} w="19px" h="19px" color="smBackground" />;
      case "SourcingEventNewQuestion":
      case "SourcingEventQuestionStatusUpdated":
        return <Icon as={FaQuestion} w="17px" h="17px" color="smBackground" />;
      case "SourcingEventNewQuestionComment":
        return <Icon as={FaComment} w="18px" h="18px" color="smBackground" />;
      case "SourcingEventOrganizationInvite":
      case "NegotiationInvite":
      case "BidDeliveryDeadline":
        return <Icon as={FaShoppingBasket} w="18px" h="18px" color="smBackground" />;
      case "BidDelivered":
        return <Icon as={FaEnvelope} w="18px" h="18px" color="smBackground" />;
    }
  };

  const getLink = (): string => {
    switch (notification.type) {
      case "ContractDateReminder": {
        const meta = requireContractReminderMeta(notification);
        return urls.contracts.viewSingle.go(meta.contractId);
      }
      case "ContractDocumentCompletedSigning": {
        const meta = requireContractDocumentMeta(notification);
        return urls.contracts.viewSingle.documents(meta.contractId);
      }
      case "ContractSharedWithDepartment":
      case "ContractSharedWithOrg":
      case "ContractSharedWithPerson": {
        const meta = requireContractkMeta(notification);
        return urls.contracts.viewSingle.go(meta.contractId);
      }
      case "ContractTaskCommentAdded":
      case "ContractTaskDeleted":
      case "ContractTaskDueDateUpdated":
      case "ContractTaskDelegated":
      case "ContractTaskStatusUpdated":
      case "ContractTaskDueDateReminder": {
        const meta = requireTaskMeta(notification);
        return urls.contracts.viewSingle.task(meta.contractId, meta.taskId);
      }
      case "SourcingEventAnnouncement": {
        const meta = requireSourcingEventAnnouncementMeta(notification);
        return urls.events.supplierView.announcement(meta.eventId, meta.announcementId);
      }
      case "SourcingEventAwarded":
      case "BidDeliveryDeadline":
      case "SourcingEventOrganizationInvite":
      case "NegotiationInvite": {
        const meta = requireSourcingEventMeta(notification);
        if (notification.meta.sourcingEvent?.eventOwningOrganizationId === authState.selectedOrg.id) {
          return urls.events.view.go(meta.eventId);
        }
        return urls.events.supplierView.go(meta.eventId);
      }
      case "BidDelivered": {
        const meta = requireBidMeta(notification);
        return urls.events.view.go(meta.eventId);
      }
      case "SourcingEventNewQuestion": {
        const meta = requireSourcingEventQuestionMeta(notification);
        return urls.events.view.question(meta.eventId, meta.questionId);
      }
      case "SourcingEventNewQuestionComment": {
        const meta = requireSourcingEventQuestionCommentMeta(notification);
        if (meta.commentAddedByBuyer) return urls.events.supplierView.question(meta.eventId, meta.questionId);
        else return urls.events.view.question(meta.eventId, meta.questionId);
      }
      case "SourcingEventQuestionStatusUpdated": {
        const meta = requireSourcingEventQuestionMeta(notification);
        return urls.events.supplierView.question(meta.eventId, meta.questionId);
      }
    }
  };

  const link = getLink();
  return (
    <LinkBox
      cursor={"pointer"}
      _hover={{ bg: "smBackgroundTertiary" }}
      borderTopLeftRadius={index === 0 ? "10px" : "0px"}
      borderTopRightRadius={index === 0 ? "10px" : "0px"}
      onClick={() => {
        markAsViewed({
          markNotificationsRequest: {
            notificationIds: [notification.id],
          },
        });
      }}
    >
      <LinkOverlay href={link}>
        <Flex
          pt="10px"
          pb="10px"
          borderTopLeftRadius={index === 0 ? "10px" : "0px"}
          borderTopRightRadius={index === 0 ? "10px" : "0px"}
          p="20px"
        >
          <Grid
            w="100%"
            gridTemplateColumns={`70px 1fr 100px`}
            templateAreas={`
                "leftIcon desc rightIcon"
                "leftIcon time rightIcon"
                `}
          >
            <GridItem area={"leftIcon"} display={"flex"} alignItems={"center"}>
              <Flex
                w={"50px"}
                height={"50px"}
                borderRadius="50%"
                bg={"blue.500"}
                alignItems={"center"}
                justifyContent="center"
              >
                {getIcon()}
              </Flex>
            </GridItem>
            <GridItem area={"desc"}>{getNotificationDescription(notification)}</GridItem>
            <GridItem area={"time"}>
              <Flex>
                <Flex justifyContent={"center"} alignItems={"center"} pr="5px">
                  <Icon as={FaClock} w="15px" h="15px" color={notification.viewed ? "smMuted" : "blue.500"} mb="-2px" />
                </Flex>
                <Flex>
                  <Box color="smMuted">
                    <Explanation enabled={true} text={displayDateWithTime(notification.createdAt, t("at"))}>
                      <Box fontSize="sm" color={notification.viewed ? "smMuted" : "blue.500"}>
                        {moment(notification.createdAt).fromNow()}
                      </Box>
                    </Explanation>
                  </Box>
                </Flex>
              </Flex>
            </GridItem>
            <GridItem area={"rightIcon"} display="flex" justifyContent={"end"} alignItems={"center"}>
              {notification.viewed ? null : <Flex w={"15px"} height={"15px"} borderRadius="50%" bg={"blue.500"} />}
            </GridItem>
          </Grid>
        </Flex>
      </LinkOverlay>
    </LinkBox>
  );
};
