import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Icon,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useState } from "react";
import { FaCheck, FaInfoCircle, FaTrophy } from "react-icons/fa";
import { EvaluationModelType, EvaluationResult, ScoredBid } from "../evaluation-models/EvaluationModelTypes";

interface EvaluationResultsProps {
  result: EvaluationResult;
  onSelectBid: (bidId: string) => void;
  modelType?: EvaluationModelType;
}

export const EvaluationResults = ({ result, onSelectBid, modelType }: EvaluationResultsProps) => {
  const [expandedBidId, setExpandedBidId] = useState<string | null>(null);

  const toggleBidDetails = (bidId: string) => {
    if (expandedBidId === bidId) {
      setExpandedBidId(null);
    } else {
      setExpandedBidId(bidId);
    }
  };

  if (!result || !result.scoredBids || result.scoredBids.length === 0) {
    return (
      <Box p={5} textAlign="center">
        <Text color="gray.500">{t("No evaluation results available yet.")}</Text>
      </Box>
    );
  }

  return (
    <Card variant="outline" mt={4} boxShadow="sm">
      <CardHeader bg="smBackgroundSecondary" py={3}>
        <Heading size="md">{t("Evaluation Results")}</Heading>
      </CardHeader>
      <CardBody p={0}>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr bg="gray.50">
              <Th>{t("Rank")}</Th>
              <Th>{t("Supplier")}</Th>
              {modelType === EvaluationModelType.QUALITY_ADJUSTED ? (
                <>
                  <Th isNumeric>{t("Original Price")}</Th>
                  <Th isNumeric>{t("Adjusted Price")}</Th>
                  <Th isNumeric>{t("Adjustment %")}</Th>
                </>
              ) : (
                <>
                  <Th isNumeric>{t("Price")}</Th>
                  <Th isNumeric>{t("Score")}</Th>
                </>
              )}
              <Th textAlign="center">{t("Status")}</Th>
              <Th>{t("Action")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {result.scoredBids.map((bid, index) => (
              <React.Fragment key={bid.bidId}>
                <Tr
                  _hover={{ bg: "smBackgroundSecondary" }}
                  cursor="pointer"
                  onClick={() => toggleBidDetails(bid.bidId)}
                  bg={bid.isWinner ? "green.50" : undefined}
                >
                  <Td>
                    <Flex alignItems="center">
                      {index === 0 && <Icon as={FaTrophy} color="yellow.500" boxSize={4} mr={2} />}
                      <Text fontWeight={index === 0 ? "bold" : "normal"}>{index + 1}</Text>
                    </Flex>
                  </Td>
                  <Td fontWeight={bid.bidId === result.bestBidId ? "bold" : "normal"}>{bid.supplierName}</Td>
                  {modelType === EvaluationModelType.QUALITY_ADJUSTED ? (
                    <>
                      <Td isNumeric fontWeight={bid.bidId === result.bestBidId ? "bold" : "normal"}>
                        {typeof bid.details?.originalPrice === 'number' ? bid.details.originalPrice.toFixed(2) : '0.00'}
                      </Td>
                      <Td isNumeric fontWeight={bid.bidId === result.bestBidId ? "bold" : "normal"}>
                        {typeof bid.details?.adjustedPrice === 'number' ? bid.details.adjustedPrice.toFixed(2) : '0.00'}
                      </Td>
                      <Td isNumeric fontWeight={bid.bidId === result.bestBidId ? "bold" : "normal"}>
                        {typeof bid.details?.adjustmentPercentage === 'number' ? bid.details.adjustmentPercentage.toFixed(2) : '0.00'}%
                      </Td>
                    </>
                  ) : (
                    <>
                      <Td isNumeric fontWeight={bid.bidId === result.bestBidId ? "bold" : "normal"}>
                        {typeof bid.details?.totalPrice === 'number' ? bid.details.totalPrice.toFixed(2) : '0.00'}
                      </Td>
                      <Td isNumeric fontWeight={bid.bidId === result.bestBidId ? "bold" : "normal"}>
                        {bid.totalScore.toFixed(2)}
                      </Td>
                    </>
                  )}
                  <Td textAlign="center">
                    {bid.isWinner ? (
                      <Tag colorScheme="green" size="sm">
                        <Icon as={FaCheck} mr={1} boxSize={3} />
                        {t("Selected")}
                      </Tag>
                    ) : (
                      <Tag colorScheme="gray" size="sm">
                        {t("Not Selected")}
                      </Tag>
                    )}
                  </Td>
                  <Td>
                    <Button
                      size="xs"
                      colorScheme="blue"
                      variant={bid.isWinner ? "outline" : "solid"}
                      onClick={(e) => {
                        e.stopPropagation();
                        onSelectBid(bid.bidId);
                      }}
                    >
                      {bid.isWinner ? t("Deselect") : t("Select")}
                    </Button>
                  </Td>
                </Tr>
                {expandedBidId === bid.bidId && (
                  <Tr>
                    <Td colSpan={7} p={0}>
                      <Box p={4} bg="gray.50">
                        <BidDetails bid={bid} />
                      </Box>
                    </Td>
                  </Tr>
                )}
              </React.Fragment>
            ))}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

interface BidDetailsProps {
  bid: ScoredBid;
}

const BidDetails = ({ bid }: BidDetailsProps) => {
  if (!bid.details) {
    return (
      <Text fontSize="sm" color="gray.500">
        {t("No detailed information available.")}
      </Text>
    );
  }

  // Price-related details
  const priceDetails = [];
  if (bid.details.originalPrice !== undefined) {
    priceDetails.push(
      <Box key="original-price">
        <Text fontSize="sm" fontWeight="medium">
          {t("Original Price")}:
        </Text>
        <Text fontSize="sm">{(bid.details.originalPrice as number).toLocaleString()}</Text>
      </Box>
    );
  }

  if (bid.details.adjustedPrice !== undefined) {
    priceDetails.push(
      <Box key="adjusted-price">
        <Text fontSize="sm" fontWeight="medium">
          {t("Adjusted Price")}:
        </Text>
        <Text fontSize="sm">{(bid.details.adjustedPrice as number).toLocaleString()}</Text>
      </Box>
    );
  }

  if (bid.details.totalAdjustment !== undefined) {
    priceDetails.push(
      <Box key="price-adjustment">
        <Text fontSize="sm" fontWeight="medium">
          {t("Price Adjustment")}:
        </Text>
        <Text fontSize="sm">{(bid.details.totalAdjustment as number).toLocaleString()}</Text>
      </Box>
    );
  }

  // Quality-related details
  const qualityDetails = [];
  if (bid.details.qualitativeAssessment) {
    qualityDetails.push(
      <Box key="assessment">
        <Text fontSize="sm" fontWeight="medium">
          {t("Assessment")}:
        </Text>
        <Text fontSize="sm">{bid.details.qualitativeAssessment as string}</Text>
      </Box>
    );
  }
  
  // We'll use the existing factorSection below instead of adding it here

  if (bid.details.strengths) {
    const strengths = bid.details.strengths as string[];
    if (strengths.length > 0) {
      qualityDetails.push(
        <Box key="strengths">
          <Text fontSize="sm" fontWeight="medium">
            {t("Strengths")}:
          </Text>
          <Box pl={2}>
            {strengths.map((strength, index) => (
              <Text fontSize="sm" key={index}>
                • {strength}
              </Text>
            ))}
          </Box>
        </Box>
      );
    }
  }

  if (bid.details.weaknesses) {
    const weaknesses = bid.details.weaknesses as string[];
    if (weaknesses.length > 0) {
      qualityDetails.push(
        <Box key="weaknesses">
          <Text fontSize="sm" fontWeight="medium">
            {t("Weaknesses")}:
          </Text>
          <Box pl={2}>
            {weaknesses.map((weakness, index) => (
              <Text fontSize="sm" key={index}>
                • {weakness}
              </Text>
            ))}
          </Box>
        </Box>
      );
    }
  }

  // Factor adjustments or scores
  let factorSection = null;
  if (bid.details.factorAdjustments) {
    const factors = bid.details.factorAdjustments as Record<string, number>;
    factorSection = (
      <Box mt={3}>
        <Text fontSize="sm" fontWeight="medium">
          {t("Factor Adjustments")}:
        </Text>
        <Box pl={2}>
          {Object.entries(factors).map(([factor, value], index) => (
            <Flex key={index} justify="space-between" width="100%" pr={4}>
              <Text fontSize="sm">{factor}:</Text>
              <Text fontSize="sm" fontWeight={value > 0 ? "medium" : "normal"}>
                {value.toLocaleString()}
              </Text>
            </Flex>
          ))}
        </Box>
      </Box>
    );
  } else if (bid.details.factorScores) {
    const factors = bid.details.factorScores as Record<string, number>;
    factorSection = (
      <Box mt={3}>
        <Text fontSize="sm" fontWeight="medium">
          {t("Factor Scores")}:
        </Text>
        <Box pl={2}>
          {Object.entries(factors).map(([factor, score], index) => (
            <Text fontSize="sm" key={index}>
              {factor}: {score.toFixed(2)}
            </Text>
          ))}
        </Box>
      </Box>
    );
  }

  // Add adjustment percentage display for Quality Adjusted Price model
  if (bid.details.adjustmentPercentage !== undefined) {
    priceDetails.push(
      <Box key="adjustment-percentage">
        <Text fontSize="sm" fontWeight="medium">
          {t("Adjustment Percentage")}:
        </Text>
        <Text fontSize="sm">
          {typeof bid.details.adjustmentPercentage === 'number' ? 
            `${bid.details.adjustmentPercentage.toFixed(2)}%` : '0.00%'}
        </Text>
      </Box>
    );
  }
  
  // If max adjustment percentage is available, show it
  if (bid.details.maxAdjustmentPercent !== undefined) {
    priceDetails.push(
      <Box key="max-adjustment">
        <Text fontSize="sm" fontWeight="medium">
          {t("Maximum Adjustment")}:
        </Text>
        <Text fontSize="sm">
          {typeof bid.details.maxAdjustmentPercent === 'number' ? 
            `${bid.details.maxAdjustmentPercent.toFixed(2)}%` : '0.00%'}
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <Flex wrap="wrap" gap={6}>
        {/* Price details */}
        {priceDetails.length > 0 && (
          <Box>
            <Text fontSize="sm" fontWeight="bold" mb={2}>
              {t("Price Information")}
            </Text>
            <Flex direction="column" gap={2}>
              {priceDetails}
            </Flex>
          </Box>
        )}

        {/* Quality details */}
        {qualityDetails.length > 0 && (
          <Box minW="300px">
            <Text fontSize="sm" fontWeight="bold" mb={2}>
              {t("Quality Assessment")}
            </Text>
            <Flex direction="column" gap={3}>
              {qualityDetails}
            </Flex>
          </Box>
        )}

        {/* Factor scores/adjustments */}
        {factorSection && <Box minW="200px">{factorSection}</Box>}
      </Flex>

      {/* Any additional model-specific details */}
      {bid.details.instructionsUsed !== undefined && (
        <Box mt={4} pt={3} borderTop="1px solid" borderColor="gray.200">
          <Flex alignItems="center">
            <Icon as={FaInfoCircle} color="blue.500" mr={2} />
            <Text fontSize="sm" color="gray.600">
              {t("This evaluation was performed using AI with custom instructions.")}
            </Text>
          </Flex>
        </Box>
      )}
    </Box>
  );
};
