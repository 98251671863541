import { Box, Button, Collapse, Flex, Text } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BseSupplierViewDto,
  TextDocumentDto,
  useListTextDocumentsForSourcingEventQuery,
} from "../../../../autogen/bff-api";
import { CombinedDocuments } from "../../../../common/documents/CombinedDocuments";
import { TextDocumentModal } from "../../../../common/documents/TextDocumentModal";
import { TipTap } from "../../../../common/input/TipTap/TipTap";
import { CompanyPitch } from "./CompanyPitch";
import { useNegotiation } from "./NegotiationContext";

interface Props {
  data: BseSupplierViewDto;
}

export const BseSupplierViewEventInfo = ({ data }: Props) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { t } = useTranslation();
  const { currentRound } = useNegotiation();

  const { data: textDocRes } = useListTextDocumentsForSourcingEventQuery({ eventId: data.id });
  const [textDocumentToView, setTextDocumentToView] = useState<TextDocumentDto>();

  const combinedDocs = useMemo(() => {
    const docs = currentRound ? currentRound.documents : data.documents;
    return [...(docs ?? []), ...(textDocRes?.documents ?? [])];
  }, [currentRound, data.documents, textDocRes?.documents]);

  const description = currentRound ? currentRound?.description : data.description;

  return (
    <>
      {textDocumentToView && (
        <TextDocumentModal
          textDocument={textDocumentToView}
          onClose={() => setTextDocumentToView(undefined)}
          isUpdating={false}
          editable={false}
        />
      )}
      <Flex width="full" columnGap={5}>
        <Flex width={{ base: "full", md: "50%" }} rounded="md" flexDirection="column">
          <Text fontSize={"sm"} fontWeight="semibold" pb="2">
            {t("Description")}
          </Text>
          <Box position="relative" width="full" shadow="inner" rounded="lg" pt="0" px="4" pb="2">
            <Collapse startingHeight="132px" in={showFullDescription}>
              <TipTap content={description ?? ""} editable={false} hasBorder={false} maxHeight="60" padding="0" />
            </Collapse>
            <Box
              display={showFullDescription ? "none" : "block"}
              position="absolute"
              bottom="0"
              left="0"
              width="full"
              height="4rem"
              p="2"
            >
              <Box width="full" height="full" bgGradient="linear(to-b, transparent, smBackground)"></Box>
            </Box>
            <Button size="sm" variant={"link"} mt="1" onClick={() => setShowFullDescription(!showFullDescription)}>
              {!showFullDescription ? t("Show more") : t("Show less")}
            </Button>
          </Box>
        </Flex>
        <Flex flexDirection="column" width="50%">
          <Text fontSize={"sm"} fontWeight="semibold" pb="2">
            {t("Invited by")}
          </Text>
          <CompanyPitch organization={data.owningOrganization} />
        </Flex>
      </Flex>
      <Box display="flex" flexDirection="column" py="5">
        <Text fontSize={"sm"} fontWeight="semibold" pb="4">
          {t("Documents")}
        </Text>
        <CombinedDocuments documents={combinedDocs} />
      </Box>
    </>
  );
};
