import { Button, Flex, useToast } from "@chakra-ui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NegotiationRoundDto, useEditNegotiationRoundMutation } from "../../../../autogen/bff-api";
import { isFetchBaseQueryError } from "../../../../common/errors/isFetchBaseQueryError";
import { useApiError } from "../../../../common/errors/useApiError";
import { DateTimeFormControl } from "../../../../common/input/DateTimeSelectors/DateTimeFormControl";
import { Description } from "./Description";

interface Props {
  negotiationRound: NegotiationRoundDto;
  nextStep: () => void;
}

export const Intro = ({ negotiationRound, nextStep }: Props) => {
  const { t } = useTranslation();
  const toast = useToast();
  const displayer = useApiError();
  const [editNegotiationRound] = useEditNegotiationRoundMutation();

  const updateDeadline = useCallback(
    async (date?: string) => {
      if (!date) return;

      await editNegotiationRound({
        negotiationId: negotiationRound.id,
        editNegotiationRequest: {
          deadline: {
            value: date,
          },
        },
      })
        .unwrap()
        .catch((error) => {
          if (isFetchBaseQueryError(error)) displayer.trigger(error);
          toast({
            title: t("Failed to update deadline"),
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    },
    [editNegotiationRound, negotiationRound.id, displayer, toast, t]
  );

  return (
    <Flex grow="1" pb="8">
      <Flex direction="column" width="full" gap="10">
        <Description negotiationRound={negotiationRound} />
        <DateTimeFormControl
          id="deadline"
          title={t("Deadline")}
          helperText={t("Select a bid deadline for this round")}
          errorMessage={null}
          defaultDate={negotiationRound.deadline}
          onChange={updateDeadline}
        />
        <Button variant="solid" width="full" colorScheme="teal" onClick={nextStep}>
          {t("Next")}
        </Button>
      </Flex>
    </Flex>
  );
};
