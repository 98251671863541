import { Icon } from "@chakra-ui/react";
import React from "react";
import { FaMoneyBill } from "react-icons/fa";
import { ReceivedBseBidDto } from "../../../../../../autogen/bff-api";
import { ProductGroup } from "../../../../../../common/types";
import {
  EvaluationModel,
  EvaluationModelContext,
  EvaluationModelType,
  EvaluationResult,
  ExtendedWinningBids,
  ScoredBid,
} from "./EvaluationModelTypes";

/**
 * PriceEvaluationModel implements simple price-based evaluation.
 * Lowest price gets highest score.
 */
export class PriceEvaluationModel implements EvaluationModel {
  public type = EvaluationModelType.LOWEST_PRICE;
  public name = "Lowest Price";
  public description = "Evaluate bids based solely on price";
  public configFields = [];

  public getModelIcon(): JSX.Element {
    return React.createElement(Icon, { as: FaMoneyBill });
  }

  public getConfigComponent = undefined;

  public evaluateBids(context: EvaluationModelContext): EvaluationResult {
    const { bids, productGroups, selectedBids } = context;

    const scoredBids: ScoredBid[] = [];

    // Extract all prices and find the lowest
    const bidPrices = bids.map((bid) => this.getTotalPrice(bid, productGroups));
    const lowestPrice = Math.min(...bidPrices.filter((price) => price > 0));

    bids.forEach((bid) => {
      const price = this.getTotalPrice(bid, productGroups);
      // Calculate score (100 for lowest price, proportionally less for higher prices)
      const priceScore = price > 0 ? Math.round((lowestPrice / price) * 100) : 0;

      // Check if this bid is selected for any product
      const isSelected =
        this.getSelectedBidIdsForSupplier(bid, selectedBids as unknown as ExtendedWinningBids[]).length > 0;

      scoredBids.push({
        bidId: bid.id,
        supplierId: bid.owningOrganization.id,
        supplierName: bid.owningOrganization.name,
        totalScore: priceScore,
        priceScore,
        details: {
          price,
          lowestPrice,
          priceDifference: price - lowestPrice,
          percentageAboveLowest: lowestPrice > 0 ? ((price - lowestPrice) / lowestPrice) * 100 : 0,
        },
        isWinner: isSelected,
      });
    });

    // Sort by total score (highest first)
    scoredBids.sort((a, b) => b.totalScore - a.totalScore);

    // Best bid is the lowest price (highest score)
    const bestBid = scoredBids.length > 0 ? scoredBids[0] : undefined;

    return {
      scoredBids,
      bestBidId: bestBid?.bidId,
      bestScore: bestBid?.totalScore,
      selectedBidIds: (selectedBids as unknown as ExtendedWinningBids[]).flatMap((sb) => sb.winningBids || []),
    };
  }

  // HELPER METHODS

  private getTotalPrice(bid: ReceivedBseBidDto, groups: ProductGroup[]): number {
    return groups.reduce((sum, group) => {
      const groupTotal = this.getProductGroupTotal(bid, group);
      return sum + groupTotal;
    }, 0);
  }

  private getProductGroupTotal(bid: ReceivedBseBidDto, group: ProductGroup): number {
    if (!group.products || !Array.isArray(group.products)) {
      return 0;
    }
    
    return group.products.reduce((sum, product) => {
      if (!product || !product.id) {
        return sum;
      }
      
      // Find the product bid across all product groups in the bid
      const bidProducts = bid.products || [];
      
      for (const bidGroup of bidProducts) {
        const productBids = bidGroup.productBids || [];
        const matchingBid = productBids.find(pb => pb.productId === product.id);
        
        if (matchingBid) {
          // Extract price safely using type assertion since price may exist on extended types
          const extendedBid = matchingBid as unknown as { price?: number | string };
          const price = typeof extendedBid.price === 'number' ? extendedBid.price : 
                        (typeof extendedBid.price === 'string' ? parseFloat(extendedBid.price) : 0);
          
          // Extract quantity safely
          const quantity = typeof product.quantity === 'number' ? product.quantity : 
                          (typeof product.quantity === 'string' ? parseFloat(product.quantity) : 1);
          
          return sum + (price * quantity);
        }
      }
      return sum;
    }, 0);
  }

  private getSelectedBidIdsForSupplier(
    bid: ReceivedBseBidDto,
    selectedBids: ExtendedWinningBids[] | undefined
  ): string[] {
    const result: string[] = [];

    if (!selectedBids || selectedBids.length === 0) {
      return result;
    }

    for (const selected of selectedBids) {
      if (selected.winningBids && selected.winningBids.includes(bid.id)) {
        result.push(bid.id);
      }
    }

    return result;
  }
}
