import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Icon,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useCallback } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { EvaluationModel, evaluationModels, EvaluationModelType } from "../evaluation-models/EvaluationModelTypes";

interface ModelSelectorProps {
  selectedModelType?: EvaluationModelType;
  onSelectModel: (model: EvaluationModel) => void;
  disabledModels?: EvaluationModelType[];
}

export const ModelSelector = ({ selectedModelType, onSelectModel, disabledModels = [] }: ModelSelectorProps) => {
  const handleModelSelect = useCallback(
    (modelType: EvaluationModelType) => {
      // Get the model from the models list filtered by type
      const modelsList = Object.values(evaluationModels);
      const model = modelsList.find((m) => m.type === modelType);
      if (model) {
        onSelectModel(model);
      }
    },
    [onSelectModel]
  );

  return (
    <Card variant="outline">
      <CardHeader>
        <Heading size="md">{t("Select Evaluation Model")}</Heading>
      </CardHeader>
      <CardBody>
        <RadioGroup onChange={(value) => handleModelSelect(value as EvaluationModelType)} value={selectedModelType}>
          <Stack spacing={4}>
            {Object.values(evaluationModels).map((model: EvaluationModel) => {
              const isDisabled = disabledModels.includes(model.type);

              return (
                <Flex
                  key={model.type}
                  p={3}
                  borderWidth="1px"
                  borderRadius="md"
                  alignItems="center"
                  opacity={isDisabled ? 0.5 : 1}
                  cursor={isDisabled ? "not-allowed" : "pointer"}
                  onClick={() => {
                    if (!isDisabled) {
                      handleModelSelect(model.type);
                    }
                  }}
                  position="relative"
                  bg={selectedModelType === model.type ? "smPrimarySofter" : undefined}
                  borderColor={selectedModelType === model.type ? "smPrimary" : "smBorder"}
                >
                  <Radio value={model.type} isDisabled={isDisabled} colorScheme="teal" opacity={0.8} mr={3} />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    w={6}
                    h={6}
                    mr={3}
                    color={selectedModelType === model.type ? "smPrimary" : "smMuted"}
                  >
                    {model.getModelIcon()}
                  </Box>
                  <Box flex={1}>
                    <Text fontWeight="medium" color="smText">
                      {t(model.name)}
                    </Text>
                    <Text fontSize="sm" color="smMuted" mt={1}>
                      {t(model.description || "")}
                    </Text>
                  </Box>
                  <Tooltip hasArrow label={t(model.description)} placement="top">
                    <Box position="absolute" top={2} right={2}>
                      <Icon as={FaInfoCircle} color="smMuted" opacity={0.6} />
                    </Box>
                  </Tooltip>
                </Flex>
              );
            })}
          </Stack>
        </RadioGroup>
      </CardBody>
    </Card>
  );
};
