import { Box, Flex, Grid, GridItem, Image, Text, useColorMode } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { assets } from "../../../assets/assets";

export const EmailVerification = () => {
  const { t } = useTranslation();
  const theme = useColorMode();
  
  return (
    <Box
      width="full"
      height="100vh"
      bgGradient="linear(to-br, smTealShadow, smBackground, smBackground, smBackground, smBlueShadow)"
    >
      <Grid
        gridTemplateColumns={"1fr 450px 1fr"}
        height="100%"
        gridTemplateRows={"1fr auto 2fr"}
        templateAreas={`
                "left top right"
                "left login right"
                "left bottom right"
                `}
      >
        <GridItem area="left"></GridItem>
        <GridItem area="top"></GridItem>
        <GridItem area="bottom"></GridItem>
        <GridItem area="right"></GridItem>
        <GridItem area="login" borderRadius={10} boxShadow="0 9px 46px 8px rgb(0,0,0,0.2)" padding={10}>
          <Flex w="100%" h="100%" flexDirection="column">
            <Flex>
              <Image 
                src={theme.colorMode === "dark" ? assets.logo_dark : assets.logo} 
                alt="Logo" 
                h="30px" 
                mt="10px" 
              />
            </Flex>
            <br />
            <Text color="smPrimary">
              <b>{t("Email confirmed!")}</b> <br />
              {t("Feel free to continue where you left off")}.
            </Text>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};
