import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useCallback, useEffect } from "react";
import { UploadDto, bffApi } from "../../../autogen/bff-api";
import { requireStringEnvVar } from "../../../config";
import { useApiError } from "../../errors/useApiError";
import { getPusher } from "../../pusher";
import { useAppDispatch } from "../../redux/hooks";

export type DocumentUploadContext = "SourcingEvent" | "Bid" | "Contract" | "Project" | "NegotiationRound";

export const useDocumentsUploader = ({
  entityId,
  entityType,
  refetch,
}: {
  entityType: DocumentUploadContext;
  entityId: string;
  refetch: () => void;
}) => {
  const dispatch = useAppDispatch();
  const displayer = useApiError();

  useEffect(() => {
    const channel = getPusher().subscribe(entityId);
    channel.bind("document-added", refetch);

    return () => {
      channel.unbind("document-added", refetch);
    };
  }, [entityId, refetch]);

  const createPostRequest = useCallback(
    async (file: {
      id: string;
      name: string;
      extension: string;
      file: File;
    }): Promise<{ data: UploadDto } | { error: FetchBaseQueryError | SerializedError }> => {
      switch (entityType) {
        case "Bid": {
          const response = dispatch(
            bffApi.endpoints.createUploadForBseBid.initiate({
              bidId: entityId,
              uploadId: file.id,
              createUploadRequestDto: {
                fileName: file.name,
                fileSize: file.file.size,
                fileExtension: file.extension,
                mimeType: file.file.type,
              },
            })
          );
          response.reset();
          return await response;
        }
        case "Contract": {
          const response = dispatch(
            bffApi.endpoints.createUploadForContract.initiate({
              contractId: entityId,
              uploadId: file.id,
              createUploadRequestDto: {
                fileName: file.name,
                fileSize: file.file.size,
                fileExtension: file.extension,
                mimeType: file.file.type,
              },
            })
          );
          response.reset();
          return await response;
        }
        case "SourcingEvent": {
          const response = dispatch(
            bffApi.endpoints.createUploadForBse.initiate({
              eventId: entityId,
              uploadId: file.id,
              createUploadRequestDto: {
                fileName: file.name,
                fileSize: file.file.size,
                fileExtension: file.extension,
                mimeType: file.file.type,
              },
            })
          );
          response.reset();
          return await response;
        }
        case "NegotiationRound": {
          const response = dispatch(
            bffApi.endpoints.createUploadForNegotiationRound.initiate({
              negotiationRoundId: entityId,
              uploadId: file.id,
              createUploadRequestDto: {
                fileName: file.name,
                fileSize: file.file.size,
                fileExtension: file.extension,
                mimeType: file.file.type,
              },
            })
          );
          response.reset();
          return await response;
        }
        case "Project": {
          const response = dispatch(
            bffApi.endpoints.createUploadForProject.initiate({
              projectId: entityId,
              uploadId: file.id,
              createUploadRequestDto: {
                fileName: file.name,
                fileSize: file.file.size,
                fileExtension: file.extension,
                mimeType: file.file.type,
              },
            })
          );
          response.reset();
          return await response;
        }
      }
    },
    [dispatch, entityId, entityType]
  );

  const upload = useCallback(
    async (file: { id: string; name: string; extension: string; file: File }): Promise<string | null> => {
      const postRequestResult = await createPostRequest(file);

      if ("data" in postRequestResult) {
        const data = postRequestResult.data;
        const form = new FormData();

        form.append("x-amz-date", data.renamedXAmzDate);
        form.append("x-amz-signature", data.renamedXAmzSignature);
        form.append("x-amz-algorithm", data.renamedXAmzAlgorithm);
        form.append("x-amz-credential", data.renamedXAmzCredential);
        form.append("policy", data.policy);
        form.append("key", data.key);
        form.append("file", file.file);

        const response = await fetch(requireStringEnvVar("VITE_S3_BASE_URL"), {
          method: "POST",
          body: form,
        });

        if (!response.ok) {
          displayer.trigger({
            message: `could not perform upload, response: ${response.body}`,
          });
        }

        return data.key;
      } else {
        displayer.trigger(postRequestResult.error);
        return null;
      }
    },
    [createPostRequest, displayer]
  );

  return {
    upload,
    createPostRequest,
  };
};
