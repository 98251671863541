import { useCallback } from "react";
import {
  ReceivedBseBidDto,
  useEditNegotiationRoundMutation,
  useUpdateBasicSourcingEventMutation,
} from "../../../../../autogen/bff-api";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import {
  removeBid,
  setNegotiationRound,
  setSourcingEvent,
} from "../../../../../common/redux/reducers/basicSourcingEventReducer";
import { ProductGroup } from "../../../../../common/types";

export const useRemoveProductBid = ({
  bid,
  productId,
  group,
  eventId,
  negotiationRoundId,
}: {
  bid: ReceivedBseBidDto;
  productId: string;
  group: ProductGroup;
  eventId: string;
  negotiationRoundId?: string;
}) => {
  const [updateSourcingEvent] = useUpdateBasicSourcingEventMutation();
  const [editNegotiationRound] = useEditNegotiationRoundMutation();
  const dispatch = useAppDispatch();

  return useCallback(async () => {
    dispatch(removeBid({ bid, productId, group }));

    if (negotiationRoundId) {
      const round = await editNegotiationRound({
        negotiationId: negotiationRoundId,
        editNegotiationRequest: {
          removeProductBids: [{ productGroupId: group.id, bids: [{ productId, bids: [bid.id] }] }],
        },
      }).unwrap();
      dispatch(setNegotiationRound(round));
    } else {
      const event = await updateSourcingEvent({
        eventId,
        editSourcingEventRequest: {
          removeProductBids: [{ productGroupId: group.id, bids: [{ productId, bids: [bid.id] }] }],
        },
      }).unwrap();
      dispatch(setSourcingEvent(event));
    }
  }, [bid, negotiationRoundId, dispatch, editNegotiationRound, eventId, group, productId, updateSourcingEvent]);
};
