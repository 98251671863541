/**
 * Utility functions for sanitizing text, filenames, and other strings
 */

/**
 * Sanitizes a filename by removing invalid characters and normalizing Unicode.
 * @param filename The filename to sanitize
 * @returns A sanitized filename suitable for file systems
 */
export const sanitizeFilename = (filename: string): string => {
  const pattern = /[^a-zA-Z0-9 \-_.]/g;
  return filename
    .normalize("NFKD")
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .replace(pattern, "_") // Replace invalid chars with underscores
    .replace(/[\s_]+/g, "_") // Collapse multiple spaces/underscores
    .replace(/^_+|_+$/g, "") // Trim leading/trailing underscores
    .substring(0, 255); // Limit length to 255 chars
};

/**
 * Sanitizes a string to be used as an Excel sheet name.
 * Excel has stricter requirements than regular filenames.
 * @param name The sheet name to sanitize
 * @param maxLength Maximum length (default 28 to allow for suffixes)
 * @returns A sanitized sheet name suitable for Excel
 */
export const sanitizeExcelSheetName = (name: string, maxLength = 28): string => {
  return sanitizeFilename(name).slice(0, maxLength);
};

/**
 * Generates a sheet name that is unique within a workbook.
 * Handles Excel's 31 character limit and ensures uniqueness by appending a counter if needed.
 * 
 * @param baseName The base name for the sheet (will be sanitized)
 * @param usedNames Set of sheet names already used in the workbook
 * @returns A unique, sanitized sheet name
 */
export const generateUniqueExcelSheetName = (baseName: string, usedNames: Set<string>): string => {
  const sanitizedName = sanitizeExcelSheetName(baseName);
  
  // Helper to generate a candidate sheet name with a counter if needed
  const generateSheetName = (base: string, count: number): string => {
    const candidate = count === 0 ? base : `${base}_${count}`;
    // If candidate exceeds 31 chars, trim the base name to fit
    if (candidate.length > 31) {
      const trimmedBase = base.slice(0, base.length - (candidate.length - 31));
      return `${trimmedBase}_${count}`;
    }
    return candidate;
  };

  // Recursively find a unique name
  const findUniqueSheetName = (base: string, existingNames: Set<string>, attempt = 0): string => {
    const candidate = generateSheetName(base, attempt);
    return existingNames.has(candidate) ? findUniqueSheetName(base, existingNames, attempt + 1) : candidate;
  };

  const uniqueName = findUniqueSheetName(sanitizedName, usedNames);
  usedNames.add(uniqueName); // Add to the set of used names
  return uniqueName;
};
