import { Box, Checkbox, Flex, Icon, IconButton, Table, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaCompressAlt, FaExpandAlt, FaGripLinesVertical, FaPlus, FaTrash } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import { useDeleteProductGroupMutation } from "../../autogen/bff-api";
import { useAppDispatch } from "../redux/hooks";
import { addProduct, removeProductGroup } from "../redux/reducers/productReducer";
import { editProductGroupThunk } from "../redux/thunks/product/editProductGroupThunk";
import { Explanation } from "../support/Explanation/Explanation";
import { Product, ProductField, ProductGroup } from "../types";
import { ProductRowForm } from "./ProductRowForm";

const initializeProduct = (productFields: ProductField[]): Product => ({
  id: uuid(),
  quantity: {
    id: uuid(),
    name: "Quantity",
    populatedBy: "Supplier",
    isRequired: false,
  },
  productFields: productFields.map((field) => ({ ...field, value: field.populatedBy === "Buyer" ? "" : undefined })),
  conversation: [],
});

const isProductSelected = ({
  selectedProducts,
  groupId,
  productId,
}: {
  selectedProducts?: ProductGroup[];
  groupId: string;
  productId: string;
}) => !!selectedProducts?.find((g) => g.id === groupId)?.products?.find((p) => p.id === productId);

const isCopiedProductSelected = ({
  selectedProducts,
  groupId,
  productId,
}: {
  selectedProducts?: ProductGroup[];
  groupId: string;
  productId: string;
}) => !!selectedProducts?.find((g) => g.originProductGroupId === groupId)?.products?.find((p) => p.id === productId);

const isGroupSelected = ({ selectedProducts, groupId }: { selectedProducts?: ProductGroup[]; groupId: string }) =>
  !!selectedProducts?.find((g) => g.id === groupId);

const areAllProductsInGroupSelected = ({
  selectedProducts,
  group,
}: {
  selectedProducts?: ProductGroup[];
  group?: ProductGroup;
}) => {
  if (!selectedProducts || !group) return false;
  const selectedGroup = selectedProducts.find((g) => g.id === group.id);
  if (!selectedGroup) return false;
  for (const p of group.products ?? []) {
    if (!selectedGroup.products?.find((sp) => sp.id === p.id)) return false;
  }
  return true;
};

export interface SelectedProduct {
  group: ProductGroup;
  product: Product;
}

export const ProductGroupForm = ({
  group,
  selectedProducts,
  highlightedProducts,
  onSelect,
  onUnSelect,
  onSelectGroup,
  onUnselectGroup,
  onProductDrag,
  onProductDragEnd,
  onProductGroupDrag,
  onProductGroupDragEnd,
  isDisabled = false,
}: {
  group: ProductGroup;
  selectedProducts?: ProductGroup[];
  highlightedProducts?: ProductGroup[];
  onSelect?: (product: Product) => void | Promise<void>;
  onUnSelect?: (product: Product) => void | Promise<void>;
  onSelectGroup?: (group: ProductGroup) => void | Promise<void>;
  onUnselectGroup?: (group: ProductGroup) => void | Promise<void>;
  onProductDrag?: (product: Product) => void | Promise<void>;
  onProductDragEnd?: (product: Product) => void | Promise<void>;
  onProductGroupDrag?: (group: ProductGroup) => void | Promise<void>;
  onProductGroupDragEnd?: (group: ProductGroup) => void | Promise<void>;
  isDisabled?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [deleteProductGroupMutation, { isLoading }] = useDeleteProductGroupMutation();

  const [showFullScreen, setShowFullScreen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  return (
    <Box
      position={showFullScreen ? "absolute" : "relative"}
      zIndex={showFullScreen ? "docked" : "auto"}
      width="full"
      left="0"
      px={showFullScreen ? "2" : "0"}
      pb={showFullScreen ? "8" : "0"}
    >
      <Box
        width="full"
        backgroundColor="smBackground"
        border="1px solid"
        borderColor="smBorder"
        rounded="lg"
        shadow={showFullScreen ? "lg" : ""}
      >
        <Flex justifyContent="space-between">
          <Flex align="center" pl="4">
            <Flex columnGap={2} align="center" justify="center" pr="2">
              {onSelectGroup && (
                <Explanation text={t("Select to see connections to sourcing events")}>
                  <Checkbox
                    size="md"
                    variant="ghost"
                    colorScheme="purple"
                    maxHeight="4"
                    isChecked={areAllProductsInGroupSelected({ selectedProducts, group })}
                    isIndeterminate={
                      !areAllProductsInGroupSelected({ selectedProducts, group }) &&
                      isGroupSelected({ selectedProducts, groupId: group.id })
                    }
                    onChange={() => {
                      if (isGroupSelected({ selectedProducts, groupId: group.id })) onUnselectGroup?.(group);
                      else onSelectGroup(group);
                    }}
                  />
                </Explanation>
              )}
              {onProductGroupDrag && (
                <Explanation text={t("Drag to sourcing event")}>
                  <IconButton
                    draggable
                    variant="ghost"
                    cursor={isDragging ? "grabbing" : "grab"}
                    aria-label="drag to event"
                    colorScheme="cyan"
                    size="xs"
                    icon={<Icon as={FaGripLinesVertical} />}
                    onDrag={() => {
                      onProductGroupDrag(group);
                      setIsDragging(true);
                    }}
                    onDragEnd={(e) => {
                      e.preventDefault();
                      setIsDragging(false);
                      onProductGroupDragEnd?.(group);
                    }}
                  />
                </Explanation>
              )}
            </Flex>
            <Text size="lg" fontWeight={"bold"}>
              {group.name}
            </Text>
            <IconButton
              aria-label="Toggle product group size"
              icon={<Icon as={showFullScreen ? FaCompressAlt : FaExpandAlt} />}
              size="sm"
              variant="ghost"
              onClick={() => setShowFullScreen(!showFullScreen)}
            />
          </Flex>
          {!isDisabled && (
            <IconButton
              aria-label="Delete product group"
              icon={<Icon as={FaTrash} />}
              variant="ghost"
              color="gray.400"
              isLoading={isLoading}
              onClick={() => {
                dispatch(removeProductGroup({ groupId: group.id }));
                deleteProductGroupMutation({ productGroupId: group.id });
              }}
            />
          )}
        </Flex>
        <Box width="full" overflowX="auto">
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                {(onProductDrag || onSelect) && <Th></Th>}
                <Th
                  colSpan={group.productFields.filter((f) => f.populatedBy === "Buyer").length + 1}
                  textAlign="center"
                  backgroundColor="smBackgroundSecondary"
                  color="smPrimary"
                  rounded="md"
                >
                  {t("Information from buyer")}
                </Th>
                <Th
                  colSpan={group.productFields.filter((f) => f.populatedBy === "Supplier").length + 1}
                  textAlign="center"
                  backgroundColor="smBackgroundTertiary"
                  color="smSecondary"
                  rounded="md"
                >
                  {t("Information from supplier")}
                </Th>
                <Th></Th>
              </Tr>
              <Tr>
                {(onProductDrag || onSelect) && <Th></Th>}
                {group.productFields
                  .filter((field) => field.populatedBy === "Buyer")
                  .map((field) => (
                    <Th key={field.id}>{field.name}</Th>
                  ))}
                <Th>{t("Quantity")}</Th>
                <Th>{t("Unit Price")}</Th>
                {group.productFields
                  .filter((field) => field.populatedBy === "Supplier")
                  .map((field) => (
                    <Th key={field.id}>{field.name}</Th>
                  ))}
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {group.products?.map((product) => (
                <ProductRowForm
                  key={product.id}
                  groupId={group.id}
                  product={product}
                  isSelected={isProductSelected({ selectedProducts, groupId: group.id, productId: product.id })}
                  isHighlighted={isCopiedProductSelected({
                    selectedProducts: highlightedProducts,
                    groupId: group.id,
                    productId: product.id,
                  })}
                  onSelect={onSelect}
                  onUnSelect={onUnSelect}
                  onProductDrag={onProductDrag}
                  onProductDragEnd={onProductDragEnd}
                  isDisabled={isDisabled}
                />
              ))}
            </Tbody>
          </Table>
        </Box>
        <Flex justifyContent="end" pr="2" pb="2">
          {!isDisabled && (
            <IconButton
              aria-label="Add Product"
              variant="outline"
              colorScheme="teal"
              size="xs"
              float="right"
              icon={<Icon as={FaPlus} />}
              ml="5"
              mt="4"
              onClick={() => {
                const product = initializeProduct(group.productFields);
                dispatch(addProduct({ groupId: group.id, product }));
                dispatch(
                  editProductGroupThunk({
                    id: group.id,
                    addProduct: {
                      product: {
                        id: product.id,
                        quantity: product.quantity,
                        productFields: product.productFields.map((field) => ({
                          id: field.id,
                          name: field.name,
                          value: field.value ? field.value : undefined,
                          populatedBy: field.populatedBy,
                          isRequired: field.isRequired,
                          type: field.type,
                        })),
                      },
                    },
                  })
                );
              }}
            />
          )}
        </Flex>
      </Box>
    </Box>
  );
};
