import { Button, Flex, Heading, Skeleton, Text, useToast } from "@chakra-ui/react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAcceptOrgUserEmailInviteMutation, useGetOrgUserEmailInviteQuery } from "../../autogen/bff-api";
import { requireStringEnvVar } from "../../config";
import { urls } from "../../urls";
import { useAuthState } from "../auth/useAuthState";
import { useApiError } from "../errors/useApiError";
import { setOrganizationId } from "../local-storage/selected-organization";

interface Props {
  inviteId: string;
  secret: string;
}

export const AcceptOrgUserInvite = ({ inviteId, secret }: Props) => {
  const authState = useAuthState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const displayer = useApiError();
  const toast = useToast();

  const [inviteAccepted, setInviteAccepted] = useState(false);

  const {
    data,
    isLoading: isLoadingInvite,
    error,
  } = useGetOrgUserEmailInviteQuery({ inviteId, secret }, { skip: inviteAccepted });

  const [acceptInvite, { isLoading: isAccepting }] = useAcceptOrgUserEmailInviteMutation();

  useEffect(() => {
    if (error) {
      toast({
        title: t("Whooops!"),
        description: t("Did you click an expired or already accepted email invite?"),
        status: "warning",
      });
      navigate(urls.home);
    }
  }, [error, toast, t, navigate]);

  if (isLoadingInvite) {
    return (
      <Flex flexDirection="column" alignItems="center">
        <Skeleton height="40px" width="300px" mb="4" />
        <Skeleton height="20px" width="250px" mb="6" />
        <Skeleton height="40px" width="200px" />
      </Flex>
    );
  }

  if (!data) return <div>{t("Invalid invite params")}</div>;

  return (
    <Flex flexDirection={"column"}>
      <Heading as="h2" size="lg">
        {t("Invitation to join")} {data.organizationName}
      </Heading>
      <br />
      <Text>
        {t("You have been invited to join")} {data.organizationName}.
      </Text>
      <Button
        mt="20px"
        variant={"solid"}
        colorScheme="blue"
        isLoading={isAccepting}
        onClick={async () => {
          try {
            const result = await acceptInvite({ inviteId, secret }).unwrap();

            setInviteAccepted(true);

            toast({
              title: t("Invitation accepted!"),
              status: "success",
            });

            setOrganizationId(result.id);

            window.location.href = requireStringEnvVar("VITE_WEBAPP_BASE_URL");
          } catch (error) {
            displayer.trigger(error as FetchBaseQueryError | SerializedError);
          }
        }}
      >
        {t("Click here to accept the invitation")}
      </Button>
      {authState.type === "LoggedInWithOrgContext" && (
        <>
          <Text mt="20px">{t("or you can")}</Text>
          <Button
            mt="20px"
            variant={"solid"}
            colorScheme="purple"
            onClick={() => {
              navigate(urls.home);
            }}
          >
            {t("Go home")}
          </Button>
        </>
      )}
    </Flex>
  );
};
