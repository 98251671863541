import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";
import { bffApi, EditSourcingEventRequest, EmailInviteLanguage, UploadDto } from "../../../../autogen/bff-api";
import { isBseDraft, isPublishedBse } from "../../../../pages/sourcing-events/typeguards";
import { getEmailSecretOverride } from "../../../local-storage/email-secret-override";
import { BasicSourcingEventState } from "../../reducers/basicSourcingEventReducer";
import { RootState } from "../../store";

export const editBseThunk = createAsyncThunk(
  "bse/edit",
  async (
    props: {
      command: EditBasicSourcingEventCommand;
    },
    { dispatch, getState }
  ): Promise<BasicSourcingEventState | null> => {
    const currentState = getState() as RootState;

    const currentEventState = currentState.basicSourcingEvent.state;
    if (!currentEventState) throw Error("Sourcing event state not defined");

    const result = await dispatch(
      bffApi.endpoints.updateBasicSourcingEvent.initiate({
        eventId: currentEventState.id,
        editSourcingEventRequest: getEditRequest(props.command),
      })
    )
      .unwrap()
      .catch(() => undefined);

    if (!result) {
      switch (props.command.type) {
        case "EditTitle":
          return {
            ...currentEventState,
            title: {
              value: currentEventState.title.value,
              errorMessage: "Invalid title update.",
            },
          };
        case "EditDescription":
          return {
            ...currentEventState,
            description: {
              value: currentEventState.description.value,
              errorMessage: "Invalid description update.",
            },
          };
        case "EditDeadline":
          return {
            ...currentEventState,
            deadline: {
              value: currentEventState.deadline.value,
              errorMessage: "Invalid deadline update.",
            },
          };
        case "EditTimezone":
          return {
            ...currentEventState,
            timezone: {
              value: currentEventState.timezone.value,
              errorMessage: "Invalid timezone update.",
            },
          };
        case "AddInvitedOrganization":
          return {
            ...currentEventState,
            organizationInvites: {
              value: currentEventState.organizationInvites.value,
              errorMessage: "Invalid update for organization invites.",
            },
          };
        case "RemoveOrganizationInvite":
          return {
            ...currentEventState,
            organizationInvites: {
              value: currentEventState.organizationInvites.value,
              errorMessage: "Invalid update for organization invites.",
            },
          };
        case "AddEmailInvites":
          return {
            ...currentEventState,
            emailInvites: {
              value: currentEventState.emailInvites.value,
              errorMessage: t("Failed to add one or more invites"),
            },
          };
        case "RemoveEmailInvite":
          return {
            ...currentEventState,
            emailInvites: {
              value: currentEventState.emailInvites.value,
              errorMessage: "Invalid update for email invites.",
            },
          };
        case "EditReferenceId":
          return {
            ...currentEventState,
            referenceId: {
              value: currentEventState.referenceId.value,
              errorMessage: "Invalid update for internal reference.",
            },
          };
        case "SetProjects":
          return {
            ...currentEventState,
          };
      }
    }

    let description: string | null;
    let deadline: string | undefined;
    let uploads: UploadDto[];

    if (isBseDraft(result)) {
      description = result.draftFields.description ?? null;
      deadline = result.draftFields.deadline;
      uploads = result.draftFields.uploads;
    } else if (isPublishedBse(result)) {
      description = result.publishedFields.description;
      deadline = result.publishedFields.deadline;
      uploads = result.publishedFields.uploads;
    } else throw Error("Invalid sourcing event state - neither draft nor published.");

    return {
      id: currentEventState.id,
      event: result,
      lastChanged: new Date().toISOString(),
      title: {
        value: result.title,
        errorMessage: currentEventState.title.errorMessage,
      },
      description: {
        value: description,
        errorMessage: currentEventState.description.errorMessage,
      },
      deadline: {
        value: deadline,
        errorMessage: currentEventState.deadline.errorMessage,
      },
      timezone: {
        value: result.timezone,
        errorMessage: currentEventState.timezone.errorMessage,
      },
      referenceId: {
        value: result.referenceId ?? null,
        errorMessage: currentEventState.referenceId.errorMessage,
      },
      documents: {
        value: result.documents,
        errorMessage: currentEventState.documents.errorMessage,
      },
      emailInvites: {
        value: result.emailInvites,
        errorMessage: null,
      },
      organizationInvites: {
        value: result.organizationInvites,
        errorMessage: currentEventState.organizationInvites.errorMessage,
      },
      uploads: {
        value: uploads,
        errorMessage: currentEventState.uploads.errorMessage,
      },
      projects: {
        values: result.projects,
        errorMessage: currentEventState.projects.errorMessage,
      },
    };
  }
);

const getEditRequest = (command: EditBasicSourcingEventCommand): EditSourcingEventRequest => {
  switch (command.type) {
    case "EditTitle":
      return {
        title: command.value,
      };
    case "EditDescription":
      return {
        description: {
          value: command.value ?? undefined,
        },
      };
    case "EditDeadline":
      return {
        deadline: {
          value: command.value,
        },
      };
    case "EditTimezone":
      return {
        timezone: {
          value: command.value ?? undefined,
        },
      };
    case "AddInvitedOrganization":
      return {
        organizationToInviteInput: command.value,
      };
    case "RemoveOrganizationInvite":
      return {
        organizationInviteIdToRemove: command.value,
      };
    case "AddEmailInvites":
      return {
        emailInvitesToAdd: command.invites,
        emailInviteSecretOverride: getEmailSecretOverride() ?? undefined,
      };
    case "RemoveEmailInvite":
      return {
        emailInviteIdToRemove: command.value,
        emailInviteSecretOverride: getEmailSecretOverride() ?? undefined,
      };
    case "EditReferenceId":
      return {
        editReferenceId: {
          value: command.value ?? undefined,
        },
      };
    case "SetProjects":
      return {
        editProjects: command.values,
      };
  }
};

type EditTitleCommand = {
  type: "EditTitle";
  value: string;
};

type EditDescriptionCommand = {
  type: "EditDescription";
  value: string | null;
};

type EditDeadlineCommand = {
  type: "EditDeadline";
  value: string;
};

type EditTimezoneCommand = {
  type: "EditTimezone";
  value: string;
};

type AddInvitedOrganizationCommand = {
  type: "AddInvitedOrganization";
  value: {
    organizationId: string;
    personIds: string[];
  };
};

type RemoveOrganizationInviteCommand = {
  type: "RemoveOrganizationInvite";
  value: string;
};

type AddEmailInvitesCommand = {
  type: "AddEmailInvites";
  invites: { email: string; language: EmailInviteLanguage; organizationEntryId: string }[];
};

type RemoveEmailInviteCommand = {
  type: "RemoveEmailInvite";
  value: string;
};

type EditReferenceIdCommand = {
  type: "EditReferenceId";
  value: string | null;
};

type SetProjects = {
  type: "SetProjects";
  values: string[];
};

export type EditBasicSourcingEventCommand =
  | EditTitleCommand
  | EditDescriptionCommand
  | EditDeadlineCommand
  | EditTimezoneCommand
  | AddInvitedOrganizationCommand
  | RemoveOrganizationInviteCommand
  | AddEmailInvitesCommand
  | RemoveEmailInviteCommand
  | EditReferenceIdCommand
  | SetProjects;
