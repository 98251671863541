import { Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Icon, Spinner } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import {
  EditNegotiationRequest,
  NegotiationRoundDto,
  useEditNegotiationRoundMutation,
} from "../../../../autogen/bff-api";
import { isFetchBaseQueryError } from "../../../../common/errors/isFetchBaseQueryError";
import { useApiError } from "../../../../common/errors/useApiError";
import { TipTap } from "../../../../common/input/TipTap/TipTap";

export const Description = ({ negotiationRound }: { negotiationRound: NegotiationRoundDto }) => {
  const { t } = useTranslation();
  const [editNegotiation, { isLoading }] = useEditNegotiationRoundMutation();
  const [value, setValue] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const displayer = useApiError();

  useEffect(() => {
    setValue(negotiationRound.description || null);
  }, [negotiationRound.description]);

  const eventHandler = useCallback(
    async (value: string | null) => {
      const request: EditNegotiationRequest = {
        description: {
          value: value || "",
        },
      };

      try {
        await editNegotiation({
          negotiationId: negotiationRound.id,
          editNegotiationRequest: request,
        }).unwrap();
        setErrorMessage(null);
      } catch (error) {
        if (isFetchBaseQueryError(error)) displayer.trigger(error);
        setErrorMessage(t("Update failed"));
      }
    },
    [displayer, editNegotiation, negotiationRound.id, t]
  );

  const valueHasChanged = negotiationRound.description !== value;
  const isPublished = negotiationRound.stateName !== "Draft";
  const showSaveButton = isPublished && valueHasChanged;

  return (
    <FormControl id="description" width="full" isInvalid={errorMessage !== null}>
      <FormLabel>
        {t("Negotiation round description")} {isLoading && <Spinner size={"xs"} />}
      </FormLabel>
      <TipTap
        content={negotiationRound.description ?? ""}
        onUpdate={(value) => {
          setValue(value);
          if (!isPublished) {
            eventHandler(value);
          }
        }}
        editable={true}
      />
      <FormHelperText>{t("Describe the details of this negotiation round")}</FormHelperText>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
      {showSaveButton && (
        <Button
          mt="10px"
          leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
          variant={"solid"}
          colorScheme="teal"
          size={"sm"}
          isLoading={isLoading}
          onClick={() => eventHandler(value)}
        >
          {t("Save")}
        </Button>
      )}
    </FormControl>
  );
};
