/**
 * Common utilities for negotiation contexts in both buyer and supplier views
 */

/**
 * Gets the latest round number from a negotiation context
 */
export const getLatestRoundNumber = (negotiationContext?: { rounds?: unknown[] }) => {
  if (!negotiationContext?.rounds?.length) return 0;
  return negotiationContext.rounds.length;
};

/**
 * Gets the latest round from a negotiation context
 */
export const getLatestRound = <T>(rounds?: T[]) => {
  if (!rounds?.length) return undefined;
  return rounds[rounds.length - 1];
};

/**
 * Gets the current round based on the round number
 */
export const getCurrentRound = <T>(roundNumber: number, rounds?: T[]) => {
  if (!roundNumber || !rounds?.length || roundNumber > rounds.length) return undefined;
  return rounds[roundNumber - 1];
};
