import { createContext, ReactNode, useContext, useState } from "react";
import { BseSupplierViewDto, SupplierNegotiationRoundDto } from "../../../../autogen/bff-api";
import { getCurrentRound, getLatestRound, getLatestRoundNumber } from "../../common/negotiation-utils";

interface NegotiationContextType {
  roundNumber: number;
  setRoundNumber: (round: number) => void;
  currentRound: SupplierNegotiationRoundDto | undefined;
  latestRound: SupplierNegotiationRoundDto | undefined;
  event: BseSupplierViewDto;
}

const NegotiationContext = createContext<NegotiationContextType | undefined>(undefined);

export const NegotiationProvider = ({ children, event }: { children: ReactNode; event: BseSupplierViewDto }) => {
  const [roundNumber, setRoundNumber] = useState(getLatestRoundNumber(event.negotiationContextDto));

  const currentRound = getCurrentRound(roundNumber, event.negotiationContextDto?.rounds);
  const latestRound = getLatestRound(event.negotiationContextDto?.rounds);

  return (
    <NegotiationContext.Provider
      value={{
        roundNumber,
        setRoundNumber,
        currentRound,
        latestRound,
        event,
      }}
    >
      {children}
    </NegotiationContext.Provider>
  );
};

export const useNegotiation = (): NegotiationContextType => {
  const context = useContext(NegotiationContext);
  if (context === undefined) {
    throw new Error("useNegotiation must be used within a NegotiationProvider");
  }
  return context;
};
