import { Box, Flex, Grid, GridItem, Image, Skeleton, Text, useColorMode } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { assets } from "../../../assets/assets";
import { useGetOrgUserEmailInviteQuery } from "../../../autogen/bff-api";
import { AlreadyAccepted } from "../AlreadyAccepted";
import { LoginDrawer } from "./LoginDrawer";
import { SignupDrawer } from "./SignupDrawer/SignupDrawer";
import { SignupOrLogin } from "./SignupOrLogin";

interface Props {
  inviteId: string;
  secret: string;
}

export const OrgUserEmailInvite = ({ inviteId, secret }: Props) => {
  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const { t } = useTranslation();
  const theme = useColorMode();
  const { data, isLoading: isLoadingInvite, error } = useGetOrgUserEmailInviteQuery({ secret, inviteId });

  if (error) return <AlreadyAccepted />;

  return (
    <Box
      width="full"
      height="100vh"
      bgGradient="linear(to-br, smTealShadow, smBackground, smBackground, smBackground, smBlueShadow)"
    >
      {showSignup && (
        <SignupDrawer
          asOrg={false}
          isOpen={showSignup}
          onClose={() => setShowSignup(false)}
          invitationContext={{
            emailInviteId: inviteId,
            emailInviteType: "SourcingEvent",
            secret: secret,
          }}
        />
      )}
      {showLogin && (
        <LoginDrawer
          isOpen={showLogin}
          onClose={() => setShowLogin(false)}
          invitationContext={{
            emailInviteId: inviteId,
            emailInviteType: "SourcingEvent",
            secret: secret,
          }}
        />
      )}
      <Grid
        gridTemplateColumns={"1fr minmax(0, 500px) 1fr"}
        height="100%"
        gridTemplateRows={"1fr minmax(480px, auto) 2fr"}
        templateAreas={`
                "left top right"
                "left login right"
                "left bottom right"
                `}
      >
        <GridItem area={"login"} borderRadius={10} boxShadow="0 9px 46px 8px rgb(0,0,0,0.2)" padding={10}>
          {data && (
            <Flex w="100%" h={"100%"} flexDirection="column">
              <Flex paddingTop={"20px"} paddingBottom="35px">
                <Image
                  src={theme.colorMode === "dark" ? assets.logo_dark : assets.logo}
                  alt="Logo"
                  h="30px"
                  mt="10px"
                />
              </Flex>
              <Text fontSize="2xl" mt="1">
                <b>{t("Invitation to join organization")}</b>
              </Text>
              <Text fontSize="md" mt="5">
                {t("You have been invited to join")}: <b>{data.organizationName}</b>.
              </Text>
              <Text fontSize="md" mt="5">
                {t("You need to")} <b>{t("sign up")}</b> {t("or")} <b>{t("log in")}</b> {t("to accept this invitation")}
                .
              </Text>
              <SignupOrLogin onSignup={() => setShowSignup(true)} onLogin={() => setShowLogin(true)} />
            </Flex>
          )}
          {!data && isLoadingInvite && (
            <Flex flexDirection="column" alignItems="center">
              <Skeleton height="40px" width="300px" mb="4" />
              <Skeleton height="20px" width="250px" mb="6" />
              <Skeleton height="40px" width="200px" />
            </Flex>
          )}
        </GridItem>
      </Grid>
    </Box>
  );
};
