import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BseDto,
  InviteInput,
  NegotiationRoundDto,
  OrganizationInviteDto,
  PersonDto,
  useEditNegotiationRoundMutation,
} from "../../../../autogen/bff-api";
import { OrganizationTypeTag } from "../../../../common/organization/OrganizationTypeTag";
import { useConfirmationModal } from "../../../../common/useConfirmationModal";
import { displayPersonNameWithEmail } from "../../../contracts/view-single/sharing/AddExternalParticipantModal";

export const InvitesTable = ({ event, negotiationRound }: { event: BseDto; negotiationRound: NegotiationRoundDto }) => {
  const { t } = useTranslation();
  const confirmationModal = useConfirmationModal();
  const [editNegotiationRound] = useEditNegotiationRoundMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentInvite, setCurrentInvite] = useState<OrganizationInviteDto | null>(null);
  const [inviteMessage, setInviteMessage] = useState("");

  const mangualRemovalConfirmationRequired = (): boolean => {
    if (negotiationRound.stateName === "Published") return true;
    return false;
  };

  const removeOrgInvite = async (inviteId: string) => {
    await editNegotiationRound({
      negotiationId: negotiationRound.id,
      editNegotiationRequest: {
        inviteIdToRemove: inviteId,
      },
    }).unwrap();
  };

  const addOrgInvite = async (inviteInput: InviteInput) => {
    await editNegotiationRound({
      negotiationId: negotiationRound.id,
      editNegotiationRequest: {
        inviteInput: {
          organizationId: inviteInput.organizationId,
          personIds: inviteInput.personIds,
          message: inviteInput.message ?? "",
        },
      },
    }).unwrap();
  };

  const openMessageModal = (invite: OrganizationInviteDto) => {
    // Only open the message modal for new invites
    setCurrentInvite(invite);
    setInviteMessage(""); // Always start with empty message for new invites
    onOpen();
  };

  const handleSaveMessage = () => {
    if (!currentInvite) return;

    // Add the invite with the message
    addOrgInvite({
      organizationId: currentInvite.organizationId,
      personIds: currentInvite.persons.map((p) => p.id),
      message: inviteMessage,
    });

    onClose();
  };

  const handleRemoveOrgInvite = async (inviteId?: string) => {
    if (!inviteId) return;
    if (mangualRemovalConfirmationRequired()) {
      confirmationModal.show({
        title: t("Confirm removal"),
        description: t("You are live, and the selected organizations wil immediately be removed from the event"),
        onConfirmed: () => removeOrgInvite(inviteId),
        confirmButtonColor: "blue",
        confirmButtonText: t("Send") ?? "",
      });
    } else removeOrgInvite(inviteId);
  };

  // Helper function to truncate text
  const truncateText = (text: string | undefined, maxLength = 30) => {
    if (!text) return "";
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  return (
    <Box
      height="full"
      width="full"
      maxWidth="full"
      overflowX={{ base: "scroll", xl: "hidden" }}
      border="1px solid"
      borderColor="smBorder"
      rounded="md"
      pb="2.5"
    >
      {confirmationModal.modal}

      {/* Message Edit Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Add Invitation Message")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="2">
              {t("Message to")}: {currentInvite?.organizationName}
            </Text>
            <Textarea
              value={inviteMessage}
              onChange={(e) => setInviteMessage(e.target.value)}
              placeholder={`${t("Enter message for the organization")}`}
              rows={5}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button colorScheme="blue" onClick={handleSaveMessage}>
              {t("Save Message")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Table variant="simple" width="full">
        <Thead>
          <Tr>
            <Th>{t("Organization name")}</Th>
            <Th>{t("Type")}</Th>
            <Th>{t("Recipients")}</Th>
            <Th>{t("Message")}</Th>
            <Th>{t("Invite")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {event.organizationInvites.map((invite: OrganizationInviteDto) => (
            <Tr key={invite.id}>
              <Td verticalAlign="top">
                <Text>{invite.organizationName}</Text>
              </Td>
              <Td verticalAlign="top">
                <OrganizationTypeTag type="Organization" />
              </Td>
              <Td verticalAlign="top">
                {invite.persons.map((person: PersonDto) => (
                  <Text key={person.id} fontSize="sm">
                    {displayPersonNameWithEmail(person)}
                  </Text>
                ))}
              </Td>
              <Td verticalAlign="top">
                {/* Message column - read-only display */}
                {negotiationRound.invites.some((i) => i.organizationId === invite.organizationId) ? (
                  <Tooltip
                    label={negotiationRound.invites.find((i) => i.organizationId === invite.organizationId)?.message}
                  >
                    <Text fontSize="sm">
                      {truncateText(
                        negotiationRound.invites.find((i) => i.organizationId === invite.organizationId)?.message
                      ) || (
                        <Text as="i" color="gray.500">
                          {t("No message")}
                        </Text>
                      )}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text fontSize="sm" color="gray.500" fontStyle="italic">
                    {t("Invite first")}
                  </Text>
                )}
              </Td>
              <Td verticalAlign="top" textAlign="center">
                <Switch
                  aria-label="toggle invite"
                  variant="ghost"
                  isChecked={negotiationRound.invites.map((i) => i.organizationId).includes(invite.organizationId)}
                  onChange={(e) => {
                    const isInviteChecked = e.target.checked;
                    if (!isInviteChecked) {
                      // Remove invite
                      handleRemoveOrgInvite(
                        negotiationRound.invites.find((i) => i.organizationId === invite.organizationId)?.id
                      );
                    } else {
                      // Open message modal for new invites
                      openMessageModal(invite);
                    }
                  }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
