import { Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";

interface Props {
  icon?: IconType;
  text: string;
  secondaryText?: string;
  backgroundColor?: string;
  color?: string;
  border?: string;
  borderColor?: string;
  children?: React.ReactNode;
}

export const StatusBanner = ({
  icon,
  text,
  secondaryText,
  backgroundColor,
  color,
  border,
  borderColor,
  children,
}: Props) => {
  return (
    <Flex
      display="flex"
      bg={backgroundColor}
      rounded="md"
      flexDirection="row"
      alignItems="center"
      color={color ?? "smBackground"}
      border={border}
      borderColor={borderColor}
      p="5"
    >
      <Flex width="100%" justifyContent="center" alignItems="center">
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
          <Flex justifyContent="center">
            {icon && <Icon as={icon} mr="2" width="5" height="5" mt="0.5" />}
            <Text fontSize={"lg"} fontWeight="bold">
              {text}
            </Text>
          </Flex>
          {secondaryText && (
            <Text fontSize={"md"} fontWeight="normal" mt="1">
              {secondaryText}
            </Text>
          )}
        </Flex>
      </Flex>
      {children && (
        <Flex justifyContent="end" pl="5">
          {children}
        </Flex>
      )}
    </Flex>
  );
};
