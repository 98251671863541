import { Box, Button, Flex, Icon, LinkBox, LinkOverlay, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaDownload } from "react-icons/fa";
import { BseDto } from "../../../../autogen/bff-api";
import { sanitizeFilename } from "../../../../common/utils/sanitizeUtils";
import { generateHTMLFromTextDocument } from "../../../../common/input/TipTap/downloadTextDocument";
import { getImpersonatedPersonId } from "../../../../common/local-storage/impersonated-person";
import { getPreferredLanguage } from "../../../../common/local-storage/language";
import { getOrganizationId } from "../../../../common/local-storage/selected-organization";
import { Explanation } from "../../../../common/support/Explanation/Explanation";
import { requireStringEnvVar } from "../../../../config";

interface Props {
  data: BseDto;
}

export const downloadProtocol = async ({
  eventId,
  content,
  fileName,
}: {
  eventId: string;
  content?: string;
  fileName: string;
}) => {
  if (!content) throw Error("No content");
  const persistedOrganizationId = getOrganizationId();
  const impersonatedPersonId = getImpersonatedPersonId();
  const response = await fetch(
    `${requireStringEnvVar(
      "VITE_BFF_BASE_URL"
    )}/api/supplier/basic/events/${eventId}/public-procurement-protocol-part-one`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        ...(persistedOrganizationId ? { organizationId: persistedOrganizationId } : undefined),
        ...(impersonatedPersonId ? { personId: impersonatedPersonId } : undefined),
        "preferred-language": getPreferredLanguage(),
      },
      body: JSON.stringify({
        descriptionHtml: generateHTMLFromTextDocument(content ?? ""),
      }),
    }
  );

  if (!response.ok) throw Error("Failed to download protocol");

  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${sanitizeFilename(`${fileName}.pdf`)}`;
  document.body.appendChild(a);
  a.click();
};

export const BseReports = ({ data }: Props) => {
  const { t } = useTranslation();

  const getCannotDownloadExplanation = (): string | null => {
    if (!data.awardedFields) return t("The event has to be awarded before the protocol can be downloaded");
    if (!data.awardedFields.eventValue) return t("You must provide a contract value before downloading this report");
    return null;
  };

  const cannotDownloadExplanation = getCannotDownloadExplanation();
  const canDownload = cannotDownloadExplanation === null;

  return (
    <Box borderRadius={10} mt="6">
      <Text fontWeight={"semibold"} pb="2">
        {t("Reports")}
      </Text>
      <Flex border="1px solid" borderColor="smBorder" rounded="lg">
        <Table variant="simple" size={"sm"}>
          <Tbody>
            <Tr>
              <Td>{t("Procurement protocol")}</Td>
              <Td>
                <Flex justifyContent="end">
                  <Explanation text={cannotDownloadExplanation ?? ""} enabled={!canDownload}>
                    <Button
                      size="sm"
                      leftIcon={<Icon as={FaDownload} w="15px" h="15px" />}
                      isDisabled={!canDownload}
                      variant={"ghost"}
                      colorScheme="blue"
                      onClick={() =>
                        downloadProtocol({
                          eventId: data.id,
                          content: data.awardedFields?.description,
                          fileName: data.title,
                        })
                      }
                    >
                      {t("Download")}
                    </Button>
                  </Explanation>
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Td>{t("Questions and announcements")}</Td>
              <Td>
                <Flex justifyContent="end">
                  <LinkBox>
                    <LinkOverlay
                      href={`${requireStringEnvVar("VITE_BFF_BASE_URL")}/api/supplier/basic/events/${
                        data.id
                      }/qa-report`}
                    >
                      <Button size="sm" leftIcon={<Icon as={FaDownload} />} variant={"ghost"} colorScheme="blue">
                        {t("Download")}
                      </Button>
                    </LinkOverlay>
                  </LinkBox>
                </Flex>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Flex>
    </Box>
  );
};
