import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  Tag,
  Text,
} from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  useGetBasicSourcingEventQuery,
  useGetNegotiationRoundQuery,
  useGetProductGroupsForSourcingEventQuery,
} from "../../../../autogen/bff-api";
import { EntityViewSkeleton } from "../../../../common/EntityViewSkeleton";
import { Layout } from "../../../../common/layout/Layout";
import { ProductGroupForm } from "../../../../common/products/ProductGroupForm";
import { mapDtoToProductGroup } from "../../../../common/redux/reducers/productReducer";
import { useSub } from "../../../../common/subscription/useSub";
import { urls } from "../../../../urls";
import { Docs } from "./Docs";
import { Intro } from "./Intro";
import { InvitedParties } from "./InvitedParties";
import { NegotiationNavigation } from "./NegotiationNavigation";
import { Review } from "./Review";

export const EditNegotiationPage = () => {
  const navigate = useNavigate();
  const sub = useSub();
  const params = useParams();
  const eventId = params.eventId;
  const negotiationId = params.negotiationId;
  const [searchParams] = useSearchParams();
  const currentStep = searchParams.get("step");

  const [showDrawer, setShowDrawer] = useState(false);

  const { data: event, isLoading: isLoadingEvent } = useGetBasicSourcingEventQuery(eventId ? { eventId } : skipToken);
  const { data: negotiationRound, isLoading: isLoadingNegotiationRound } = useGetNegotiationRoundQuery(
    negotiationId ? { negotiationId } : skipToken
  );
  const { data: productData } = useGetProductGroupsForSourcingEventQuery(
    eventId ? { sourcingEventId: eventId } : skipToken
  );

  const productGroups = useMemo(() => {
    return productData?.productGroups.map((g) => mapDtoToProductGroup(g));
  }, [productData?.productGroups]);

  useEffect(() => {
    if (!currentStep && eventId && negotiationId) {
      navigate(urls.events.editNegotiation.go({ eventId, negotiationId }).intro);
    }
  }, [currentStep, eventId, navigate, negotiationId]);

  const navigationHandlers =
    eventId && negotiationId
      ? {
          navigateToIntro: () => navigate(urls.events.editNegotiation.go({ eventId, negotiationId }).intro),
          navigateToProducts: () =>
            sub.hasProductAccess
              ? navigate(urls.events.editNegotiation.go({ eventId, negotiationId }).products)
              : undefined,
          navigateToDocs: () => navigate(urls.events.editNegotiation.go({ eventId, negotiationId }).docs),
          navigateToParties: () => navigate(urls.events.editNegotiation.go({ eventId, negotiationId }).parties),
          navigateToReview: () => navigate(urls.events.editNegotiation.go({ eventId, negotiationId }).review),
          navigateToEventView: () => navigate(urls.events.view.go(eventId)),
        }
      : null;

  if (!eventId || !negotiationId || !navigationHandlers) return <Text>{t("Invalid URL")}</Text>;

  if (isLoadingEvent || isLoadingNegotiationRound) return <EntityViewSkeleton />;

  if (!event) return <Text>{t("Someting went wrong when loading sourcing event")}</Text>;

  if (!negotiationRound) return <Text>{t("Someting went wrong when loading negotiation round")}</Text>;

  return (
    <Layout>
      <Drawer placement={"bottom"} onClose={() => setShowDrawer(false)} isOpen={showDrawer}>
        <DrawerOverlay />
        <DrawerContent borderTop="1px solid" borderColor="smBorder">
          <DrawerBody>
            <NegotiationNavigation navigationHandlers={navigationHandlers} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Flex
        display={{ base: "flex", md: "none" }}
        position="fixed"
        zIndex={1}
        bottom="0px"
        width="full"
        height="60px"
        borderTop={"1px solid"}
        borderColor="smBorder"
        alignItems="center"
        bg="smBackground"
      >
        <Button variant={"solid"} colorScheme="blue" width="full" mx="2.5" onClick={() => setShowDrawer(true)}>
          {t("Change step")}
        </Button>
      </Flex>
      <Flex width="full" justifyContent="center">
        <Flex
          flexDirection="column"
          alignItems="center"
          width="container.xl"
          maxWidth="container.xl"
          overflow="hidden"
          pt="2.5"
          px="2"
        >
          <Flex columnGap="5" width="full">
            <Box display={{ base: "none", md: "flex" }} width="48" borderBottom="1px solid" borderColor="smBorder" />
            <Flex grow="1" borderBottom="1px solid" borderColor="smBorder" pb="2">
              <Box>
                <Heading width="full" as="h2" size="md" mb="2">
                  {event.title}
                </Heading>
                <Tag>
                  {t("Negotiation round")} {negotiationRound?.roundNumber}
                </Tag>
              </Box>
            </Flex>
          </Flex>
          <Flex columnGap="5" width="full" maxWidth="full" overflow="hidden" pt="10">
            <Box display={{ base: "none", md: "flex" }} width="48" minWidth="48">
              <NegotiationNavigation navigationHandlers={navigationHandlers} />
            </Box>
            <Flex grow="1" overflowX="hidden">
              <Box width="full" display={currentStep === "intro" ? undefined : "none"}>
                {event && negotiationRound && (
                  <Intro
                    negotiationRound={negotiationRound}
                    nextStep={navigationHandlers.navigateToProducts ?? navigationHandlers.navigateToDocs}
                  />
                )}
              </Box>
              <Box display={currentStep === "products" ? undefined : "none"} maxWidth="full">
                {productGroups?.map((group) => (
                  <ProductGroupForm key={group.id} group={group} isDisabled={true} />
                ))}
                <Flex py="10">
                  <Button
                    variant="outline"
                    mr="5px"
                    w="100%"
                    colorScheme="teal"
                    onClick={navigationHandlers.navigateToIntro}
                  >
                    {t("Previous")}
                  </Button>
                  <Button variant="solid" w="100%" colorScheme="teal" onClick={navigationHandlers.navigateToDocs}>
                    {t("Next")}
                  </Button>
                </Flex>
              </Box>
              <Box display={currentStep === "docs" ? undefined : "none"} width="full">
                {negotiationId && (
                  <Docs
                    negotiationId={negotiationId}
                    nextStep={navigationHandlers.navigateToParties}
                    previousStep={navigationHandlers.navigateToProducts ?? navigationHandlers.navigateToIntro}
                  />
                )}
              </Box>
              <Box display={currentStep === "parties" ? undefined : "none"} width="full">
                <InvitedParties
                  event={event}
                  negotiationRound={negotiationRound}
                  nextStep={navigationHandlers.navigateToReview}
                  previousStep={navigationHandlers.navigateToDocs}
                />
              </Box>
              <Box width="full" display={currentStep === "review" ? undefined : "none"}>
                {event && negotiationRound && (
                  <Review
                    event={event}
                    negotiationRound={negotiationRound}
                    previousStep={navigationHandlers.navigateToParties}
                  />
                )}
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  );
};
